import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { sumBy } from "lodash";
// import {
//   Field,
//   Form,
//   FormElement,
//   FormRenderProps,
// } from "@progress/kendo-react-form";
// import LOGO from "../../../assets/Images/logo.png";
// // import { FaCheck } from "react-icons/fa6";
// import FormCheckboxGroup from "../../../components/formFields/FormCheckboxGroup";
// import FormCheckbox from "../../../components/formFields/FormCheckbox";
// import { RxDotFilled } from "react-icons/rx";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getTourBookingFormViewByID } from "../services/tourBooking.services";

// const TicketType = [
//   { label: "Group", value: "Group" },
//   { label: "Online", value: "Online" },
//   { label: "AG", value: "AG" },
//   { label: "Documents", value: "Documents" },
// ];

// const VisaSelection = [
//   { label: "Yes", value: "Yes" },
//   { label: "No", value: "No" },
// ];

const TourBookingReport: React.FC = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const TourBookingNo = params?.TourBookingNo;
  console.log(TourBookingNo);
  const TourBookingViewDetails = useAppSelector(
    (state) => state.tourBooking.TourBookingViewDetails
  );
  const [formKey, setFormKey] = useState(1);
  console.log("TourBookingViewDetails", TourBookingViewDetails);
  // const TourBookingViewDetails: any = {};
  useEffect(() => {
    if (TourBookingNo) {
      dispatch(getTourBookingFormViewByID(TourBookingNo));
    }
  }, [TourBookingNo]);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [TourBookingViewDetails]);

  return (
    <>
      {TourBookingViewDetails && (
        <div className="container-fluid tourbooking-view">
          <div className="row">
            <div className="col-12 p-3">
              <table className="w-100 border border-dark fw-600">
                <tr className="border border-dark ">
                  <td
                    className="p-2 border border-dark"
                    style={{ width: "20%" }}
                  >
                    <span className="color-darkred ">Branch</span> :{" "}
                    {TourBookingViewDetails?.BranchName}
                  </td>
                  <td
                    className="p-2 border border-dark"
                    style={{ width: "26%" }}
                  >
                    <span className="color-darkred ">Book By</span> :{" "}
                    {TourBookingViewDetails?.BookByName}
                  </td>
                  <td
                    className="p-2 border border-dark text-center"
                    style={{ width: "36%", background: "#ffdcdc" }}
                  >
                    {/* <span className="color-darkred ">Book By</span> :{" "} */}
                    {TourBookingViewDetails?.TourCompanyName}{" "}
                    {TourBookingViewDetails?.TourCompanyName &&
                      "- Tour Booking Form"}
                  </td>
                  <td
                    className="p-2 border border-dark"
                    style={{ width: "18%" }}
                  >
                    <span className="color-darkred ">Form No</span> :{" "}
                    {TourBookingViewDetails?.TourBookingNo}
                  </td>
                </tr>
              </table>
              <table className="w-100 border border-dark mt-1 fw-600">
                <tr className="border border-dark">
                  <td
                    className="p-2 border border-dark"
                    style={{ width: "50%" }}
                  >
                    <span className="color-darkred ">
                      Tour Start - End Date
                    </span>{" "}
                    : {TourBookingViewDetails?.TourStartEndDate}
                  </td>
                  <td
                    className="p-2 border border-dark"
                    style={{ width: "16%" }}
                  >
                    <span className="color-darkred ">Days</span> :{" "}
                    {TourBookingViewDetails?.NoOfDays}
                  </td>
                  <td
                    className="p-2 border border-dark"
                    style={{ width: "16%" }}
                  >
                    <span className="color-darkred ">Night</span> :{" "}
                    {TourBookingViewDetails?.NoOfNights}
                  </td>
                  <td
                    className="p-2 border border-dark"
                    style={{ width: "18%", whiteSpace: "nowrap" }}
                  >
                    <span className="color-darkred ">Tour Code</span> :{" "}
                    {TourBookingViewDetails?.TourDateCode}
                  </td>
                </tr>
              </table>
              <table className="w-100 border border-dark mt-1 fw-600">
                <tr className="border border-dark">
                  <td className="p-2">
                    <div>
                      <span className="color-darkred ">Tour Name</span> :{" "}
                      {TourBookingViewDetails?.TourName}
                    </div>
                  </td>
                </tr>
              </table>
              <table className="w-100 border border-dark mt-2 fw-600">
                <tr className="border border-dark">
                  <td className=" p-2" colSpan={2}>
                    <span className="color-darkred ">Party Name</span> :{" "}
                    {`${TourBookingViewDetails?.FirstName} ${TourBookingViewDetails?.LastName}`}
                  </td>
                </tr>
                <tr className="border border-dark">
                  <td className=" p-2" colSpan={2}>
                    <span className="color-darkred ">Party Address</span> :{" "}
                    {TourBookingViewDetails?.Address}
                  </td>
                </tr>
                <tr className="border border-dark">
                  <td className=" p-2" colSpan={2}>
                    <span className="color-darkred ">Company Name</span> :{" "}
                    {TourBookingViewDetails?.CompanyName}
                  </td>
                </tr>
                <tr className="border border-dark">
                  <td className=" p-2" colSpan={2}>
                    <span className="color-darkred ">Company Address</span> :{" "}
                    {TourBookingViewDetails?.CompanyAddress}
                  </td>
                </tr>
                <tr className="border border-dark">
                  <td className=" p-2 w-50">
                    <span className="color-darkred ">Mobile No.</span> :{" "}
                    {TourBookingViewDetails?.MobileNo}
                  </td>
                  <td className=" p-2 w-50">
                    <span className="color-darkred ">Resident Mo. No.</span> :{" "}
                    {TourBookingViewDetails?.ResidentPhoneNo}
                  </td>
                </tr>
                <tr className="border border-dark">
                  <td className=" p-2">
                    <span className="color-darkred ">Email ID</span> :{" "}
                    {TourBookingViewDetails?.EmailID}
                  </td>
                  <td className=" p-2">
                    <span className="color-darkred ">
                      For Emergency Mo. No.
                    </span>{" "}
                    : {TourBookingViewDetails?.EmergencyNo}
                  </td>
                </tr>
                <tr className="border border-dark">
                  <td className=" p-2" colSpan={2}>
                    <div
                      className="d-flex w-100 justify-content-between"
                      style={{ gap: "10px" }}
                    >
                      <div>
                        <span className="color-darkred ">Aadhar No.</span> :{" "}
                        {TourBookingViewDetails?.AadharNo}
                      </div>
                      {/* </td>
                  <td className=" p-2"> */}
                      <div>
                        <span className="color-darkred ">PAN No.</span> :{" "}
                        {TourBookingViewDetails?.PANCardNo}
                      </div>
                      <div style={{ minWidth: 220 }}>
                        <span className="color-darkred ">GST No.</span> :{" "}
                        {TourBookingViewDetails?.CompanyGSTNo}
                      </div>
                    </div>
                  </td>
                </tr>
                <tr className="border border-dark">
                  <td className=" p-2" colSpan={2}>
                    <span className="color-darkred ">Airline Name</span> :{" "}
                    {TourBookingViewDetails?.AirlineName}
                  </td>
                </tr>
                <tr className="border border-dark">
                  <td className=" p-2" style={{ background: "lightgreen" }}>
                    <span className="color-darkred ">Pickup Place</span> :{" "}
                    {TourBookingViewDetails?.PickUpPlace}
                  </td>
                  <td className=" p-2" style={{ background: "#ffdcdc" }}>
                    <span className="color-darkred ">Drop Place</span> :{" "}
                    {TourBookingViewDetails?.DropPlace}
                  </td>
                </tr>
              </table>
            </div>
            {TourBookingViewDetails?.TourBookingFormPax &&
              TourBookingViewDetails?.TourBookingFormPax.length > 0 && (
                <div className="col-12 p-3 pt-1">
                  <h6 className="w-100 text-center m-0 p-2 bg-dark text-white">
                    PASSENGER DETAILS
                  </h6>
                  {TourBookingViewDetails?.TourBookingFormPax &&
                    TourBookingViewDetails?.TourBookingFormPax?.length > 0 && (
                      <table className="w-100 border border-dark">
                        <tr
                          className="fw-600"
                          style={{ background: "#fac3c3", color: "#000" }}
                        >
                          <td className="border border-dark p-2">No</td>
                          <td className="border border-dark p-2">Name</td>
                          <td className="border border-dark p-2">Age</td>
                          <td className="border border-dark p-2">Gender</td>
                          <td className="border border-dark p-2">
                            Passport No.
                          </td>
                          <td className="border border-dark p-2">
                            Expiry Date
                          </td>
                          <td className="border border-dark p-2">Room No</td>
                          <td className="border border-dark p-2">Amount</td>
                          <td className="border border-dark p-2">Extra Cost</td>
                          <td className="border border-dark p-2">Discount</td>
                          <td className="border border-dark p-2">
                            Taxable Amount
                          </td>
                          <td className="border border-dark p-2">GST Amount</td>
                          <td className="border border-dark p-2">TCS Amount</td>
                          <td className="border border-dark p-2">Final Cost</td>
                          {/* <td className="border border-dark p-2">Status</td> */}
                        </tr>
                        {TourBookingViewDetails?.TourBookingFormPax?.map(
                          (pax: any, index: number) => (
                            <tr key={index} className="fw-600">
                              <td className="border border-dark p-2">
                                {index + 1}
                              </td>
                              <td className="border border-dark p-2">
                                {pax?.Name}
                              </td>
                              <td className="border border-dark p-2">
                                {pax?.Age}
                              </td>
                              <td className="border border-dark p-2">
                                {pax?.Gender}
                              </td>
                              <td className="border border-dark p-2">
                                {pax?.PassportNo}
                              </td>
                              <td className="border border-dark p-2">
                                {pax?.ExpiryDate}
                              </td>
                              <td className="border border-dark p-2">
                                {pax?.RoomNo}
                              </td>
                              <td className="border border-dark p-2 text-end">
                                {pax?.Amount}
                              </td>
                              <td className="border border-dark p-2 text-end">
                                {pax?.ExtraCost}
                              </td>
                              <td className="border border-dark p-2 text-end">
                                {pax?.Discount}
                              </td>
                              <td className="border border-dark p-2 text-end">
                                {pax?.TaxableAmount}
                              </td>
                              <td className="border border-dark p-2 text-end">
                                {pax?.GSTAmount}
                              </td>
                              <td className="border border-dark p-2 text-end">
                                {pax?.TCSAmount}
                              </td>
                              <td className="border border-dark p-2 text-end">
                                {pax?.FinalCost}
                              </td>
                            </tr>
                          )
                        )}
                        <tr className="fw-bold color-darkred">
                          <td
                            className="border border-dark p-2 bg-whitesmoke color-darkred"
                            colSpan={7}
                          >
                            Total
                          </td>
                          <td className="border border-dark p-2 bg-whitesmoke text-end">
                            {sumBy(
                              TourBookingViewDetails?.TourBookingFormPax,
                              "Amount"
                            )}
                          </td>
                          <td className="border border-dark p-2 bg-whitesmoke text-end">
                            {sumBy(
                              TourBookingViewDetails?.TourBookingFormPax,
                              "ExtraCost"
                            )}
                          </td>
                          <td className="border border-dark p-2 bg-whitesmoke text-end">
                            {sumBy(
                              TourBookingViewDetails?.TourBookingFormPax,
                              "Discount"
                            )}
                          </td>
                          <td className="border border-dark p-2 bg-whitesmoke text-end">
                            {sumBy(
                              TourBookingViewDetails?.TourBookingFormPax,
                              "TaxableAmount"
                            )}
                          </td>
                          <td className="border border-dark p-2 bg-whitesmoke text-end">
                            {sumBy(
                              TourBookingViewDetails?.TourBookingFormPax,
                              "GSTAmount"
                            )}
                          </td>
                          <td className="border border-dark p-2 bg-whitesmoke text-end">
                            {sumBy(
                              TourBookingViewDetails?.TourBookingFormPax,
                              "TCSAmount"
                            )}
                          </td>
                          <td className="border border-dark p-2 bg-whitesmoke text-end">
                            {sumBy(
                              TourBookingViewDetails?.TourBookingFormPax,
                              "FinalCost"
                            )}
                          </td>
                        </tr>
                        <tr className="fw-600">
                          <td className="border border-dark p-2" colSpan={14}>
                            <span className="fw-600 color-darkred">
                              Remarks
                            </span>
                            {" : "}
                            <span>{TourBookingViewDetails?.Remarks}</span>
                          </td>
                        </tr>
                      </table>
                    )}
                </div>
              )}
            <div className="col-12 p-3 pt-1">
              <h6 className="w-100 text-center m-0 p-2 bg-dark text-white">
                ROOMING DETAILS
              </h6>
              <table className="w-100 border border-dark">
                <tr
                  className="border border-dark"
                  style={{ background: "#fac3c3", color: "#000" }}
                >
                  <td
                    className="border border-dark p-2"
                    style={{ width: "20%" }}
                  >
                    <span className="fw-600">Adult</span>
                  </td>
                  <td
                    className="border border-dark p-2"
                    style={{ width: "20%" }}
                  >
                    <span className="fw-600">CWB</span>
                  </td>
                  <td
                    className="border border-dark p-2"
                    style={{ width: "20%" }}
                  >
                    <span className="fw-600">CNB</span>
                  </td>
                  <td
                    className="border border-dark p-2"
                    style={{ width: "20%" }}
                  >
                    <span className="fw-600">Extra Adult</span>
                  </td>
                  <td
                    className="border border-dark p-2"
                    style={{ width: "20%" }}
                  >
                    <span className="fw-600">Extra Bed</span>
                  </td>
                  {/* <td
                    className="border border-dark p-2"
                    style={{ width: "14.28%" }}
                  >
                    <span className="fw-600">No Of Rooms</span>
                  </td>
                  <td
                    className="border border-dark p-2"
                    style={{ width: "14.28%" }}
                  >
                    <span className="fw-600">No Of Seats</span>
                  </td> */}
                </tr>
                <tr className="border border-dark">
                  <td className="border border-dark p-2">
                    <span className="fw-600">
                      {" "}
                      {TourBookingViewDetails?.TotalAdults || 0}
                    </span>
                  </td>
                  <td className="border border-dark p-2">
                    <span className="fw-600">
                      {" "}
                      {TourBookingViewDetails?.TotalCWB || 0}
                    </span>
                  </td>
                  <td className="border border-dark p-2">
                    <span className="fw-600">
                      {TourBookingViewDetails?.TotalCNB || 0}
                    </span>
                  </td>
                  <td className="border border-dark p-2">
                    <span className="fw-600">
                      {TourBookingViewDetails?.TotalExtraAdults || 0}
                    </span>
                  </td>
                  <td className="border border-dark p-2">
                    <span className="fw-600">
                      {" "}
                      {TourBookingViewDetails?.ExtraBed || 0}
                    </span>
                  </td>
                  {/* <td className="border border-dark p-2">
                    <span className="fw-600">
                      {" "}
                      {TourBookingViewDetails?.TotalNoOfRooms || 0}
                    </span>
                  </td>
                  <td className="border border-dark p-2">
                    <span className="fw-600">
                      {" "}
                      {TourBookingViewDetails?.TotalNoOfSeats || 0}
                    </span>
                  </td> */}
                </tr>
              </table>
            </div>
            {TourBookingViewDetails?.TourBookingFormAirTicket &&
              TourBookingViewDetails?.TourBookingFormAirTicket.length > 0 && (
                <div className="col-12 p-3">
                  <h6 className="w-100 text-center m-0 p-2 bg-dark text-white">
                    AIR TICKET DETAILS
                  </h6>
                  <div className="table-responsive">
                    {TourBookingViewDetails?.TourBookingFormAirTicket &&
                    TourBookingViewDetails?.TourBookingFormAirTicket.length >
                      0 ? (
                      <table className="w-100">
                        <tbody>
                          <tr
                            className="text-center"
                            style={{ background: "#fac3c3", color: "#000" }}
                          >
                            <td className="border border-dark p-2">No</td>
                            <td className="border border-dark p-2">
                              Passenger Name
                            </td>
                            <td className="border border-dark p-2">
                              Airline / Flight No
                            </td>
                            <td className="border border-dark p-2">
                              From Place to To Place
                            </td>
                            <td className="border border-dark p-2">
                              Department Date Time - Arrival Date Time
                            </td>
                            <td className="border border-dark p-2">PNR No.</td>
                            <td className="border border-dark p-2">Pax</td>
                            <td className="border border-dark p-2">Boarding</td>
                            <td className="border border-dark p-2">Class</td>
                            {/* <td className="border border-dark p-2">Type</td> 
                      <td className="border border-dark p-2">Seat Number</td> */}
                            <td className="border border-dark p-2">Amount</td>
                            {/* <td className="border border-dark p-2">Conv. Charge</td> 
                      <td className="border border-dark p-2">GST</td> 
                      <td className="border border-dark p-2">Invoice Amount</td> 
                      <td className="border border-dark p-2">
                        Vendor Cancellation
                      </td>
                      <td className="border border-dark p-2">
                        Our Cancellation
                      </td>
                      <td className="border border-dark p-2">
                        Cancellation GST
                      </td>
                      <td className="border border-dark p-2">
                        Credit Note Cost
                      </td> */}
                            <td className="border border-dark p-2">Remarks</td>
                            <td className="border border-dark p-2">Status</td>
                            {/* <td className="border border-dark p-2">Booking From</td> */}
                            <td className="border border-dark p-2">
                              Vendor Name
                            </td>
                            {/* <td className="border border-dark p-2">Approve Ticket</td> */}
                          </tr>
                          {TourBookingViewDetails?.TourBookingFormAirTicket.map(
                            (airTicket: any, index: number) => (
                              <tr key={index} className="text-center">
                                <td className=" border border-dark p-2">
                                  {index + 1}
                                </td>
                                <td className=" border border-dark p-2">
                                  {airTicket.PassengerName}
                                </td>
                                <td className=" border border-dark p-2">
                                  {airTicket.Airline +
                                    " / " +
                                    airTicket.FlightNo}
                                </td>
                                <td className=" border border-dark p-2">
                                  {airTicket.FromPlace +
                                    " to " +
                                    airTicket.ToPlace}
                                </td>
                                <td className=" border border-dark p-2">
                                  {airTicket.DepartureDateTime +
                                    " - " +
                                    airTicket.ArrivalDateTime}
                                </td>
                                <td className=" border border-dark p-2">
                                  {airTicket.PNRNo}
                                </td>
                                <td className=" border border-dark p-2">
                                  {airTicket.Pax}
                                </td>
                                <td className=" border border-dark p-2">
                                  {airTicket.Boarding}
                                </td>
                                <td className=" border border-dark p-2">
                                  {airTicket.Class}
                                </td>
                                {/* <td className=" border border-dark p-2">
                            {airTicket.Type}
                          </td>
                          <td className=" border border-dark p-2">
                            {airTicket.SeatNo}
                          </td> */}
                                <td className=" border border-dark p-2">
                                  {airTicket.BasicAmount}
                                </td>
                                {/* <td className=" border border-dark p-2">
                            {airTicket.ConvenienceCharge}
                          </td>
                          <td className=" border border-dark p-2">
                            {airTicket.GST}
                          </td> 
                          <td className=" border border-dark p-2">
                            {airTicket.InvoiceAmount}
                          </td>
                           <td className=" border border-dark p-2">
                            {airTicket.VendorCancellation}
                          </td>
                          <td className=" border border-dark p-2">
                            {airTicket.OurCancellation}
                          </td>
                          <td className=" border border-dark p-2">
                            {airTicket.CancellationGST}
                          </td>
                          <td className=" border border-dark p-2">
                            {airTicket.CreditnoteCost}
                          </td> */}
                                <td className=" border border-dark p-2">
                                  {airTicket.Remarks}
                                </td>
                                <td className=" border border-dark p-2">
                                  {airTicket.Status}
                                </td>
                                {/* <td className=" border border-dark p-2">
                            {airTicket.BookingFrom}
                          </td> */}
                                <td className=" border border-dark p-2">
                                  {airTicket.VendorName}
                                </td>
                                {/* <td className=" border border-dark p-2">
                            {airTicket.ApprovedTicket}
                          </td> */}
                              </tr>
                            )
                          )}
                          <tr>
                            <td
                              colSpan={9}
                              style={{
                                background: "lightgray",
                                color: "black",
                              }}
                              className=" text-end border border-dark p-2 fw-600"
                            >
                              Total
                            </td>
                            <td
                              style={{
                                background: "lightgray",
                                color: "black",
                              }}
                              className=" text-center border border-dark p-2 fw-600"
                            >
                              {sumBy(
                                TourBookingViewDetails?.TourBookingFormAirTicket,
                                "BasicAmount"
                              )}
                            </td>
                            {/* <td className="border border-dark p-2"></td>
                      <td className="border border-dark p-2"></td> 
                      <td className=" text-center pr-10 border border-dark p-2">
                        {sumBy(
                          TourBookingViewDetails?.TourBookingFormAirTicket,
                          "InvoiceAmount"
                        )}
                      </td>
                       <td className="border border-dark p-2"></td>
                      <td className="border border-dark p-2"></td>
                      <td className="border border-dark p-2"></td>
                      <td className=" text-right pr-10 border border-dark p-2">
                        {sumBy(
                          TourBookingViewDetails?.TourBookingFormAirTicket,
                          "CreditnoteCost"
                        )}
                      </td>
                      <td className=" border border-dark p-2"></td>
                      <td className=" border border-dark p-2"></td>*/}
                            <td
                              style={{
                                background: "lightgray",
                                color: "black",
                              }}
                              className=" border border-dark p-2"
                            ></td>
                            <td
                              style={{
                                background: "lightgray",
                                color: "black",
                              }}
                              className=" border border-dark p-2"
                            ></td>
                            <td
                              style={{
                                background: "lightgray",
                                color: "black",
                              }}
                              className=" border border-dark p-2"
                            ></td>
                          </tr>
                        </tbody>
                      </table>
                    ) : null}
                  </div>
                </div>
              )}
            <div className="col-12 p-3 pt-1">
              <h6 className="w-100 text-center m-0 p-2 bg-dark text-white">
                PAYMENT SUMMARY
              </h6>
              <table className="w-100 border border-dark">
                <tbody>
                  <tr
                    className="fw-600 text-center"
                    style={{ background: "#fac3c3", color: "#000" }}
                  >
                    <td className="col-6 border border-dark p-2 fw-600 text-center">
                      Description
                    </td>
                    <td className="col-6 border border-dark p-2 fw-600 text-center">
                      Total Cost
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-600 border border-dark p-2 pl-35">
                      Total Cost (A)
                    </td>
                    <td className="fw-600 border border-dark p-2 text-end pr-10 ">
                      {TourBookingViewDetails?.TourCost || 0}
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-600 border border-dark p-2 pl-35 ">
                      Tour Cancellation Cost (B)
                    </td>
                    <td className="fw-600 border border-dark p-2 text-end pr-10 ">
                      {TourBookingViewDetails?.TourCancellationCost || 0}
                    </td>
                  </tr>
                  <tr style={{ background: "azure" }}>
                    <td className="fw-600 border border-dark p-2 pl-35 ">
                      Tour Booking Amount (C) = (A + B)
                    </td>
                    <td className="fw-600 border border-dark p-2 text-end pr-10 ">
                      {TourBookingViewDetails?.TotalBookingAmount || 0}
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-600 border border-dark p-2 pl-35 ">
                      Total Paid Amount (D)
                    </td>
                    <td className="fw-600 border border-dark p-2 text-end pr-10 ">
                      {TourBookingViewDetails?.TotalPaidAmount || 0}
                    </td>
                  </tr>
                  <tr style={{ background: "lightgreen" }}>
                    <td className="fw-600 border border-dark p-2 pl-35">
                      Remaining Amount (E) = (C + D)
                    </td>
                    <td className="fw-600 border border-dark p-2 text-end pr-10">
                      {TourBookingViewDetails?.RemainingAmount || 0}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
      {/* {TourBookingViewDetails && (
        <Form
          key={formKey}
          initialValues={TourBookingViewDetails}
          render={(formRenderProps: FormRenderProps) => {
            console.log("formRenderProps", formRenderProps.allowSubmit);
            return (
              <FormElement>
                <div
                  className="container-fluid tourbooking-table-view"
                  style={{ fontSize: 12 }}
                >
                  <div className="row pt-2">
                    <div className="col-3">
                      <img
                        src={LOGO}
                        alt="AMT Logo"
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div
                      className="col-9"
                      style={{
                        borderLeft: "1px dashed gray",
                        fontSize: "clamp(12px, 1.5vw, 21px)",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <p className="m-0">
                        Shakti-21 Complex, Above Indusind Bank, Keshav Baugh
                        Party Plot to Mansi Circle Road, <br />
                        Vastrapur, Ahmedabad-380015, Gujarat, India. <br />
                        E-mail: info@ajaymoditours.com <RxDotFilled /> Web.:
                        www.ajaymodi.com
                      </p>
                    </div>
                    <div className="col-12 p-3">
                      <h6 className="w-100 text-center m-0 p-2 bg-dark text-white">
                        BOOKING DETAILS
                      </h6>
                      <table className="w-100 border border-dark">
                        <tr className="border border-dark">
                          <td
                            className=" color-antiquewhite"
                            style={{ width: "16%" }}
                          >
                            <span className="fw-600">Tour Booking No.</span>
                          </td>
                          <td style={{ width: "16%" }}>
                            <span className="fw-600">
                              {" "}
                              {TourBookingViewDetails?.TourBookingNo}
                            </span>
                          </td>
                          <td
                            className=" color-antiquewhite"
                            style={{ width: "16%" }}
                          >
                            <span className="fw-600">Sector</span>
                          </td>
                          <td style={{ width: "16%" }}>
                            <span className="fw-600"></span>
                          </td>
                          <td
                            className=" color-antiquewhite"
                            style={{ width: "16%" }}
                          >
                            <span className="fw-600">Book By</span>
                          </td>
                          <td style={{ width: "16%" }}>
                            <span className="fw-600">
                              {TourBookingViewDetails?.BookByName}
                            </span>
                          </td>
                        </tr>
                        <tr className="border border-dark">
                          <td className=" color-antiquewhite">
                            <span className="fw-600">Check-In Date</span>
                          </td>
                          <td>
                            <span className="fw-600">
                              {TourBookingViewDetails?.PickUpDate}
                            </span>
                          </td>
                          <td className=" color-antiquewhite">
                            <span className="fw-600">Check-Out Date</span>
                          </td>
                          <td>
                            <span className="fw-600">
                              {TourBookingViewDetails?.DropDate}
                            </span>
                          </td>
                          <td className=" color-antiquewhite">
                            <span className="fw-600">Date of Booking</span>
                          </td>
                          <td>
                            <span className="fw-600">
                              {TourBookingViewDetails?.TourBookingDate}
                            </span>
                          </td>
                        </tr>
                        <tr className="border border-dark">
                          <td className=" color-antiquewhite">
                            <span className="fw-600">Departure From</span>
                          </td>
                          <td>
                            <span className="fw-600">
                              {TourBookingViewDetails?.PickUpPlace}
                            </span>
                          </td>
                          <td className=" color-antiquewhite">
                            <span className="fw-600">Arrival To</span>
                          </td>
                          <td>
                            <span className="fw-600">
                              {TourBookingViewDetails?.DropPlace}
                            </span>
                          </td>
                          <td className=" color-antiquewhite">
                            <span className="fw-600">Total Passenger</span>
                          </td>
                          <td>
                            <span className="fw-600">
                              {TourBookingViewDetails?.TotalNoOfPax}
                            </span>
                          </td>
                        </tr>
                        <tr className="border border-dark">
                          <td className=" color-antiquewhite">
                            <span className="fw-600">Ticket Type</span>
                          </td>
                          <td colSpan={3}>
                            <Field
                              wrapperClassName="m-0"
                              checkboxWrapperClassName="d-flex gap-4"
                              id={"ticket_type"}
                              name={"ticket_type"}
                              marginRight={0}
                              marginBeetween={2}
                              disabled={true}
                              component={FormCheckboxGroup}
                              options={TicketType}
                            />
                          </td>
                          <td className="color-antiquewhite">
                            <span className="fw-600">Visa</span>
                          </td>
                          <td>
                            <Field
                              wrapperClassName="m-0"
                              checkboxWrapperClassName="d-flex gap-4"
                              id={"IsVisa"}
                              name={"IsVisa"}
                              marginRight={0}
                              marginBeetween={2}
                              disabled={true}
                              component={FormCheckboxGroup}
                              options={VisaSelection}
                            />
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div className="col-12 p-3 pt-1">
                      <h6 className="w-100 text-center m-0 p-2 bg-dark text-white">
                        PERSONAL DETAILS
                      </h6>
                      <table className="w-100 border border-dark">
                        <tr className="border border-dark">
                          <td
                            className=" color-antiquewhite"
                            style={{ width: "20%" }}
                          >
                            <span className="fw-600">First Name</span>
                          </td>
                          <td style={{ width: "30%" }}>
                            <span className="fw-600">
                              {TourBookingViewDetails?.FirstName}
                            </span>
                          </td>
                          <td
                            className=" color-antiquewhite"
                            style={{ width: "20%" }}
                          >
                            <span className="fw-600">Last Name</span>
                          </td>
                          <td style={{ width: "30%" }}>
                            <span className="fw-600">
                              {TourBookingViewDetails?.LastName}
                            </span>
                          </td>
                        </tr>
                        <tr className="border border-dark">
                          <td className=" color-antiquewhite">
                            <span className="fw-600">Mobile No.</span>
                          </td>
                          <td>
                            <span className="fw-600">
                              {TourBookingViewDetails?.MobileNo}
                            </span>
                          </td>
                          <td className=" color-antiquewhite">
                            <span className="fw-600">PAN No.</span>
                          </td>
                          <td>
                            <span className="fw-600">
                              {TourBookingViewDetails?.PANCardNo}
                            </span>
                          </td>
                        </tr>
                        <tr className="border border-dark">
                          <td className=" color-antiquewhite">
                            <span className="fw-600">Emergency No.</span>
                          </td>
                          <td>
                            <span className="fw-600">
                              {TourBookingViewDetails?.EmergencyNo}
                            </span>
                          </td>
                          <td className=" color-antiquewhite">
                            <span className="fw-600">E-mail</span>
                          </td>
                          <td>
                            <span className="fw-600">
                              {TourBookingViewDetails?.EmailID}
                            </span>
                          </td>
                        </tr>
                        <tr className="border border-dark">
                          <td className=" color-antiquewhite">
                            <span className="fw-600">Address</span>
                          </td>
                          <td colSpan={3}>
                            <span className="fw-600">
                              {TourBookingViewDetails?.Address}
                            </span>
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div className="col-12 p-3 pt-1">
                      <h6 className="w-100 text-center m-0 p-2 bg-dark text-white">
                        ROOMING DETAILS
                      </h6>
                      <table className="w-100 border border-dark">
                        <tr className="border border-dark">
                          <td style={{ width: "14.28%" }}>
                            <span className="fw-600">DBL/TWN</span>
                          </td>
                          <td style={{ width: "14.28%" }}>
                            <span className="fw-600">TRIPLE</span>
                          </td>
                          <td style={{ width: "14.28%" }}>
                            <span className="fw-600">Child with Bed</span>
                          </td>
                          <td style={{ width: "14.28%" }}>
                            <span className="fw-600">Child No Bed</span>
                          </td>
                          <td style={{ width: "14.28%" }}>
                            <span className="fw-600">Inf. on Land</span>
                          </td>
                          <td style={{ width: "14.28%" }}>
                            <span className="fw-600">Inf. Land + Air</span>
                          </td>
                          <td style={{ width: "14.28%" }}>
                            <span className="fw-600">Single</span>
                          </td>
                        </tr>
                        <tr className="border border-dark">
                          <td>
                            <span className="fw-600"></span>
                          </td>
                          <td>
                            <span className="fw-600"></span>
                          </td>
                          <td>
                            <span className="fw-600">
                              {TourBookingViewDetails?.TotalCWB || 0}
                            </span>
                          </td>
                          <td>
                            <span className="fw-600">
                              {TourBookingViewDetails?.TotalCNB || 0}
                            </span>
                          </td>
                          <td>
                            <span className="fw-600"></span>
                          </td>
                          <td>
                            <span className="fw-600"></span>
                          </td>
                          <td>
                            <span className="fw-600"></span>
                          </td>
                        </tr>
                      </table>
                    </div>
                    {TourBookingViewDetails?.TourBookingFormPax &&
                      TourBookingViewDetails?.TourBookingFormPax.length > 0 && (
                        <div className="col-12 p-3 pt-1">
                          <h6 className="w-100 text-center m-0 p-2 bg-dark text-white">
                            PASSENGER DETAILS
                          </h6>
                          {TourBookingViewDetails?.TourBookingFormPax &&
                            TourBookingViewDetails?.TourBookingFormPax?.length >
                              0 && (
                              <table className="w-100 border border-dark">
                                <tr className="color-antiquewhite fw-600">
                                  <td className="border border-dark p-2">No</td>
                                  <td className="border border-dark p-2">
                                    Name
                                  </td>
                                  <td className="border border-dark p-2">
                                    Age
                                  </td>
                                  <td className="border border-dark p-2">
                                    Gender
                                  </td>
                                  <td className="border border-dark p-2">
                                    INR
                                  </td>
                                  <td className="border border-dark p-2">
                                    USD
                                  </td>
                                  <td className="border border-dark p-2">
                                    Ser. Tax
                                  </td>
                                  <td className="border border-dark p-2">
                                    Ticket Cost
                                  </td>
                                  <td className="border border-dark p-2">
                                    Total
                                  </td>
                                  <td className="border border-dark p-2">
                                    Meal Pref.
                                  </td>
                                </tr>
                                {TourBookingViewDetails?.TourBookingFormPax?.map(
                                  (pax: any, index: number) => (
                                    <tr key={index}>
                                      <td className="border border-dark p-2">
                                        {index + 1}
                                      </td>
                                      <td className="border border-dark p-2">
                                        {pax?.Name}
                                      </td>
                                      <td className="border border-dark p-2">
                                        {pax?.Age}
                                      </td>
                                      <td className="border border-dark p-2">
                                        {pax?.Gender}
                                      </td>
                                      <td className="border border-dark p-2">
                                        {pax?.INR}
                                      </td>
                                      <td className="border border-dark p-2">
                                        {pax?.USD}
                                      </td>
                                      <td className="border border-dark p-2">
                                        {pax?.Serv_tax}
                                      </td>
                                      <td className="border border-dark p-2">
                                        {pax?.ticket_cost}
                                      </td>
                                      <td className="border border-dark p-2">
                                        {pax?.FinalCost}
                                      </td>
                                      <td className="border border-dark p-2">
                                        {pax?.meal}
                                      </td>
                                    </tr>
                                  )
                                )}
                                <tr>
                                  <td
                                    className="border border-dark p-2"
                                    colSpan={5}
                                  >
                                    <span className="fw-600">Remarks</span>
                                    {" : "}
                                    <span>
                                      {TourBookingViewDetails?.Remarks}
                                    </span>
                                  </td>
                                  <td
                                    className="border border-dark p-2 fw-600 bg-whitesmoke"
                                    colSpan={3}
                                  >
                                    Total Amount In INR
                                  </td>
                                  <td
                                    className="border border-dark p-2 fw-600 bg-whitesmoke"
                                    colSpan={2}
                                  >
                                    {sumBy(
                                      TourBookingViewDetails?.TourBookingFormPax,
                                      "FinalCost"
                                    )}
                                  </td>
                                </tr>
                              </table>
                            )}
                        </div>
                      )}
                    <div className="col-12 ps-3 pe-3 pb-2 d-flex gap-3 justify-content-end">
                      <Field
                        wrapperClassName="m-0"
                        name="IsGST"
                        label="GST"
                        component={FormCheckbox}
                        size="medium"
                        disabled
                        labelClassName={"fw-bold fs-10"}
                      />{" "}
                      <Field
                        wrapperClassName="m-0"
                        name="IsTCS"
                        label="TCS"
                        component={FormCheckbox}
                        size="medium"
                        disabled
                        labelClassName={"fw-bold fs-10"}
                      />
                    </div>
                    {TourBookingViewDetails?.SightseenDetails &&
                      TourBookingViewDetails?.SightseenDetails.length > 0 && (
                        <div className="col-12 p-3 pt-1">
                          <h6 className="w-100 text-center m-0 p-2 bg-dark text-white">
                            OPTIONAL SIGHTSEEING DETAILS
                          </h6>
                          {TourBookingViewDetails?.SightseenDetails &&
                            TourBookingViewDetails?.SightseenDetails?.length >
                              0 && (
                              <table className="w-100 border border-dark">
                                <tr className="color-antiquewhite fw-600">
                                  <td className="border border-dark p-2">No</td>
                                  <td className="border border-dark p-2">
                                    Sightseen Name
                                  </td>
                                  <td className="border border-dark p-2">
                                    Pax
                                  </td>
                                  <td className="border border-dark p-2">
                                    Rate
                                  </td>
                                </tr>
                                {TourBookingViewDetails?.SightseenDetails?.map(
                                  (pax: any, index: number) => (
                                    <tr key={index}>
                                      <td className="border border-dark p-2">
                                        {index + 1}
                                      </td>
                                      <td className="border border-dark p-2">
                                        {pax?.SightseenName}
                                      </td>
                                      <td className="border border-dark p-2">
                                        {pax?.Pax}
                                      </td>
                                      <td className="border border-dark p-2">
                                        {pax?.Rate}
                                      </td>
                                    </tr>
                                  )
                                )}
                              </table>
                            )}
                        </div>
                      )}
                    <div className="col-12 p-3 pt-1">
                      <h6 className="w-100 text-center m-0 p-2 bg-dark text-white">
                        PAYMENT SUMMARY
                      </h6>
                      <table className="w-100 border border-dark">
                        <tbody>
                          <tr className="fw-600 text-center color-antiquewhite">
                            <td className="col-6 border border-dark p-2 fw-600 text-center">
                              Description
                            </td>
                            <td className="col-6 border border-dark p-2 fw-600 text-center">
                              Total Cost
                            </td>
                          </tr>
                          <tr>
                            <td className="fw-600 border border-dark p-2 pl-35">
                              Total Cost (A)
                            </td>
                            <td className="fw-600 border border-dark p-2 text-end pr-10 ">
                              {TourBookingViewDetails?.TourCost || 0}
                            </td>
                          </tr>
                          <tr>
                            <td className="fw-600 border border-dark p-2 pl-35 ">
                              Tour Cancellation Cost (B)
                            </td>
                            <td className="fw-600 border border-dark p-2 text-end pr-10 ">
                              {TourBookingViewDetails?.TourCancellationCost}
                            </td>
                          </tr>
                          <tr style={{ background: "azure" }}>
                            <td className="fw-600 border border-dark p-2 pl-35 ">
                              Tour Booking Amount (C) = (A + B)
                            </td>
                            <td className="fw-600 border border-dark p-2 text-end pr-10 ">
                              {TourBookingViewDetails?.TotalBookingAmount}
                            </td>
                          </tr>
                          <tr>
                            <td className="fw-600 border border-dark p-2 pl-35 ">
                              Total Paid Amount (D)
                            </td>
                            <td className="fw-600 border border-dark p-2 text-end pr-10 ">
                              {TourBookingViewDetails?.TotalPaidAmount}
                            </td>
                          </tr>
                          <tr style={{ background: "#ffdddd" }}>
                            <td className="fw-600 border border-dark p-2 pl-35">
                              Remaining Amount (E) = (C + D)
                            </td>
                            <td className="fw-600 border border-dark p-2 text-end pr-10">
                              {TourBookingViewDetails?.RemainingAmount}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="col-12 p-3 pt-1">
                      <table className="w-100 border border-dark">
                        <tr className="border border-dark">
                          <td
                            className=" color-antiquewhite"
                            style={{ width: "20%" }}
                          >
                            <span className="fw-600">Name of Passenger</span>
                          </td>
                          <td style={{ width: "21%" }}>
                            <span className="fw-600">
                              {TourBookingViewDetails?.FirstName}{" "}
                              {TourBookingViewDetails?.LastName}
                            </span>
                          </td>
                          <td
                            className=" color-antiquewhite"
                            style={{ width: "20%" }}
                          >
                            <span className="fw-600">
                              Name of Booking Staff
                            </span>
                          </td>
                          <td style={{ width: "21%" }}>
                            <span className="fw-600">
                              {TourBookingViewDetails?.BookingApprovedBy}
                            </span>
                          </td>
                          <td style={{ width: "18%" }} rowSpan={2}>
                            <span className="fw-600">Date</span>
                            {" : "}
                            <span className="fw-600">
                              {TourBookingViewDetails?.TourBookingDate}
                            </span>
                          </td>
                        </tr>
                        <tr className="border border-dark">
                          <td className=" color-antiquewhite">
                            <span className="fw-600">Passenger Signature</span>
                          </td>
                          <td>
                            <span className="fw-600"></span>
                          </td>
                          <td className=" color-antiquewhite">
                            <span className="fw-600">
                              Booking Staff Signature
                            </span>
                          </td>
                          <td>
                            <span className="fw-600"></span>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </FormElement>
            );
          }}
        />
      )} */}
    </>
  );
};

export default TourBookingReport;
