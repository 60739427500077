import { createSlice } from "@reduxjs/toolkit";
import { IUserTourInitialState } from "./reportsModel";
import {
  getSelfTourBooking,
  getUserTourBooking,
} from "./services/reportsService";

const initialState: IUserTourInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  UserWiseTourBookingReport: [],
  UserTourID: null,
  UserWiseTourBookingReportDetails: {
    FromDate: "",
    ToDate: "",
    UserID: "",
    DateType: null,
  },
  SelfTourList: [],
  SelfTourID: null,
  SelfTourDetail: {
    FromDate: "",
    ToDate: "",
    UserID: "",
    DateType: null,
    BranchID:"",
  },
};

const reportSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    setUserWiseTourBookingReport: (state, action) => {
      state.UserWiseTourBookingReportDetails = action.payload;
    },
    clearUserWiseTourBookingReportList: (state) => {
      state.UserWiseTourBookingReport = [];
    },
    setSelfWiseTourBookingReport: (state, action) => {
      state.SelfTourDetail = action.payload;
    },
    clearSelfWiseTourBookingReportList: (state) => {
      state.SelfTourList = [];
    },


  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserTourBooking.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getUserTourBooking.fulfilled, (state, action) => {
        state.loading = false;
        state.UserWiseTourBookingReport = action.payload;
      })
      .addCase(getUserTourBooking.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch user tours";
      });
    builder
      .addCase(getSelfTourBooking.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getSelfTourBooking.fulfilled, (state, action) => {
        state.loading = false;
        state.SelfTourList = action.payload;
      })
      .addCase(getSelfTourBooking.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch user tours";
      });
  },
});

export const {
  setUserWiseTourBookingReport,
  clearUserWiseTourBookingReportList,
  clearSelfWiseTourBookingReportList,
  setSelfWiseTourBookingReport
} = reportSlice.actions;
export default reportSlice.reducer;
