import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  filterBy,
  CompositeFilterDescriptor,
} from "@progress/kendo-data-query";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { PagerTargetEvent } from "@progress/kendo-react-data-tools";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import { Typography } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import { Switch } from "@progress/kendo-react-inputs";
import AlertBox from "../../components/common/AlertBox";
import { LoadingPanel } from "../../components/layout/Loading";
import IconButton from "../../components/common/IconButton";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import { MdDelete } from "react-icons/md";
import {
  ActiveInactiveSightseen,
  deleteSightseen,
  getAllSightseen,
} from "./services/sightseen.services";
import { clearSightseenID, setSightseenID } from "./sightseenSlice";
import { checkAcessRights } from "../../_helper/helper";
import {
  DELETE_ACCESS,
  EDIT_ACCESS,
  INSERT_ACCESS,
} from "../../_contstants/common";
import ButtonCreate from "../../components/muidrawer/ButtonCreate";
import ButtonEdit from "../../components/muidrawer/ButtonEdit";

const Sightseen: React.FC = () => {
  const dispatch = useAppDispatch();
  const isAddAccess = checkAcessRights(location.pathname, INSERT_ACCESS);

  useEffect(() => {
    dispatch(getAllSightseen());
    return () => {
      dispatch(closeDialog());
    };
  }, []);

  return (
    <>
      <DeleteSightseenDialog />
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Sightseen List
          </Typography.h4>
          {isAddAccess && (
            <ButtonCreate label="Create Sightseen" drawerName="sightseenForm" />
          )}
        </GridLayoutItem>
        <GridLayoutItem>
          <SightseenGridComponent />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 10 };

const SightseenGridComponent: React.FC = () => {
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const isEditAccess = checkAcessRights(location.pathname, EDIT_ACCESS);
  const isDeleteAccess = checkAcessRights(location.pathname, DELETE_ACCESS);
  const SightseenList = useAppSelector(
    (state) => state.sightseen.SightseenList
  );
  const loading = useAppSelector((state) => state.sightseen.loading);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All" ? SightseenList.length : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleOpenDeleteDialog = (ID: number) => {
      dispatch(openDialog("deleteSightseen"));
      dispatch(setSightseenID(ID));
    };

    return (
      <td
        className="action-td"
        style={{
          ...props.style,
        }}
      >
        {isEditAccess && (
          <ButtonEdit
            ID={props.dataItem.ID}
            name="sightseenForm"
            tooltipTitle="Edit"
          />
        )}
        {isDeleteAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Delete"
            onClick={() => handleOpenDeleteDialog(props.dataItem.ID)}
          >
            <MdDelete
              className="absolute-position"
              style={{ fontSize: "24px" }}
            />
          </IconButton>
        )}
      </td>
    );
  };

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };
  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className="table-header"
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  const MyActiveInActtiveCommandCell = (props: GridCellProps) => {
    const [IsActive, setIsActive] = useState<boolean>(props.dataItem.IsActive);

    const handleActiveInActive = () => {
      setIsActive(!IsActive);
      const data = {
        ID: props.dataItem.ID,
        IsActive: !IsActive,
      };
      dispatch(ActiveInactiveSightseen(data)).then((response: any) => {
        if (response.payload?.data?.Status === 200) {
          dispatch(getAllSightseen());
        } else {
          dispatch(getAllSightseen());
        }
      });
    };

    return (
      <td style={{ textAlign: "center" }}>
        <Switch
          disabled={isEditAccess ? false : true}
          name="IsActive"
          checked={IsActive}
          onChange={handleActiveInActive}
          size="small"
        />
      </td>
    );
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {SightseenList && SightseenList.length ? (
        <KendoGrid
          style={{ maxHeight: "calc(100vh - 152px)" }}
          filterable={true}
          filter={filter}
          data={
            filter
              ? filterBy(SightseenList, filter).slice(
                  page.skip,
                  page.take + page.skip
                )
              : SightseenList.slice(page.skip, page.take + page.skip)
          }
          onFilterChange={handleFilterChange}
          skip={page.skip}
          take={page.take}
          total={SightseenList.length}
          pageable={{
            buttonCount: 5,
            pageSizes: [5, 10, 15, "All"],
            pageSizeValue: pageSizeValue,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column
            field="SightseenName"
            title="Sightseen Name"
            cell={(props: any) => (
              <td
                className={`${isEditAccess ? "hoverableName" : "normalName"}`}
              >
                {isEditAccess ? (
                  <ButtonEdit
                    ID={props.dataItem.ID}
                    name="sightseenForm"
                    label={props.dataItem?.SightseenName}
                  />
                ) : (
                  props.dataItem?.SightseenName
                )}
              </td>
            )}
          />
          <Column field="SectorName" title="Sector Name" />
          <Column field="Rate" title="Rate" />
          <Column
            field="ID"
            title="Status"
            cell={MyActiveInActtiveCommandCell}
            width={"90px"}
            filterable={false}
            locked={true}
          />
          {(isEditAccess || isDeleteAccess) && (
            <Column
              field="ID"
              title="Actions"
              cell={MyEditCommandCell}
              width={"110px"}
              filterable={false}
            />
          )}
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

const DeleteSightseenDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const SightseenID = useAppSelector((state) => state.sightseen.SightseenID);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const handleDeleteAction = async (ID: number | null) => {
    if (ID) {
      const response = await dispatch(deleteSightseen(ID));
      if (response.payload.Data.Status === 200) {
        dispatch(getAllSightseen());
        dispatch(closeDialog());
        dispatch(clearSightseenID());
      } else {
        dispatch(getAllSightseen());
        dispatch(closeDialog());
        dispatch(clearSightseenID());
      }
    }
  };

  return (
    <>
      {dialogName === "deleteSightseen" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Sightseen"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Sightseen?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(SightseenID)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default Sightseen;
