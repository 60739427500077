import React, { useEffect } from "react";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import moment from "moment";
import { getAllActiveUsers } from "../../user/services/user.services";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ShadowCard from "../../../components/common/ShadowCard";
import FormSelectionField from "../../../components/formFields/FormSelectionField";
import { requiredValidator } from "../../../components/formFields/CommonValidator";
import FormDatePicker from "../../../components/formFields/FormDateField";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import {
  clearUserWiseTourBookingReportList,
  setUserWiseTourBookingReport,
} from "../reportsSlice";

// import { getUserTourBooking } from "../services/reportsService";

const UserWiseBookingReport: React.FC = () => {
  const dispatch = useAppDispatch();
  // const navigate = useNavigate();
  const UserList = useAppSelector((state) => state.user.UserList);
  const loading = useAppSelector((state) => state.reports.loading);

  useEffect(() => {
    dispatch(getAllActiveUsers());

    return () => {
      dispatch(clearUserWiseTourBookingReportList());
    };
  }, []);

  const handleSubmit = (values: any) => {
    const payload = {
      UserID: values?.UserID ? values?.UserID : null,
      DateType: values?.DateType ? values?.DateType : null,
      FromDate: values?.FromDate
        ? moment(values?.FromDate).format("YYYY-MM-DD")
        : "0",
      ToDate: values?.ToDate
        ? moment(values?.ToDate).format("YYYY-MM-DD")
        : "0",
    };

    dispatch(setUserWiseTourBookingReport(payload));
    window.open(
      `usertourBookingview/${payload?.DateType}/${payload?.UserID}/${payload?.FromDate}/${payload?.ToDate}`
    );
  };

  return (
    <GridLayout gap={{ rows: 10 }} cols={[{ width: "100%" }]}>
      <GridLayoutItem>
        <ShadowCard>
          <Form
            onSubmit={handleSubmit}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement style={{ width: "100%" }}>
                <GridLayout
                  style={{ marginRight: 20 }}
                  gap={{ rows: 10, cols: 8 }}
                  cols={[
                    { width: "33.33%" },
                    { width: "33.33%" },
                    { width: "33.33%" },
                  ]}
                >
                  <GridLayoutItem colSpan={3}>
                    <Typography.h4
                      style={{ marginBottom: 0, marginLeft: "3px" }}
                    >
                      User Wise Tour Booking Report
                    </Typography.h4>
                  </GridLayoutItem>

                  <GridLayoutItem>
                    <Field
                      name="UserID"
                      label="User"
                      placeholder="Select User"
                      component={FormSelectionField}
                      options={UserList.map((user) => ({
                        value: user.ID,
                        label: `${user?.FirstName} ${user?.LastName}`,
                      }))}
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="DateType"
                      label="Date Type"
                      placeholder="Select Date Type"
                      component={FormSelectionField}
                      options={[
                        { value: 1, label: "Booking Date" },
                        { value: 2, label: "Travel Date" },
                      ]}
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </GridLayoutItem>

                  <GridLayoutItem
                    style={{ display: "flex", gap: 10, alignItems: "center" }}
                  >
                    <Field
                      name="FromDate"
                      wrapperStyle={{ width: "100%" }}
                      label="From Date"
                      format="dd/MM/yyyy"
                      component={FormDatePicker}
                      validator={requiredValidator}
                      astrike={true}
                    />
                    <Field
                      name="ToDate"
                      wrapperStyle={{ width: "100%" }}
                      label="To Date"
                      format="dd/MM/yyyy"
                      component={FormDatePicker}
                      astrike={true}
                      validator={requiredValidator}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem
                    colSpan={3}
                    style={{
                      paddingTop: "30px",
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "end",
                    }}
                  >
                    <ButtonWithLoading
                      label="Get Details"
                      type="submit"
                      disabled={!formRenderProps.allowSubmit || loading}
                      loading={loading}
                    />
                  </GridLayoutItem>
                </GridLayout>
              </FormElement>
            )}
          />
        </ShadowCard>
      </GridLayoutItem>
    </GridLayout>
  );
};

export default UserWiseBookingReport;
