import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import {
  ITourBookingItineraryList,
  ITourBookingPlace,
} from "../tourBookingModel";
import moment from "moment";

export const getAllTourBookings = createAsyncThunk(
  "TourBooking/FindAllTourBooking",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourBooking/FindAllTourBooking`,
        {
          TourBookingNo: "",
          FirstName: "",
          MobileNo: "",
          Sector: "",
          Tour: "",
          TourDateCode: "",
          GroupBookingNo: "",
          BookBy: "",
          Branch: "",
          Limit: 500,
          Skip: 0,
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Tour Bookings:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createTourBooking = createAsyncThunk(
  "TourBooking/InsertTourBooking",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourBooking/InsertTourBooking`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating tour booking:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const insertTourBookingStep2 = createAsyncThunk(
  "TourBooking/InsertTourBookingStep2",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourBooking/InsertTourBookingStep2`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error InsertTourBookingStep2:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const uploadTourBookingTouriestDoc = createAsyncThunk(
  "TourBooking/TourBookingDocumentUpload",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourBooking/TourBookingDocumentUpload`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error InsertTourBookingStep2:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const insertTourBookingStep3 = createAsyncThunk(
  "TourBooking/InsertTourBookingStep3",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourBooking/InsertTourBookingStep3`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error InsertTourBookingStep3:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateTourBooking = createAsyncThunk(
  "TourBooking/UpdateTourBooking",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourBooking/UpdateTourBooking`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating tour booking:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteTourBooking = createAsyncThunk(
  "TourBooking/DeleteTourBooking",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourBooking/DeleteTourBooking`,
        { ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting tour booking:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteTouristDocument = createAsyncThunk(
  "TourBooking/DeleteTourBookingDocument",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourBooking/DeleteTourBookingDocument`,
        { ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting tour booking:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getTourBookingByID = createAsyncThunk(
  "TourBooking/FindByIDTourBooking",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourBooking/FindByIDTourBooking`,
        { ID }
      );
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        IsLTCTour: result.IsLTCTour ? 1 : 2,
        SectorID: result.SectorID,
        SubSectorID: result.SubSectorID,
        TourID: result.TourID,
        TourCategoryID: result.TourCategoryID,
        RoomType: result.RoomType,
        TourBookingNo: result.TourBookingNo,
        CompanyID: result.CompanyID,
        CurrencyID: result.CurrencyID,
        CurrencyRate: result.CurrencyRate,
        Sector: result.Sector,
        TourName: result.TourName,
        Company: result.Company,
        TravelType: result.TravelType,
        TourMonth: result.TourMonth ? +result.TourMonth : null,
        TourDateID: result.TourDateID ? result.TourDateID : null,
        TourDate: result.TourDate ? result.TourDate : "",
        TourDateCode: result.TourDateCode,
        TourStartDate: result.TourStartDate
          ? moment(result.TourStartDate, "YYYY-MM-DD").toDate()
          : "",
        NoOfNights: result.NoOfNights,
        TotalNoOfRooms: result.TotalNoOfRooms,
        TourEndDate: result.TourEndDate
          ? moment(result.TourEndDate, "YYYY-MM-DD").toDate()
          : "",
        FirstName: result.FirstName,
        LastName: result.LastName,
        MobileNo: result.MobileNo,
        EmailID: result.EmailID,
        Address: result.Address,
        CityID: result.CityID,
        CityName: result.CityName,
        City: result.City,
        StateID: result.StateID,
        State: result.State,
        CountryID: result.CountryID,
        Country: result.Country,
        PinCode: result.PinCode,
        MobileNoDuringTravelling: result.MobileNoDuringTravelling,
        ResidentPhoneNo: result.ResidentPhoneNo,
        EmergencyNo: result.EmergencyNo,
        PANCardNo: result.PANCardNo,
        PassportNo: result.PassportNo,
        AadharNo: result.AadharNo,
        IsCompanyInvoice: result.IsCompanyInvoice ? 1 : 2,
        CompanyName: result.CompanyName,
        CompanyAddress: result.CompanyAddress,
        CompanyCityID: result.CompanyCityID,
        CompanyCity: result.CompanyCity,
        CompanyStateID: result.CompanyStateID,
        CompanyState: result.CompanyState,
        CompanyCountryID: result.CompanyCountryID,
        CompanyCountry: result.CompanyCountry,
        CompanyGSTNo: result.CompanyGSTNo,
        CompanyPANNo: result.CompanyPANNo,
        TotalAdults: result.TotalAdults,
        TotalExtraAdults: result.TotalExtraAdults,
        TotalCWB: result.TotalCWB,
        TotalCNB: result.TotalCNB,
        TotalInfants: result.TotalInfants,
        TotalNoOfPax: result.TotalNoOfPax,
        PickUpDate: result.PickUpDate
          ? moment(result.PickUpDate, "YYYY-MM-DD").toDate()
          : "",
        PickUpPlace: result.PickUpPlace ? result.PickUpPlace : "",
        PickUpTime: result.PickUpTime
          ? moment(result.PickUpTime, "HH:mm:ss").toDate()
          : "",
        PickUpFlightNo: result.PickUpFlightNo,
        DropDate: result.DropDate
          ? moment(result.DropDate, "YYYY-MM-DD").toDate()
          : "",
        DropPlace: result.DropPlace ? result.DropPlace : "",
        DropTime: result.DropTime
          ? moment(result.DropTime, "HH:mm:ss").toDate()
          : "",
        DropFlightNo: result.DropFlightNo,
        RouteVoucherNo: result.RouteVoucherNo,
        VehicleTypeID: result.VehicleTypeID,
        VendorID: result.VendorID,
        Vendor: result.Vendor,
        NoOfSeats: result.NoOfSeats,
        Remarks: result.Remarks,
        TicketBookedBy: result.TicketBookedBy,
        FlightNo: result.FlightNo,
        FlightTicketNo: result.FlightTicketNo,
        HotelVoucherNo: result.HotelVoucherNo,
        AvailableNoOfRooms: result.AvailableNoOfRooms,
        TotalNoOfSeats: result.TotalNoOfSeats,
        AvailableNoOfSeats: result.AvailableNoOfSeats,
        IsGST: result.IsGST,
        IsTCS: result.IsTCS,
        TotalAmount: result.TotalAmount,
        TotalGSTAmount: result.TotalGSTAmount,
        TotalExtraCost: result.TotalExtraCost,
        TotalCost: result.TotalCost,
        VehicleCost: result.VehicleCost,
        VehicleSharingPaxID: result.VehicleSharingPaxID,
        SpecialityTypeID: result.SpecialityTypeID,
        SpecialityType: result.SpecialityType,
        Gender: result.Gender,
        OldTourBookingNo: result.OldTourBookingNo,
        BusSeat: result.BusSeat,
        DiscountType: result.DiscountType,
        DiscountAmount: result.DiscountAmount,
        AdminDiscountAmount: result.AdminDiscountAmount,
        DiscountValue: result.DiscountValue,
        TotalDiscountAmount: result.TotalDiscountAmount,
        TotalTCSAmount: result.TotalTCSAmount,
        Kasar: result.Kasar,
        ServiceChargeAmount: result.ServiceChargeAmount,
        VehicleType: result.VehicleType,
        SectorType: result.SectorType,
        ExtraBed: result.ExtraBed,
        VehicleSharing: result.VehicleSharing,
        GroupBookingNo: result.GroupBookingNo,
        FoodOptionID: result.FoodOptionID,
        FoodType: result.FoodType,
        TourPlaceBooking: result.TourPlaceBooking
          ? result.TourPlaceBooking.map((tourplace: any) => {
              return {
                ID: tourplace.ID,
                TourBookingID: tourplace.TourBookingID,
                TourID: tourplace.TourID,
                PlaceID: tourplace.PlaceID,
                City: tourplace.City,
                CheckinDate: tourplace.CheckinDate
                  ? moment(tourplace.CheckinDate, "YYYY-MM-DD").toDate()
                  : "",
                NoOfNights: tourplace.NoOfNights,
                NoOfDays: tourplace.NoOfDays,
                CheckOutDate: tourplace.CheckOutDate
                  ? moment(tourplace.CheckOutDate, "YYYY-MM-DD").toDate()
                  : "",
                Position: tourplace.Position,
                Remarks: tourplace.Remarks,
                HotelID: tourplace.HotelID,
                HotelName: tourplace.HotelName,
                MealPlanID: tourplace.MealPlanID,
              };
            })
          : [],
        RoomPaxes: result.RoomPaxes
          ? result.RoomPaxes.map((roompax: any) => {
              return {
                RoomNo: roompax.RoomNo,
                PaxTypeID: roompax.PaxTypeID,
                SectorID: roompax.SectorID,
                TouristPaxInfo: roompax.TouristPaxInfo
                  ? roompax.TouristPaxInfo.map((touristpax: any) => {
                      return {
                        ID: touristpax.ID,
                        TourBookingID: touristpax.TourBookingID,
                        FirstName: touristpax.FirstName,
                        LastName: touristpax.LastName,
                        MobileNo: touristpax.MobileNo,
                        DOB: touristpax.DOB
                          ? moment(touristpax.DOB, "YYYY-MM-DD").toDate()
                          : "",
                        PNR: touristpax.PNR,
                        RoomNo: touristpax.RoomNo,
                        DiscountType: touristpax.DiscountType,
                        DiscountAmount: touristpax.DiscountAmount,
                        Amount: touristpax.Amount,
                        Initial: touristpax.Initial,
                        Gender: touristpax.Gender,
                        Age: touristpax.Age,
                        ExtraCost: touristpax.ExtraCost,
                        Remarks: touristpax.Remarks,
                        IsIsVisa: touristpax.IsVisa,
                        Insurance: touristpax.IsInsurance,
                        FoodOptionID: touristpax.FoodOptionID,
                        PassportNo: touristpax.PassportNo,
                        IssueDate: touristpax.IssueDate
                          ? moment(touristpax.IssueDate, "YYYY-MM-DD").toDate()
                          : "",
                        ExpiryDate: touristpax.ExpiryDate
                          ? moment(touristpax.ExpiryDate, "YYYY-MM-DD").toDate()
                          : "",
                        NationalityID: touristpax.NationalityID,
                      };
                    })
                  : [],
              };
            })
          : [],
        PaxTypeList: result.PaxTypeList
          ? result.PaxTypeList.map((paxtype: any) => {
              return {
                PaxTypeID: paxtype.PaxTypeID,
                NoOfRoom: paxtype.NoOfRoom,
              };
            })
          : [],
      } as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching tour booking:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getByIDTourBookingStep1 = createAsyncThunk(
  "TourBooking/FindByTourBookingStep1",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourBooking/FindByTourBookingStep1`,
        { ID }
      );
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        SectorID: result.SectorID,
        SectorName: result.SectorName,
        TourID: result.TourID,
        TourCode: result.TourCode,
        TourCategoryID: result.TourCategoryID,
        TourCategory: result.TourCategory,
        TourBookingNo: result.TourBookingNo,
        CompanyID: result.CompanyID,
        CurrencyID: result.CurrencyID,
        CurrencyRate: result.CurrencyRate,
        TourName: result.TourName,
        TravelType: result.TravelType,
        TourMonth: result.TourMonth ? +result.TourMonth : null,
        TourDateID: result.TourDateID ? result.TourDateID : null,
        TourDate: result.TourDate ? result.TourDate : "",
        TourStartDate: result.TourStartDate
          ? moment(result.TourStartDate, "YYYY-MM-DD").toDate()
          : "",
        NoOfNights: result.NoOfNights,
        TotalNoOfRooms: result.TotalNoOfRooms,
        TourEndDate: result.TourEndDate
          ? moment(result.TourEndDate, "YYYY-MM-DD").toDate()
          : "",
        FirstName: result.FirstName,
        LastName: result.LastName,
        MobileNo: result.MobileNo,
        EmailID: result.EmailID,
        Address: result.Address,
        CityID: result.CityID,
        CityName: result.CityName,
        StateID: result.StateID,
        StateName: result.StateName,
        CountryID: result.CountryID,
        CountryName: result.CountryName,
        EmergencyNo: result.EmergencyNo,
        IsCompanyInvoice: result.IsCompanyInvoice ? 1 : 2,
        CompanyName: result.CompanyName,
        CompanyAddress: result.CompanyAddress,
        CompanyCity: result.CompanyCity,
        CompanyStateID: result.CompanyStateID,
        CompanyState: result.CompanyState,
        CompanyCountryID: result.CompanyCountryID,
        CompanyCountry: result.CompanyCountry,
        CompanyGSTNo: result.CompanyGSTNo,
        CompanyPANNo: result.CompanyPANNo,
        NoOfSeats: result.NoOfSeats,
        Remarks: result.Remarks,
        TotalNoOfSeats: result.TotalNoOfSeats,
        AvailableNoOfSeats: result.AvailableNoOfSeats,
        IsGST: result.IsGST,
        IsTCS: result.IsTCS,
        TotalAmount: result.TotalAmount,
        TotalGSTAmount: result.TotalGSTAmount,
        TotalExtraCost: result.TotalExtraCost,
        TotalCost: result.TotalCost,
        DiscountType: result.DiscountType,
        DiscountAmount: result.DiscountAmount,
        AdminDiscountAmount: result.AdminDiscountAmount,
        DiscountValue: result.DiscountValue,
        TotalDiscountAmount: result.TotalDiscountAmount,
        TotalTCSAmount: result.TotalTCSAmount,
        Kasar: result.Kasar,
        ServiceChargeAmount: result.ServiceChargeAmount,
        FoodOptionID: result.FoodOptionID,
        FoodType: result.FoodType,
        PaxTypeList: result.PaxTypeList
          ? result.PaxTypeList.map((paxtype: any) => {
              return {
                PaxTypeID: paxtype.PaxTypeID,
                NoOfRoom: paxtype.NoOfRoom,
              };
            })
          : [],
        SightseenDetails: result.SightseenDetails
          ? result.SightseenDetails.map((paxtype: any) => {
              return {
                SightseenID: paxtype.SightseenID,
                SightseenName: paxtype.SightseenName,
                Rate: paxtype.Rate,
                Pax: paxtype.Pax,
              };
            })
          : [],

        TourBookingDate: result?.TourBookingDate
          ? moment(result?.TourBookingDate, "YYYY-MM-DD")?.toDate()
          : "",
        PickUpDate: result?.PickUpDate
          ? moment(result?.PickUpDate, "YYYY-MM-DD")?.toDate()
          : "",
        PickUpPlace: result?.PickUpPlace ? result?.PickUpPlace : "",
        PickUpTime: result?.PickUpTime
          ? moment(result?.PickUpTime, "HH:mm:ss")?.toDate()
          : "",
        PickUpFlightNo: result?.PickUpFlightNo ? result?.PickUpFlightNo : "",
        DropDate: result?.DropDate
          ? moment(result?.DropDate, "YYYY-MM-DD")?.toDate()
          : "",
        DropPlace: result?.DropPlace ? result?.DropPlace : "",
        DropTime: result?.DropTime
          ? moment(result?.DropTime, "HH:mm:ss")?.toDate()
          : "",
        DropFlightNo: result?.DropFlightNo ? result?.DropFlightNo : "",
      } as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching tour booking:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getByIDTourBookingStep2 = createAsyncThunk(
  "TourBooking/FindByIDTourBookingStep2",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourBooking/FindByIDTourBookingStep2`,
        { ID }
      );
      const result = response.data?.Data as any;
      return {
        ID: ID,
        RoomPaxes: result
          ? result?.map((roompax: any) => {
              return {
                RoomNo: roompax.RoomNo,
                PaxTypeID: roompax.PaxTypeID,
                SectorID: roompax.SectorID,
                TouristPaxInfo: roompax.TouristPaxInfo
                  ? roompax.TouristPaxInfo.map((touristpax: any) => {
                      return {
                        ID: touristpax.ID,
                        Amount: touristpax.Amount ? +touristpax.Amount : null,
                        ExtraCost: touristpax.ExtraCost
                          ? +touristpax.ExtraCost
                          : null,
                        FirstName: touristpax.FirstName,
                        LastName: touristpax.LastName,
                        MobileNo: touristpax.MobileNo,
                        Initial: touristpax.Initial,
                        DOB: touristpax.DOB
                          ? moment(touristpax.DOB, "YYYY-MM-DD").toDate()
                          : "",
                        FoodOptionID: touristpax.FoodOptionID
                          ? +touristpax.FoodOptionID
                          : null,
                        FoodOption: touristpax.FoodOption
                          ? touristpax.FoodOption
                          : "",
                        PassportNo: touristpax.PassportNo
                          ? touristpax.PassportNo
                          : "",
                        ExpiryDate: touristpax.ExpiryDate
                          ? moment(touristpax.ExpiryDate, "YYYY-MM-DD").toDate()
                          : "",
                        NationalityID: touristpax.NationalityID
                          ? +touristpax.NationalityID
                          : null,
                        PanNo: touristpax.PanNo ? touristpax.PanNo : "",
                        AadharNo: touristpax.AadharNo
                          ? touristpax.AadharNo
                          : "",
                        DiscountAmount: touristpax.DiscountAmount
                          ? +touristpax.DiscountAmount
                          : null,
                        OtherCountry: touristpax.OtherCountry
                          ? +touristpax.OtherCountry
                          : null,
                        IsVisa: touristpax.IsVisa ? touristpax.IsVisa : false,
                        IsVisaVerify:
                          touristpax.IsVisaVerify === true
                            ? "Approved"
                            : "Not Approved",
                        DocumentList: touristpax.DocumentList
                          ? touristpax.DocumentList
                          : [],
                      };
                    })
                  : [],
              };
            })
          : [],
      } as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching tour booking:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
export const getByIDTourBookingStep3 = createAsyncThunk(
  "TourBooking/FindByIDTourBookingStep3",
  async (TourBookingID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourBooking/FindByIDTourBookingStep3`,
        { TourBookingID }
      );
      const result = response.data?.Data as any;
      return {
        TourBookingID: TourBookingID,
        TourPlaceBooking: result
          ? result.map((tourplace: any) => {
              return {
                ID: tourplace.ID,
                TourBookingID: tourplace.TourBookingID,
                TourID: tourplace.TourID,
                PlaceID: tourplace.PlaceID,
                City: tourplace.City,
                CheckinDate: tourplace.CheckinDate
                  ? moment(tourplace.CheckinDate, "YYYY-MM-DD").toDate()
                  : "",
                NoOfNights: tourplace.NoOfNights,
                NoOfDays: tourplace.NoOfDays,
                CheckOutDate: tourplace.CheckOutDate
                  ? moment(tourplace.CheckOutDate, "YYYY-MM-DD").toDate()
                  : "",
                Position: tourplace.Position,
                Remarks: tourplace.Remarks,
                HotelID: tourplace.HotelID,
                HotelName: tourplace.HotelName,
              };
            })
          : [],
      } as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching tour booking:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getTourPlacesByTourID = createAsyncThunk(
  "TourPlace/TourPlaceByTourIDDate",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourPlace/TourPlaceByTourIDDate`,
        {
          TourID: null,
          TourDate: "",
        }
      );
      const result = response.data?.Data as any;
      const TourPlaceDetails = result.map((tourPlace: any) => ({
        TourID: tourPlace.TourID,
        Tour: tourPlace.Tour,
        PlaceID: tourPlace.PlaceID,
        Place: tourPlace.Place,
        NoOfDays: tourPlace.NoOfDays,
        NoOfNights: tourPlace.NoOfNights,
        CheckinDate: tourPlace.CheckinDate,
        CheckOutDate: tourPlace.CheckOutDate,
      })) as ITourBookingPlace[];
      return TourPlaceDetails || [];
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching tour:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getMonthWiseTourDatesByTourID = createAsyncThunk(
  "Tour/MonthWiseTourDateByTourID",
  async (TourID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Tour/MonthWiseTourDateByTourID`,
        {
          TourID,
        }
      );
      return response.data?.Data as any;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching tour dates:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getCheckAirlineSeats = createAsyncThunk(
  "Tour/CheckAirlineSeats",
  async (data: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Tour/CheckAirlineSeats`,
        data
      );
      return response.data?.Data as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching tour dates:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getTourCost = createAsyncThunk(
  "Tour/GetTourcost",
  async (data: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Tour/GetTourcost`,
        data
      );
      return response.data?.Data as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching tour dates:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getTourBySectorIDAndTravelType = createAsyncThunk(
  "Tour/TourBySectorIDAndTravelType",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Tour/TourBySectorIDAndTravelType`,
        formData
      );
      return response.data?.Data as any[];
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Tour by Sector and Travel Type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getGetPaxTourCostWithName = createAsyncThunk(
  "TourBooking/GetPaxTourCostWithName",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourBooking/GetPaxTourCostWithName`,
        formData
      );
      return response.data?.Data?.map((e: any) => ({
        ID: e.ID,
        Amount: e.Amount ? +e.Amount : null,
        ExtraCost: e.ExtraCost ? +e.ExtraCost : null,
        FirstName: e.FirstName,
        LastName: e.LastName,
        MobileNo: e.MobileNo,
        Initial: e.Initial,
        DOB: e.DOB ? moment(e.DOB, "YYYY-MM-DD").toDate() : "",
        FoodOptionID: e.FoodOptionID ? +e.FoodOptionID : null,
        FoodOption: e.FoodOption ? e.FoodOption : "",
        PassportNo: e.PassportNo ? e.PassportNo : "",
        ExpiryDate: e.ExpiryDate
          ? moment(e.ExpiryDate, "YYYY-MM-DD").toDate()
          : "",
        NationalityID: e.NationalityID ? +e.NationalityID : null,
        PanNo: e.PanNo ? e.PanNo : "",
        AadharNo: e.AadharNo ? e.AadharNo : "",
        DiscountAmount: e.DiscountAmount ? +e.DiscountAmount : null,
        IsVisa: e.IsVisa ? e.IsVisa : false,
        DocumentList: e.DocumentList ? e.DocumentList : [],
      })) as any[];
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Tour by Sector and Travel Type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createTouristInfo = createAsyncThunk(
  "TourBooking/InsertTouristInfo",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourBooking/InsertTouristInfo`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating tour booking:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const insertTourBookingItinerary = createAsyncThunk(
  "TourBooking/InsertTourBookingItinerary",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourBooking/InsertTourBookingItinerary`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error inserting tour booking itinerary:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateTourBookingItinerary = createAsyncThunk(
  "TourBooking/UpdateTourBookingItinerary",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourBooking/UpdateTourBookingItinerary`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating tour booking itinerary:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteTourBookingItinerary = createAsyncThunk(
  "TourBooking/DeleteTourBookingItinerary",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourBooking/DeleteTourBookingItinerary`,
        {
          ID: ID,
        }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting tourBooking itinerary:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getTourBookingItinerariesByTourBookingID = createAsyncThunk(
  "TourBooking/FindByIdTourBookingItinerary",
  async (TourBookingID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourBooking/FindByIdTourBookingItinerary`,
        {
          TourBookingID: TourBookingID,
        }
      );
      const result = response.data?.Data as ITourBookingItineraryList[];
      return result || [];
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching tourBooking itineraries:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getTourBookingCancellationViewByID = createAsyncThunk(
  "TourBooking/TourBookingCancellationViewByID",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourBooking/TourBookingCancellationViewByID`,
        formData
      );
      return response.data?.Data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching TourBookingCancellationViewByID:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const tourBookingCancellation = createAsyncThunk(
  "TourBooking/TourBookingCancellation",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourBooking/TourBookingCancellation`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data?.Data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching TourBookingCancellationViewByID:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getTourBookingFormViewByID = createAsyncThunk(
  "TourBooking/TourBookingFormView",
  async (ID: string) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourBooking/TourBookingFormView`,
        { TourBookingNo: ID }
      );
      const result = response.data?.Data as any;
      return {
        TourBookingID: result?.TourBookingID,
        BranchName: result?.BranchName,
        BookByName: result?.BookByName,
        TourBookingNo: result?.TourBookingNo,
        TourStartEndDate: result?.TourStartEndDate,
        NoOfNights: result?.NoOfNights,
        AirlineName: result?.AirlineName,
        NoOfDays: result?.NoOfDays,
        TourDateCode: result?.TourDateCode,
        TourName: result?.TourName,
        TravelType: result?.TravelType,
        Name: result?.Name,
        Address: result?.Address,
        CompanyName: result?.CompanyName,
        CompanyAddress: result?.CompanyAddress,
        MobileNo: result?.MobileNo,
        EmailID: result?.EmailID,
        ResidentPhoneNo: result?.ResidentPhoneNo,
        EmergencyNo: result?.EmergencyNo,
        PANCardNo: result?.PANCardNo,
        PassportNo: result?.PassportNo,
        AadharNo: result?.AadharNo,
        CompanyGSTNo: result?.CompanyGSTNo,
        BookingApprovedBy: result?.BookingApprovedBy,
        VehicleType: result?.VehicleType,
        TotalNoOfSeats: result?.TotalNoOfSeats,
        TotalNoOfRooms: result?.TotalNoOfRooms,
        TotalAdults: result?.TotalAdults,
        TotalExtraAdults: result?.TotalExtraAdults,
        TotalCWB: result?.TotalCWB,
        TotalCNB: result?.TotalCNB,
        TotalInfants: result?.TotalInfants,
        TotalNoOfPax: result?.TotalNoOfPax,
        ExtraBed: result?.ExtraBed,
        Remarks: result?.Remarks,
        TourCost: result?.TourCost,
        TourCancellationCost: result?.TourCancellationCost,
        TotalBookingAmount: result?.TotalBookingAmount,
        TotalPaidAmount: result?.TotalPaidAmount,
        RemainingAmount: result?.RemainingAmount,
        RefundAmount: result?.RefundAmount,
        PickUpPlace: result?.PickUpPlace,
        PickUpDate: result?.PickUpDate,
        DropPlace: result?.DropPlace,
        DropDate: result?.DropDate,
        TourBookingDate: result?.TourBookingDate,
        FirstName: result?.FirstName,
        LastName: result?.LastName,
        IsGST: result?.IsGST,
        IsTCS: result?.IsTCS,
        IsVisa: result?.IsVisa === true ? ["Yes"] : ["No"],
        TourCompanyName: result?.TourCompanyName,
        SectorType: result?.SectorType,
        TourBookingFormPax: result?.TourBookingFormPax
          ? result?.TourBookingFormPax?.map((pax: any) => {
              return {
                ID: pax?.ID,
                TourBookingID: pax?.TourBookingID,
                Name: pax?.Name,
                Gender: pax?.Gender,
                Age: pax?.Age,
                RoomNo: pax?.RoomNo,
                Amount: pax?.Amount,
                ExtraCost: pax?.ExtraCost,
                Discount: pax?.Discount,
                GSTAmount: pax?.GSTAmount,
                TCSAmount: pax?.TCSAmount,
                FinalCost: pax?.FinalCost,
                IsVisa: pax?.IsVisa,
                PassportNo: pax?.PassportNo,
                ExpiryDate: pax?.ExpiryDate,
                TaxableAmount: pax?.TaxableAmount,
              };
            })
          : [],
        SightseenDetails: result?.SightseenDetails
          ? result?.SightseenDetails.map((sightseen: any) => {
              return {
                SightseenID: sightseen?.SightseenID,
                SightseenName: sightseen?.SightseenName,
                Rate: sightseen?.Rate,
                Pax: sightseen?.Pax,
              };
            })
          : [],
        TourBookingPaymentDetails: result?.TourBookingPaymentDetails
          ? result?.TourBookingPaymentDetails
          : [],
        TourBookingPlaceDetails: result?.TourBookingPlaceDetails
          ? result?.TourBookingPlaceDetails?.map((places: any) => {
              return {
                HotelName: places?.HotelName,
                PlaceName: places?.PlaceName,
                CheckInDate: places?.CheckInDate,
                CheckOutDate: places?.CheckOutDate,
              };
            })
          : [],
      } as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching tour booking details:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const tourBookingPaxVisaVerify = createAsyncThunk(
  "Tour/TouristPaxIsVisaVerifyUpdate",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Tour/TouristPaxIsVisaVerifyUpdate`,
        formData
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Tourist Pax Visa Verify :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
