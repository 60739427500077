import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IDashboardDetails, IDashboardState } from "./dashboardModel";
import {
  getAllPendingDocumentTourBooking,
  getDashboard,
  getDashboardPendingPaymentReceipt,
  getDashboardTotalBooking,
  getDashboardTotalDepartureInNextFifteenDay,
  getDashboardTotalPaymentReceipt,
} from "./services/dashboard.services";

const initialState: IDashboardState = {
  loading: false,
  error: "",
  dateFilter: {
    SelectedDate: null,
    FromDate: null,
    ToDate: null,
  },
  DashboardDetails: {
    PendingDocument: 0,
    TotalBooking: 0,
    TotalPayment: 0,
    pendingPayment: 0,
    TotalDeparture: 0,
    SectorList: [],
  },
  PendingDocumentTourBookingList: [],
  TotalBookingList: [],
  TotalPaymentList: [],
  PendingPaymentList: [],
  TotalDepartureList: [],
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    clearDashboardDetails: (state) => {
      state.DashboardDetails = initialState.DashboardDetails;
    },
    clearDateFilter: (state) => {
      state.dateFilter = initialState.dateFilter;
    },
    setDateFilter: (state, action) => {
      state.dateFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDashboard.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getDashboard.fulfilled,
      (state, action: PayloadAction<IDashboardDetails>) => {
        state.loading = false;
        state.DashboardDetails = action.payload;
      }
    );
    builder.addCase(getDashboard.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.DashboardDetails = initialState.DashboardDetails;
    });

    builder.addCase(getAllPendingDocumentTourBooking.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllPendingDocumentTourBooking.fulfilled,
      (state, action: PayloadAction<any[]>) => {
        state.loading = false;
        state.PendingDocumentTourBookingList = action.payload || [];
      }
    );
    builder.addCase(
      getAllPendingDocumentTourBooking.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
        state.PendingDocumentTourBookingList = [];
      }
    );

    builder.addCase(getDashboardTotalBooking.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getDashboardTotalBooking.fulfilled,
      (state, action: PayloadAction<any[]>) => {
        state.loading = false;
        state.TotalBookingList = action.payload || [];
      }
    );
    builder.addCase(getDashboardTotalBooking.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.TotalBookingList = [];
    });

    builder.addCase(getDashboardTotalPaymentReceipt.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getDashboardTotalPaymentReceipt.fulfilled,
      (state, action: PayloadAction<any[]>) => {
        state.loading = false;
        state.TotalPaymentList = action.payload || [];
      }
    );
    builder.addCase(
      getDashboardTotalPaymentReceipt.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
        state.TotalPaymentList = [];
      }
    );

    builder.addCase(getDashboardPendingPaymentReceipt.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getDashboardPendingPaymentReceipt.fulfilled,
      (state, action: PayloadAction<any[]>) => {
        state.loading = false;
        state.PendingPaymentList = action.payload || [];
      }
    );
    builder.addCase(
      getDashboardPendingPaymentReceipt.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
        state.PendingPaymentList = [];
      }
    );

    builder.addCase(
      getDashboardTotalDepartureInNextFifteenDay.pending,
      (state) => {
        state.loading = true;
        state.error = "";
      }
    );
    builder.addCase(
      getDashboardTotalDepartureInNextFifteenDay.fulfilled,
      (state, action: PayloadAction<any[]>) => {
        state.loading = false;
        state.TotalDepartureList = action.payload || [];
      }
    );
    builder.addCase(
      getDashboardTotalDepartureInNextFifteenDay.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
        state.TotalDepartureList = [];
      }
    );
  },
});

export const { clearDashboardDetails, clearDateFilter, setDateFilter } =
  dashboardSlice.actions;
export default dashboardSlice.reducer;
