import React from "react";
// import "./login.scss";
import "./loginNew.scss";
import LOGO from "../../assets/Images/logo.png";
import MONE from "../../assets/Images/m1.jpeg";
import MTWO from "../../assets/Images/m2.jpeg";
import MTHREE from "../../assets/Images/m3.jpeg";
import MFOUR from "../../assets/Images/m4.png";
import MFIVE from "../../assets/Images/m5.png";
import MSIX from "../../assets/Images/m6.png";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import FormTextField from "../../components/formFields/FormTextField";
import {
  passwordValidator,
  requiredValidator,
} from "../../components/formFields/CommonValidator";
import FormPasswordField from "../../components/formFields/FormPasswordField";
// import FormCheckbox from "../../components/formFields/FormCheckbox";
import { useNavigate } from "react-router-dom";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { employeeLogin } from "./loginSlice";

const LoginNew = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loading = useAppSelector((state) => state.login.loading);
  const handleSubmit = async (values: any) => {
    const data = {
      UserName: values.UserName,
      Password: values.Password,
    };

    // edit by jyotindra
    const response = await dispatch(employeeLogin(data));
    if (response?.meta?.requestStatus === "fulfilled") {
      navigate("/");
    }
  };

  const MemoryImages = [
    {
      imgURL: MONE,
      title: "Beach days ...",
      alt: "Beach",
    },
    {
      imgURL: MTWO,
      title: "Campfire vibes",
      alt: "Campfire",
    },
    {
      imgURL: MTHREE,
      title: "Bye!",
      alt: "plane",
    },
    {
      imgURL: MFOUR,
      title: "Catching waves",
      alt: "catching",
    },
    {
      imgURL: MFIVE,
      title: "Awsome view",
      alt: "",
    },
    {
      imgURL: MSIX,
      title: "High Height",
      alt: "effiel",
    },
  ];
  return (
    <div className="login-container">
      <div className="memory-pictures">
        {MemoryImages.map((item: any, index: number) => (
          <figure className="memory-item" key={index}>
            <img src={item.imgURL} alt={item.alt} />
            <figcaption className="handwriting">{item.title}</figcaption>
          </figure>
        ))}
      </div>
      <div className="main-card">
        <div className="center-card">
          <img src={LOGO} alt="logo" />
          <div className="form-card">
            <h2 className="login_title">Login</h2>
            <Form
              onSubmit={handleSubmit}
              initialValues={{
                UserName: "",
                Password: "",
                RememberMe: false,
              }}
              render={(formRenderProps: FormRenderProps) => (
                <FormElement style={{ width: "100%" }}>
                  <Field
                    name={"UserName"}
                    type={"text"}
                    component={FormTextField}
                    label={"Username"}
                    placeholder="Enter Your Username"
                    validator={requiredValidator}
                    astrike={true}
                    wrapperClassName="login-form-label"
                  />
                  <Field
                    name={"Password"}
                    type={"password"}
                    component={FormPasswordField}
                    label={"Password"}
                    placeholder="Enter Your Password"
                    validator={passwordValidator}
                    wrapperClassName="login-form-label"
                  />
                  {/* <div className="forgot">
                    <Field
                      wrapperClassName={"remember-me"}
                      id={"RememberMe"}
                      name={"RememberMe"}
                      label={"Remember Me"}
                      component={FormCheckbox}
                    />
                    <Link to="/">Forgot password</Link>
                  </div> */}

                  <ButtonWithLoading
                    label="Login"
                    themeColor="primary"
                    type="submit"
                    width="100%"
                    disabled={!formRenderProps.allowSubmit || loading}
                    loading={loading}
                    className="login-btn"
                  />

                  {/* <div className="register">
                    <p>
                      Not registered yet? &nbsp;
                      <Link to="/signup">Register Now</Link>
                    </p>
                  </div> */}
                </FormElement>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginNew;
