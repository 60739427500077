import React, { useEffect } from "react";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";

// import { Button, Dialog } from "@mui/material";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import AlertBox from "../../../components/common/AlertBox";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getUserTourBooking } from "../services/reportsService";

import { useParams } from "react-router-dom";
import moment from "moment";
import { checkAcessRights } from "../../../_helper/helper";
import { EXCEL_EXPORT_ACCESS, PRINT_ACCESS } from "../../../_contstants/common";
import { Button } from "@progress/kendo-react-buttons";
import { LoadingPanel } from "../../../components/layout/Loading";

const UserTourBookingView: React.FC = () => {
  const dispatch = useAppDispatch();
  const UserWiseTourBookingReport = useAppSelector(
    (state) => state.reports.UserWiseTourBookingReport
  );
  const gridRef = React.useRef<any>(null);
  const loading = useAppSelector((state) => state.reports.loading);

  const params = useParams();
  const isExcelExportAccess = checkAcessRights(
    "/usertourbookingform",
    EXCEL_EXPORT_ACCESS
  );

  const isPrintAccess = checkAcessRights("/usertourbookingform", PRINT_ACCESS);

  const DateType = params?.DateType;
  const FromDate = params?.FromDate;
  const ToDate = params?.ToDate;
  const UserID = params?.UserID;

  useEffect(() => {
    if (DateType && FromDate && ToDate) {
      const payload = {
        DateType: DateType !== "0" ? DateType : null,
        FromDate: FromDate !== "0" ? FromDate : "",
        ToDate: ToDate !== "0" ? ToDate : "",
        UserID: UserID !== "0" ? UserID : "",
      };
      dispatch(getUserTourBooking(payload));
    }
  }, []);

  const handleExportExcel = () => {
    const table = document.querySelector(".table-bordered") as HTMLTableElement;
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.table_to_sheet(table);

    // Adjust column widths dynamically
    const colWidths: number[] = [];
    const rows = table.querySelectorAll("tr");

    rows.forEach((row) => {
      const cells = row.querySelectorAll("th, td");
      cells.forEach((cell, colIndex) => {
        const cellContent = cell.textContent || "";
        const cellLength = cellContent.length;

        // Calculate width (max of existing or current cell)
        colWidths[colIndex] = Math.max(
          colWidths[colIndex] || 10,
          cellLength + 2
        );
      });
    });
    // Apply column widths
    ws["!cols"] = colWidths.map((width) => ({ wch: width }));

    Object.keys(ws).forEach((key) => {
      if (!key.startsWith("!")) {
        const cell = ws[key];
        if (cell.z !== "m/d/yy") {
          if (typeof cell.v === "number") {
            // Check if the cell is a date (custom logic depending on your use case)
            const isDate = cell.t === "d"; // 't' might indicate the cell type in some libraries
            if (!isDate) {
              cell.t = "s"; // Set cell type to 'string'
              cell.v = `${cell.v}`; // Convert number to string
            }
          }
        } else if (cell.z === "m/d/yy") {
          cell.z = "DD-MMM-YYYY";
        }
      }
    });

    XLSX.utils.book_append_sheet(wb, ws, `Sheet 1`);

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(data, `User Tour Booking`);
  };

  const printTable = () => {
    let table = document.querySelector(
      ".table-bordered"
    ) as HTMLTableElement | null;
    let printbuttons = document.querySelector(".printbuttons") as any;
    // let report_container = document.querySelector(".report-container") as any;
    if (!table) {
      console.error("Table element not found.");
      return;
    }

    printbuttons.style.display = "none";
    // report_container.style.padding = "20px 0";
    let tableRows = table.rows.length;
    let maxRows = table.rows.length;
    let pageCount = Math.ceil(tableRows / maxRows);
    let printContent = document.createElement("div");
    let content = "";

    for (let i = 0; i < pageCount; i++) {
      content += '<div class="print-page">';
      //   content += "<h2>Page " + (i + 1) + "</h2>";
      content += '<table class="table table-bordered reportScreens">';

      let startRow = i * maxRows;
      let endRow = Math.min((i + 1) * maxRows, tableRows);

      for (let j = startRow; j < endRow; j++) {
        content += table.rows[j].outerHTML;
      }

      content += "</table>";
      content += "</div>";
    }

    printContent.innerHTML = content;
    table.style.display = "none";
    document.body.appendChild(printContent);

    const style = document.createElement("style");
    style.textContent = `@page { margin: 1cm; }`;
    document.head.appendChild(style);

    window.print();
    printContent.remove();
    table.style.display = "table";
    printbuttons.style.display = "block";
    // report_container.style.padding = "20px 10px";
  };
  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {UserWiseTourBookingReport && UserWiseTourBookingReport.length ? (
        <GridLayout cols={[{ width: "100%" }]}>
          <GridLayoutItem
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              padding: "10px 0px 10px 15px",
            }}
          >
            <Typography.h4 style={{ marginBottom: 0 }}>
              User Wise Tour Booking Report &nbsp;
              <span style={{ fontSize: 12 }}>
                (
                {FromDate
                  ? moment(FromDate, "YYYY-MM-DD").format("DD/MM/YYYY")
                  : ""}
                {" to "}
                {ToDate
                  ? moment(ToDate, "YYYY-MM-DD").format("DD/MM/YYYY")
                  : ""}
                )
              </span>
            </Typography.h4>

            <div className="printbuttons">
              {isPrintAccess && (
                <Button
                  type="button"
                  style={{
                    marginRight: "10px",
                  }}
                  fillMode={"solid"}
                  themeColor={"primary"}
                  onClick={printTable}
                >
                  Print
                </Button>
              )}

              {isExcelExportAccess && (
                <Button
                  type="button"
                  fillMode={"solid"}
                  themeColor={"primary"}
                  onClick={handleExportExcel}
                >
                  Excel Export
                </Button>
              )}
            </div>
          </GridLayoutItem>

          <div className="table-responsive" style={{ margin: "0 10px" }}>
            <table className="table table-bordered reportScreens">
              <thead>
                <tr
                  style={{
                    verticalAlign: "baseline",
                  }}
                >
                  <th style={{ background: "black", color: "white" }}>No</th>
                  <th style={{ background: "black", color: "white" }}>
                    Booking Date
                  </th>
                  <th style={{ background: "black", color: "white" }}>
                    Tour Booking No
                  </th>
                  <th style={{ background: "black", color: "white" }}>
                    Tour Name
                  </th>
                  <th style={{ background: "black", color: "white" }}>
                    Customer Name
                  </th>
                  <th style={{ background: "black", color: "white" }}>
                    Tour Start Date
                  </th>
                  <th style={{ background: "black", color: "white" }}>
                    No Of Nights
                  </th>
                  <th style={{ background: "black", color: "white" }}>
                    Total Adults
                  </th>
                  <th style={{ background: "black", color: "white" }}>
                    Total Children
                  </th>
                  <th style={{ background: "black", color: "white" }}>
                    Tour Amount
                  </th>
                  <th style={{ background: "black", color: "white" }}>
                    Air Ticket Amount
                  </th>
                  <th style={{ background: "black", color: "white" }}>
                    Total Paid Amount
                  </th>
                  <th style={{ background: "black", color: "white" }}>
                    Remaining Amount
                  </th>
                  <th style={{ background: "black", color: "white" }}>
                    Book By
                  </th>
                  <th style={{ background: "black", color: "white" }}>
                    Branch
                  </th>
                </tr>
              </thead>
              <tbody>
                {UserWiseTourBookingReport &&
                  UserWiseTourBookingReport.length > 0 &&
                  UserWiseTourBookingReport?.map((user: any, index: number) => (
                    <tr>
                      <td className="text-center">{index + 1}</td>
                      <td className="text-center">
                        {user?.BookingDate
                          ? moment(user?.BookingDate, "DD/MM/YYYY").format(
                              "DD MMM YYYY"
                            )
                          : ""}
                      </td>
                      <td className="text-center">{user?.TourBookingNo}</td>
                      <td>{user?.TourName}</td>
                      <td>{user?.CustomerName}</td>
                      <td className="text-center">
                        {user?.TourStartDate
                          ? moment(user?.TourStartDate, "DD/MM/YYYY").format(
                              "DD MMM YYYY"
                            )
                          : ""}
                      </td>
                      <td className="text-center">{user?.NoOfNights || 0}</td>
                      <td className="text-center">{user?.TotalAdults || 0}</td>
                      <td className="text-center">{user?.TotalChilds || 0}</td>
                      <td className="text-end">{user?.TourAmount || 0}</td>
                      <td className="text-end">{user?.AirTicketAmount || 0}</td>
                      <td className="text-end">{user?.TotalPaidAmount || 0}</td>
                      <td className="text-end">{user?.RemainingAmount || 0}</td>
                      <td>{user?.BookBy}</td>
                      <td>{user?.Branch}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </GridLayout>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

export default UserTourBookingView;
