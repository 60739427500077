import React, { useEffect } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Anchor, IDrawerState, opened } from "./muiDrawerSlice";
import { useAppDispatch } from "../../app/hooks";

interface CreateButtonYype {
  drawerName: string;
  drawerWidth?: number;
  label: string;
  anchor?: Anchor;
  style?: any;
  isIndirect?: boolean;
  firstClick?: boolean;
}

const ButtonCreate: React.FC<CreateButtonYype> = (props) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (props.firstClick) {
      handleCreate();
    }
  }, []);

  const handleCreate = () => {
    const drawerOptions: IDrawerState = {
      open: true,
      anchor: props?.anchor || ("right" as Anchor),
      name: props.drawerName,
      drawerWidth: props?.drawerWidth || 400,
      isIndirect: props?.isIndirect || false,
    };
    dispatch(opened(drawerOptions));
  };

  return (
    <Button
      onClick={handleCreate}
      fillMode={"solid"}
      themeColor={"primary"}
      style={props.style}
    >
      {props.label}
    </Button>
  );
};

export default ButtonCreate;
