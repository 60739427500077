import React, { useEffect, useRef } from "react";
import {
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import RippleButton from "../../../components/common/RippleButton";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useLocation } from "react-router-dom";
import { LoadingPanel } from "../../../components/layout/Loading";
import moment from "moment";
import {
  createTourDateArray,
  getActiveTourDatesByTourID,
} from "../services/tour.services";
import {
  clearTourDateDetails,
  decreaseActiveStep,
  increaseActiveStep,
} from "../tourSlice";
import { getAllActiveUsers } from "../../user/services/user.services";
import { closeDialog } from "../../../components/dialog/dialogSlice";
import ShadowCard from "../../../components/common/ShadowCard";
import TourDateArray from "./TourDateArray";
import { getAllActiveAirlines } from "../../airline/services/airline.services";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const TourDateChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const TourDateDetails = useAppSelector((state) => state.tour.TourDateDetails);

  useEffect(() => {
    const tourDatesCopy = JSON.parse(JSON.stringify(TourDateDetails))?.map(
      (tourDate: any) => ({
        ...tourDate,
        TourDate: tourDate.TourDate ? moment(tourDate.TourDate).toDate() : "",
      })
    );
    formRenderProps.onChange("TourDates", {
      value: tourDatesCopy || [],
    });
  }, [TourDateDetails]);
  return null;
};

const TourDates: React.FC = () => {
  const location = useLocation();
  const TourID = location.state?.TourID;
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.tour.loading);
  const TourDateDetails = useAppSelector((state) => state.tour.TourDateDetails);

  useEffect(() => {
    dispatch(getAllActiveUsers());
    dispatch(getAllActiveAirlines());
    return () => {
      dispatch(closeDialog());
      dispatch(clearTourDateDetails());
    };
  }, []);

  useEffect(() => {
    if (TourID) {
      dispatch(getActiveTourDatesByTourID(TourID));
    }
  }, [TourID]);

  const handleSubmit = async (values: any) => {
    if (TourID) {
      const insertPayload = {
        TourCodes: values?.TourDates
          ? values?.TourDates?.map((tourDate: any) => {
              return {
                TourID: TourID,
                ID: tourDate?.ID ? tourDate?.ID : 0,
                TourDate: tourDate?.TourDate
                  ? moment(tourDate?.TourDate).format("YYYY-MM-DD")
                  : "",
                TourCode: tourDate?.TourCode ? tourDate?.TourCode : "",
                NoOfSeats: tourDate?.NoOfSeats ? +tourDate?.NoOfSeats : null,
                TotalNoOfRooms: tourDate?.TotalNoOfRooms
                  ? +tourDate?.TotalNoOfRooms
                  : null,
                ManagerID: tourDate?.ManagerID ? tourDate?.ManagerID : null,
                AirlineID: tourDate?.AirlineID ? tourDate?.AirlineID : null,
              };
            })
          : [],
      };
      const response = await dispatch(createTourDateArray(insertPayload));
      if (response?.meta?.requestStatus === "fulfilled") {
        dispatch(increaseActiveStep());
      }
    }
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        initialValues={TourDateDetails}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <TourDateChangeWatcher formRenderProps={formRenderProps} />
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "33.33%" },
                  { width: "33.33%" },
                  { width: "33.33%" },
                ]}
              >
                <GridLayoutItem style={{ marginTop: 10 }} colSpan={3}>
                  <FieldArray
                    formRenderProps={formRenderProps}
                    component={TourDateArray}
                    name="TourDates"
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={3}
                  style={{
                    textAlign: "end",
                    marginTop: "20px",
                    width: "100%",
                  }}
                >
                  <RippleButton
                    type="button"
                    fillMode="outline"
                    themeColor="primary"
                    style={{ marginRight: 4 }}
                    onClick={() => dispatch(decreaseActiveStep())}
                  >
                    Previous
                  </RippleButton>
                  <ButtonWithLoading
                    label={"Save & Next"}
                    type="submit"
                    disabled={!formRenderProps.allowSubmit || loading}
                    loading={loading}
                  />
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
          </FormElement>
        )}
      />
    </>
  );
};

export default TourDates;
