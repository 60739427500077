export type ISelectionType = {
  value: number;
  label: string;
};

export const GENDER = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
];

export const STATUSARRAY = [
  { label: "Active", value: 1 },
  { label: "Inactive", value: 2 },
];

export const SECTORTYPE = [
  { label: "DOMESTIC", value: "DOMESTIC" },
  { label: "INTERNATIONAL", value: "INTERNATIONAL" },
];

export const TRAVELTYPE = [
  { label: "TOUR", value: "TOUR" },
  { label: "PACKAGE", value: "PACKAGE" },
];

export const STARRATING = [
  { label: "1 STAR", value: 1 },
  { label: "2 STAR", value: 2 },
  { label: "3 STAR", value: 3 },
  { label: "4 STAR", value: 4 },
  { label: "5 STAR", value: 5 },
];

export const BOOLEAN = [
  { label: "Yes", value: 1 },
  { label: "NO", value: 2 },
];

export const DateType = [
  { label: "Booking Date", value: 1 },
  { label: "Travel Date", value: 2 },
];

export const TCS_ARRAY = [{ label: "TCS 5%", value: 5 }];

export const INSERT_OPT_TYPE = 1;
export const EDIT_OPT_TYPE = 2;
export const DELETE_OPT_TYPE = 3;
export const GET_BY_GUID_OPT_TYPE = 4;
export const GET_ALL_ACTIVE_WITH_FILTER_OPT_TYPE = 5;
export const GET_ALL_OPT_TYPE = 6;
export const ACTIVE_INACTIVE_OPT_TYPE = 7;

export const DRAWER_WIDTH = 400;

export const INSERT_ACCESS = "1";
export const EDIT_ACCESS = "2";
export const DELETE_ACCESS = "3";
export const PDF_DOWNLOAD_ACCESS = "4";
export const EXCEL_EXPORT_ACCESS = "5";
export const DOC_UPLOAD_ACCESS = "6";
export const PRINT_ACCESS = "7";

export const VEHICLESHARINGPAX = [
  { label: "2 PAX", value: 1 },
  { label: "3 PAX", value: 2 },
  { label: "4 PAX", value: 3 },
  { label: "5 PAX", value: 4 },
  { label: "SHARING", value: 5 },
];

export const VEHICLESHARING = [
  { label: "SHARING", value: "SHARING" },
  { label: "SEPARATE", value: "SEPARATE" },
];

export const NAMEPREFIX = [
  { label: "MR.", value: "MR." },
  { label: "MRS.", value: "MRS." },
  { label: "MS.", value: "MS." },
];

export const PAYMENTFOR = [
  { label: "TOUR", value: "TOUR" },
  { label: "PACKAGE", value: "PACKAGE" },
  { label: "TICKET", value: "TICKET" },
];
export const PAYMENTTYPE = [
  { label: "CASH", value: "CASH" },
  { label: "CREDIT CARD", value: "CREDIT CARD" },
  { label: "CHEQUE", value: "CHEQUE" },
  { label: "NEFT / IMPS", value: "NEFT / IMPS" },
  { label: "UPI", value: "UPI" },
  { label: "BANK DEPOSIT", value: "BANK DEPOSIT" },
  // { label: "CREDIT NOTE", value: "CREDIT NOTE" },
];
export const UPITYPE = [
  { label: "Paytm", value: "PAYTM" },
  { label: "PhonePay", value: "PHONEPAY" },
  { label: "GooglePay", value: "GOOGLEPAY" },
];

export const DateRangeFilter = [
  { value: 1, label: "Today" },
  { value: 2, label: "Yesterday" },
  { value: 3, label: "This Week" },
  { value: 4, label: "Last 30 Days" },
  { value: 5, label: "This Month" },
  { value: 6, label: "Custom" },
];
