import React from "react";
import { useAppSelector } from "../app/hooks";
import AppDrawer from "../components/muidrawer/Drawer";
import CreateCity from "./city/CreateCity";
import CreateState from "./state/CreateState";
import CreateAirlineClass from "./airlineclass/CreateAirlineClass";
import CreateAirport from "./airport/CreateAirport";
import CreateCountry from "./country/CreateCountry";
import CreateCurrency from "./currency/CreateCurrency";
import CreateDocument from "./document/CreateDocument";
import CreateFlightPlace from "./flightplace/CreateFlightPlace";
import CreateFoodOption from "./foodoption/CreateFoodOption";
import CreateGst from "./gst/CreateGst";
import CreateHotelMapping from "./hotelmapping/CreateHotelMapping";
import CreateItinerary from "./itinerary/CreateItinerary ";
import CreateMealType from "./mealtype/CreateMealType";
import CreateMenu from "./menu/CreateMenu";
import CreateNationality from "./nationality/CreateNationality";
import CreatePaxType from "./paxtype/CreatePaxType";
import CreateRegion from "./region/CreateRegion";
import CreateRights from "./rights/CreateRights";
import CreateRoomCategory from "./roomcategory/CreateRoomCategory";
import CreateRoomType from "./roomtype/CreateRoomType";
import CreateRoute from "./route/CreateRoute";
import CreateSightseen from "./sightseen/CreateSightseen";
import CreateSpecialityType from "./specialitytype/CreateSpecialityType";
import CreateVehicleSharingPax from "./vehiclesharingpax/CreateVehicleSharingPax";
import CreateVehicleType from "./vehicleType/CreateVehicleType";
import CreateVendorType from "./vendortype/CreateVendorType";

const Drawers = () => {
  const isDrawerOpen = useAppSelector((state) => state.muidrawer.open);
  const drawerName = useAppSelector((state) => state.muidrawer.name);

  //   ***********Note : Here you need to add key as a form name same which you passed from create Button***********

  const drawerComponents: Record<string, React.JSX.Element> = {
    airlineClassForm: <CreateAirlineClass />,
    airportForm: <CreateAirport />,
    cityForm: <CreateCity />,
    countryForm: <CreateCountry />,
    stateForm: <CreateState />,
    currencyForm: <CreateCurrency />,
    documentForm: <CreateDocument />,
    flightPlaceForm: <CreateFlightPlace />,
    foodOptionForm: <CreateFoodOption />,
    gstForm: <CreateGst />,
    hotelMappingForm: <CreateHotelMapping />,
    itineraryForm: <CreateItinerary />,
    mealTypeForm: <CreateMealType />,
    menuForm: <CreateMenu />,
    nationalityform: <CreateNationality />,
    paxTypeForm: <CreatePaxType />,
    regionForm: <CreateRegion />,
    rightsForm: <CreateRights />,
    roomCategoryForm: <CreateRoomCategory />,
    roomTypeForm: <CreateRoomType />,
    routeForm: <CreateRoute />,
    sightseenForm: <CreateSightseen />,
    specialityTypeForm: <CreateSpecialityType />,
    vehicleSharingPaxForm: <CreateVehicleSharingPax />,
    vehicleTypeForm: <CreateVehicleType />,
    vendorTypeForm: <CreateVendorType />,
  };

  return (
    <>
      {isDrawerOpen && Object.keys(drawerComponents).includes(drawerName) && (
        <AppDrawer>{drawerComponents[drawerName]}</AppDrawer>
      )}
    </>
  );
};

export default Drawers;
