import React, { useEffect, useRef } from "react";
import {
  filterBy,
  CompositeFilterDescriptor,
} from "@progress/kendo-data-query";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { PagerTargetEvent } from "@progress/kendo-react-data-tools";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import { Typography } from "@progress/kendo-react-common";
import { getDashboardPendingPaymentReceipt } from "../services/dashboard.services";
import { formatIndianNumber } from "../../../_helper/helper";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { LoadingPanel } from "../../../components/layout/Loading";
import AlertBox from "../../../components/common/AlertBox";

const TotalPendingPayment: React.FC = () => {
  return (
    <>
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Pending Payment List
          </Typography.h4>
        </GridLayoutItem>
        <GridLayoutItem>
          <PaymentReceiptGridComponent />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 10 };

const PaymentReceiptGridComponent: React.FC = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);
  const dateFilter = useAppSelector((state) => state.dashboard.dateFilter);

  const PendingPaymentList = useAppSelector(
    (state) => state.dashboard.PendingPaymentList
  );
  const loading = useAppSelector((state) => state.dashboard.loading);

  useEffect(() => {
    const payload = {
      FromDate: dateFilter?.FromDate ? dateFilter?.FromDate : "",
      ToDate: dateFilter?.ToDate ? dateFilter?.ToDate : "",
    };
    dispatch(getDashboardPendingPaymentReceipt(payload));
  }, [dateFilter]);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All" ? PendingPaymentList.length : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className={`table-header ${props.index === 8 ? "locked-header" : ""}`}
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  if (loading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      {PendingPaymentList && PendingPaymentList.length ? (
        <KendoGrid
          style={{ maxHeight: "calc(100vh - 152px)" }}
          filterable={true}
          className="responsive-table"
          filter={filter}
          data={
            filter
              ? filterBy(PendingPaymentList, filter).slice(
                  page.skip,
                  page.take + page.skip
                )
              : PendingPaymentList.slice(page.skip, page.take + page.skip)
          }
          onFilterChange={handleFilterChange}
          skip={page.skip}
          take={page.take}
          total={PendingPaymentList.length}
          pageable={{
            buttonCount: 5,
            pageSizes: [5, 10, 15, "All"],
            pageSizeValue: pageSizeValue,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column
            field="TourBookingNo"
            title="Booking No"
            width={150}
            // cell={(props: any) => (
            //   <td>
            //     <p
            //       className="hoverableName"
            //       style={{ fontWeight: 600, cursor: "pointer", margin: 0 }}
            //       onClick={() =>
            //         window.open(
            //           `/paymentreceiptcustomerview/${props.dataItem?.ReceiptNo}`
            //         )
            //       }
            //     >
            //       {props.dataItem?.ReceiptNo}
            //     </p>
            //   </td>
            // )}
          />
          <Column field="MobileNo" title="Mobile No" width={150} />
          <Column
            field="Sector"
            title="Sector"
            width={200}
            cell={(props: any) => (
              <td>
                <div
                  dangerouslySetInnerHTML={{ __html: props?.dataItem?.Sector }}
                />
              </td>
            )}
          />
          <Column field="TravelType" title="Travel Type" width={150} />
          <Column field="Tour" title="Tour" width={200} />
          <Column field="TourDateCode" title="Tour Date Code" width={150} />
          <Column field="TourStartDate" title="Tour Start Date" width={150} />
          <Column field="Name" title="Name" width={150} />
          <Column field="NoOfNights" title="No Of Nights" width={150} />
          <Column
            field="TotalAmount"
            title="Total Amount"
            width={160}
            cell={(props: any) => (
              <td className="text-end">
                {props.dataItem.TotalAmount
                  ? formatIndianNumber(props.dataItem.TotalAmount)
                  : 0}
              </td>
            )}
          />
          <Column
            field="PendingAmount"
            title="Pending Amount"
            width={160}
            cell={(props: any) => (
              <td className="text-end">
                {props.dataItem.PendingAmount
                  ? formatIndianNumber(props.dataItem.PendingAmount)
                  : 0}
              </td>
            )}
          />
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

export default TotalPendingPayment;
