import React, { useEffect, useRef } from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormTextField from "../../components/formFields/FormTextField";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import { clearNationalityDetails } from "./nationalitySlice";
import {
  createNationality,
  getAllNationalities,
  getNationalityByID,
  updateNationality,
} from "./services/nationality.services";
import { closed } from "../../components/muidrawer/muiDrawerSlice";
import { Typography } from "@progress/kendo-react-common";
import { RxCross1 } from "react-icons/rx";

const CreateNationality: React.FC = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);

  const NationalityDetail = useAppSelector(
    (state) => state.nationality.NationalityDetail
  );
  const NationalityID = useAppSelector((state) => state.muidrawer.data);
  const formLoading = useAppSelector((state) => state.nationality.formLoading);

  useEffect(() => {
    if (NationalityID) {
      dispatch(getNationalityByID(NationalityID));
    }
  }, [NationalityID]);

  useEffect(() => {
    return () => {
      dispatch(clearNationalityDetails());
      dispatch(closed());
    };
  }, []);

  const handleSubmit = async (values: any) => {
    try {
      if (!NationalityID) {
        const insertPayload = {
          Nationality: values.Nationality,
          IsActive: true,
        };
        const response = await dispatch(createNationality(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearNationalityDetails());
          dispatch(getAllNationalities());
        }
      } else {
        const payload = {
          ID: NationalityID,
          Nationality: values.Nationality,
          IsActive: values.IsActive,
        };
        const response = await dispatch(updateNationality(payload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearNationalityDetails());
          dispatch(getAllNationalities());
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  const handleFormClose = () => {
    dispatch(closed());
  };

  if (formLoading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={NationalityDetail}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ width: "100%" }}>
          <GridLayout
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
            gap={{ rows: 10 }}
            cols={[{ width: "100%" }]}
          >
            <GridLayoutItem className="master-drawer-header">
              <Typography.h4 className="m-0">
                {NationalityID ? "Update Nationality" : "Create Nationality"}
              </Typography.h4>
              <Button
                type="button"
                fillMode={"flat"}
                themeColor={"dark"}
                style={{ marginRight: 4 }}
                onClick={() => dispatch(closed())}
              >
                <RxCross1 />
              </Button>
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="Nationality"
                label="Nationality"
                placeholder="I.e. Indian"
                astrike={true}
                component={FormTextField}
                validator={requiredValidator}
              />
            </GridLayoutItem>
            <GridLayoutItem
              style={{
                marginTop: "20px",
                width: "100%",
              }}
            >
              <ButtonWithLoading
                label={!NationalityID ? "Create" : "Update"}
                type="submit"
                disabled={!formRenderProps.allowSubmit || formLoading}
                loading={formLoading}
              />
              <Button
                type="button"
                fillMode={"outline"}
                themeColor={"primary"}
                style={{ marginLeft: 5 }}
                onClick={handleFormClose}
              >
                Cancel
              </Button>
            </GridLayoutItem>
          </GridLayout>
        </FormElement>
      )}
    />
  );
};

export default CreateNationality;
