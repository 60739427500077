import { Typography } from "@progress/kendo-react-common";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import FormSelectionField from "../../../components/formFields/FormSelectionField";
import { requiredValidator } from "../../../components/formFields/CommonValidator";
import FormTextField from "../../../components/formFields/FormTextField";
import { useLocation } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import {
  deleteTourRate,
  getTourRatesByTourID,
  insertTourRate,
  updateTourRate,
} from "../services/tour.services";
import moment from "moment";
import {
  clearTourCostID,
  clearTourCostDetails,
  setTourCostDetails,
  setTourCostID,
  increaseActiveStep,
  decreaseActiveStep,
} from "../tourSlice";
import FormDatePicker from "../../../components/formFields/FormDateField";
import { getAllActiveRoomTypes } from "../../roomtype/services/roomType.services";
import RippleButton from "../../../components/common/RippleButton";
import AppDialog, {
  DialogCloseButton,
} from "../../../components/dialog/Dialog";
import { Button } from "@progress/kendo-react-buttons";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCellProps,
  GridPageChangeEvent,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import {
  HeaderThElement,
  PagerTargetEvent,
} from "@progress/kendo-react-data-tools";
import {
  closeDialog,
  openDialog,
} from "../../../components/dialog/dialogSlice";
import IconButton from "../../../components/common/IconButton";
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import AlertBox from "../../../components/common/AlertBox";
import { LoadingPanel } from "../../../components/layout/Loading";
import { getAllActiveCurrencies } from "../../currency/services/currency.services";
import { ICurrency } from "../../currency/currencyModel";
import ShadowCard from "../../../components/common/ShadowCard";

const TourRates: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const TourID = location.state?.TourID;

  useEffect(() => {
    dispatch(getTourRatesByTourID(+TourID));
    dispatch(getAllActiveRoomTypes());
    dispatch(getAllActiveCurrencies());
    return () => {
      dispatch(clearTourCostDetails());
    };
  }, []);

  return (
    <>
      <DeleteTourRateDialog />
      <TourRateForm />
      <TourRateGridComponent />
    </>
  );
};

const TourRateForm: React.FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const TourID = location.state?.TourID;
  const loading = useAppSelector((state) => state.tour.loading);
  const RoomTypeList = useAppSelector((state) => state.roomType.RoomTypeList);
  const TourCostDetails = useAppSelector((state) => state.tour.TourCostDetails);
  const CurrencyList = useAppSelector((state) => state.currency.CurrencyList);

  const [formKey, setFormKey] = useState(1);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [TourCostDetails]);

  const handleSubmit = async (values: any) => {
    try {
      if (!TourCostDetails?.ID) {
        const insertPayload = {
          TourID: +TourID,
          StartDate: values?.StartDate
            ? moment(values?.StartDate).format("YYYY-MM-DD")
            : "",
          EndDate: values?.EndDate
            ? moment(values?.EndDate).format("YYYY-MM-DD")
            : "",
          CurrencyID: values?.CurrencyID ? +values?.CurrencyID : null,
          TourCategoryID: values?.TourCategoryID
            ? +values?.TourCategoryID
            : null,
          Nights: values?.Nights ? +values?.Nights : null,
          TwinSharing: values?.TwinSharing ? +values?.TwinSharing : null,
          ThreeSharing: values?.ThreeSharing ? +values?.ThreeSharing : null,
          ExtraAdults: values?.ExtraAdults ? +values?.ExtraAdults : null,
          CWB: values?.CWB ? +values?.CWB : null,
          CNB: values?.CNB ? +values?.CNB : null,
          Infants: values?.Infants ? +values?.Infants : null,
        };
        const response = await dispatch(insertTourRate(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(getTourRatesByTourID(TourID));
          setFormKey(formKey + 1);
          dispatch(clearTourCostDetails());
        }
      } else {
        const editPayload = {
          ID: values?.ID ? +values?.ID : null,
          TourID: +TourID,
          StartDate: values?.StartDate
            ? moment(values?.StartDate).format("YYYY-MM-DD")
            : "",
          EndDate: values?.EndDate
            ? moment(values?.EndDate).format("YYYY-MM-DD")
            : "",
          CurrencyID: values?.CurrencyID ? +values?.CurrencyID : null,
          TourCategoryID: values?.TourCategoryID
            ? +values?.TourCategoryID
            : null,
          Nights: values?.Nights ? +values?.Nights : null,
          TwinSharing: values?.TwinSharing ? +values?.TwinSharing : null,
          ThreeSharing: values?.ThreeSharing ? +values?.ThreeSharing : null,
          ExtraAdults: values?.ExtraAdults ? +values?.ExtraAdults : null,
          CWB: values?.CWB ? +values?.CWB : null,
          CNB: values?.CNB ? +values?.CNB : null,
          Infants: values?.Infants ? +values?.Infants : null,
        };
        const response = await dispatch(updateTourRate(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(getTourRatesByTourID(TourID));
          setFormKey(formKey + 1);
          dispatch(clearTourCostDetails());
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  return (
    <Form
      onSubmit={handleSubmit}
      key={formKey}
      initialValues={TourCostDetails}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement>
          <ShadowCard style={{ padding: 10 }}>
            <GridLayout
              style={{ marginRight: 40 }}
              gap={{ rows: 0, cols: 10 }}
              cols={[
                { width: "20%" },
                { width: "20%" },
                { width: "20%" },
                { width: "20%" },
                { width: "20%" },
              ]}
            >
              <GridLayoutItem colSpan={5}>
                <Typography.h4>{"Tour Rate"}</Typography.h4>
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name={`StartDate`}
                  label="Start Date"
                  format="dd/MM/yyyy"
                  minDate={moment().toDate()}
                  component={FormDatePicker}
                  astrike={true}
                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name={`EndDate`}
                  label="End Date"
                  format="dd/MM/yyyy"
                  minDate={moment(
                    formRenderProps.valueGetter("StartDate")
                  ).toDate()}
                  component={FormDatePicker}
                  validator={requiredValidator}
                  astrike={true}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name={`TourCategoryID`}
                  label={"Tour Type"}
                  component={FormSelectionField}
                  validator={requiredValidator}
                  astrike={true}
                  options={RoomTypeList.map((room: any) => {
                    return {
                      value: room.ID,
                      label: room.RoomType,
                    };
                  })}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  id={"CurrencyID"}
                  name={"CurrencyID"}
                  label={"Currency"}
                  component={FormSelectionField}
                  isAddNew={true}
                  drawerName={"currencyForm"}
                  isIndirect={true}
                  validator={requiredValidator}
                  astrike={true}
                  options={CurrencyList?.map((currency: ICurrency) => {
                    return {
                      value: currency.ID,
                      label: currency.CurrencyName,
                    };
                  })}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  wrapperClassName="w-100"
                  name="Nights"
                  label="Nights"
                  type="number"
                  component={FormTextField}
                  validator={requiredValidator}
                  astrike={true}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  wrapperClassName="w-100"
                  name="TwinSharing"
                  label="Twin Sharing"
                  type="number"
                  component={FormTextField}
                  validator={requiredValidator}
                  astrike={true}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  wrapperClassName="w-100"
                  name="ThreeSharing"
                  label="Three Sharing"
                  type="number"
                  component={FormTextField}
                  validator={requiredValidator}
                  astrike={true}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  wrapperClassName="w-100"
                  name="ExtraAdults"
                  label="Extra Adult"
                  type="number"
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  wrapperClassName="w-100"
                  name="CWB"
                  label="CWB"
                  type="number"
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  wrapperClassName="w-100"
                  name="CNB"
                  label="CNB"
                  type="number"
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="Infants"
                  label="Infant"
                  type="number"
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem
                colSpan={4}
                style={{ textAlign: "end", marginTop: 20 }}
              >
                <RippleButton
                  type="button"
                  fillMode="outline"
                  themeColor="primary"
                  style={{ marginRight: 10 }}
                  onClick={() => dispatch(decreaseActiveStep())}
                >
                  Previous
                </RippleButton>
                <RippleButton
                  fillMode="outline"
                  themeColor="primary"
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    setFormKey(formKey + 1);
                    dispatch(clearTourCostDetails());
                  }}
                >
                  Cancel
                </RippleButton>
                <ButtonWithLoading
                  label={TourCostDetails?.ID ? "Update" : "Add"}
                  type="submit"
                  disabled={!formRenderProps.allowSubmit || loading}
                  loading={loading}
                />
                <RippleButton
                  fillMode="solid"
                  themeColor="primary"
                  style={{ marginLeft: 10 }}
                  onClick={() => dispatch(increaseActiveStep())}
                >
                  Next
                </RippleButton>
              </GridLayoutItem>
            </GridLayout>
          </ShadowCard>
        </FormElement>
      )}
    />
  );
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 10 };

const TourRateGridComponent: React.FC = () => {
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const TourCostList = useAppSelector((state) => state.tour.TourCostList);
  const loading = useAppSelector((state) => state.tour.loading);

  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All" ? TourCostList.length : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEdit = (item: any) => {
      const payload = {
        ID: item.ID,
        TourID: item.TourID,
        StartDate: item.StartDate
          ? moment(item.StartDate, "DD/MM/YYYY").toDate()
          : "",
        EndDate: item.EndDate
          ? moment(item.EndDate, "DD/MM/YYYY").toDate()
          : "",
        CurrencyID: item.CurrencyID,
        TourCategoryID: item.TourCategoryID,
        Nights: item.Nights,
        TwinSharing: item.TwinSharing,
        ThreeSharing: item.ThreeSharing,
        ExtraAdults: item.ExtraAdults || 0,
        CWB: item.CWB || 0,
        CNB: item.CNB || 0,
        Infants: item.Infants || 0,
      };
      dispatch(setTourCostDetails(payload));
    };
    const handleOpenDeleteDialog = (ID: number) => {
      dispatch(openDialog("deleteTourRate"));
      dispatch(setTourCostID(ID));
    };

    return (
      <td
        className={props.className}
        style={{
          ...props.style,
          textAlign: "center",
          display: "flex",
          justifyContent: "space-evenly",
        }}
      >
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Edit"
          onClick={() => handleEdit(props.dataItem)}
        >
          <FiEdit className="absolute-position" style={{ fontSize: "20px" }} />
        </IconButton>
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Delete"
          onClick={() => handleOpenDeleteDialog(props.dataItem.ID)}
        >
          <MdDelete
            className="absolute-position"
            style={{ fontSize: "24px" }}
          />
        </IconButton>
      </td>
    );
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className={`table-header ${props.index === 11 ? "locked-header" : ""}`}
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {TourCostList && TourCostList.length ? (
        <KendoGrid
          className="responsive-table"
          style={{ marginTop: 20 }}
          data={TourCostList.map((e: any) => {
            return {
              ...e,
              StartDate: e.StartDate
                ? moment(e.StartDate, "YYYY-MM-DD").format("DD/MM/YYYY")
                : "",
              EndDate: e.EndDate
                ? moment(e.EndDate, "YYYY-MM-DD").format("DD/MM/YYYY")
                : "",
            };
          })}
          skip={page.skip}
          take={page.take}
          total={TourCostList.length}
          pageable={{
            buttonCount: 5,
            pageSizes: [5, 10, 15, "All"],
            pageSizeValue: pageSizeValue,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column field="StartDate" title="Start Date" width={120} />
          <Column field="EndDate" title="End Date" width={120} />
          <Column field="TourCategory" title="Tour Type" width={120} />
          <Column field="CurrencyName" title="Currency" width={120} />
          <Column field="Nights" title="Nights" width={100} />
          <Column field="TwinSharing" title="2 Sharing" width={100} />
          <Column field="ThreeSharing" title="3 Sharing" width={100} />
          <Column field="ExtraAdults" title="Extra Adult" width={110} />
          <Column field="CWB" title="CWB" width={100} />
          <Column field="CNB" title="CNB" width={100} />
          <Column field="Infants" title="Infant" width={100} />
          <Column
            field="ID"
            title="Actions"
            cell={MyEditCommandCell}
            width={"110px"}
            filterable={false}
            locked={true}
          />
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

const DeleteTourRateDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const TourID = location.state?.TourID;
  const TourCostID = useAppSelector((state) => state.tour.TourCostID);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);

  const handleDeleteAction = async (ID: number | null) => {
    if (ID) {
      const response = await dispatch(deleteTourRate(ID));
      if (response?.meta?.requestStatus === "fulfilled") {
        dispatch(getTourRatesByTourID(TourID));
        dispatch(closeDialog());
        dispatch(clearTourCostID());
      } else {
        dispatch(getTourRatesByTourID(TourID));
        dispatch(closeDialog());
        dispatch(clearTourCostID());
      }
    }
  };

  return (
    <>
      {dialogName === "deleteTourRate" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Tour Rate"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Tour Rate?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(TourCostID)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default TourRates;
