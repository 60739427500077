import { Card, CardHeader } from "@progress/kendo-react-layout";
import React from "react";
import { IconType } from "react-icons";

const hexToRgba = (hex: string, alpha: number = 1): string => {
  let cleanHex = hex.replace(/^#/, "");
  let r = 0,
    g = 0,
    b = 0;
  if (cleanHex.length === 3) {
    r = parseInt(cleanHex[0] + cleanHex[0], 16);
    g = parseInt(cleanHex[1] + cleanHex[1], 16);
    b = parseInt(cleanHex[2] + cleanHex[2], 16);
  } else if (cleanHex.length === 6 || cleanHex.length === 8) {
    r = parseInt(cleanHex.substring(0, 2), 16);
    g = parseInt(cleanHex.substring(2, 4), 16);
    b = parseInt(cleanHex.substring(4, 6), 16);
  }
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};
interface DashBoardCardProps {
  title: string;
  cardColor: string;
  amount: any;
  icon: IconType;
}

const DashBoardCard: React.FC<DashBoardCardProps> = ({
  icon: Icon,
  amount,
  cardColor,
  title,
}) => {
  return (
    <Card
      style={{
        backgroundColor: hexToRgba(cardColor, 0.4),
        // minWidth: "250px",
        padding: "15px",
        position: "relative",
        boxShadow: `${hexToRgba(
          cardColor,
          0.2
        )} 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px`,
      }}
    >
      <CardHeader style={{ border: "none" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              backgroundColor: hexToRgba(cardColor),
              borderRadius: 5,
              padding: 10,
              marginRight: "10px",
              color: "#fff",
            }}
          >
            <Icon size={52} />
          </div>
          <div>
            <h2 style={{ margin: 0 }}>{amount}</h2>
          </div>
        </div>
        <p style={{ margin: "10px 0 0 0", fontWeight: "bold", fontSize: 18 }}>
          {title}
        </p>
      </CardHeader>
      <Icon
        style={{ position: "absolute", right: 40, bottom: 0, opacity: 0.3 }}
        color={cardColor}
        size={130}
      />
    </Card>
  );
};

export default DashBoardCard;
