import React, { useEffect, useRef } from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormTextField from "../../components/formFields/FormTextField";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import {
  createItinerary,
  getAllActiveItinerary,
  getAllItinerary,
  getItineraryByID,
  updateItinerary,
} from "./services/itinerary.services";
import { clearItineraryDetails } from "./itinerarySlice";
import { Typography } from "@progress/kendo-react-common";
import { RxCross1 } from "react-icons/rx";
import { closed } from "../../components/muidrawer/muiDrawerSlice";
import { STATUSARRAY } from "../../_contstants/common";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { getAllActiveSector } from "../sector/services/sector.services";
import FormRichTextField from "../../components/formFields/FormRichTextField";

const CreateItinerary: React.FC = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);

  const ItineraryDetail = useAppSelector(
    (state) => state.itinerary.ItineraryDetail
  );
  const ItineraryID = useAppSelector((state) => state.muidrawer.data);
  const formLoading = useAppSelector((state) => state.itinerary.formLoading);
  const SectorList = useAppSelector((state) => state.sector.SectorList);
  const isIndirect = useAppSelector((state) => state.muidrawer.isIndirect);

  useEffect(() => {
    dispatch(getAllActiveSector());
    return () => {
      dispatch(clearItineraryDetails());
      dispatch(closed());
    };
  }, []);

  useEffect(() => {
    if (ItineraryID) {
      dispatch(getItineraryByID(ItineraryID));
    }
  }, [ItineraryID]);

  const handleSubmit = async (values: any) => {
    const formData = {
      SectorID: values?.SectorID,
      ItineraryTitle: values?.ItineraryTitle,
      Description: values?.Description,
      IsActive: values?.IsActive === 1,
    };
    try {
      if (!ItineraryID) {
        const response = await dispatch(createItinerary(formData));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearItineraryDetails());
          if (isIndirect) {
            dispatch(getAllActiveItinerary());
          } else {
            dispatch(getAllItinerary());
          }
        }
      } else {
        const editPayload = {
          ...formData,
          ID: ItineraryID,
        };
        const response = await dispatch(updateItinerary(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearItineraryDetails());
          dispatch(getAllItinerary());
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };
  const handleFormClose = () => {
    dispatch(closed());
  };
  if (formLoading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={ItineraryDetail}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ width: "100%" }}>
          <GridLayout
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
            gap={{ rows: 10 }}
            cols={[{ width: "100%" }]}
          >
            <GridLayoutItem
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "17px 0 8px",
              }}
            >
              <Typography.h4 className="m-0">
                {ItineraryID ? "Update Itinerary " : "Create Itinerary"}
              </Typography.h4>
              <Button
                type="button"
                fillMode={"flat"}
                themeColor={"dark"}
                style={{ marginRight: 4 }}
                onClick={() => dispatch(closed())}
              >
                <RxCross1 />
              </Button>
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                id={"SectorID"}
                name={"SectorID"}
                component={FormSelectionField}
                validator={requiredValidator}
                astrike={true}
                label={"Sector"}
                placeholder={"Select Sector"}
                options={SectorList?.map((sector: any) => {
                  return {
                    value: sector.ID,
                    label: sector.SectorName,
                  };
                })}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="ItineraryTitle"
                label="Itinerary"
                placeholder={"Itinerary Title"}
                component={FormTextField}
                astrike={true}
                validator={requiredValidator}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="Description"
                label="Description"
                component={FormRichTextField}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                id={"IsActive"}
                name={"IsActive"}
                component={FormSelectionField}
                astrike={true}
                validator={requiredValidator}
                label={"Status"}
                options={STATUSARRAY?.map((status: any) => {
                  return {
                    value: status.value,
                    label: status.label,
                  };
                })}
              />
            </GridLayoutItem>
            <GridLayoutItem
              colSpan={3}
              style={{
                textAlign: "start",
                marginTop: "20px",
                width: "100%",
              }}
            >
              <ButtonWithLoading
                label={ItineraryID ? "Update" : "Create"}
                type="submit"
                disabled={!formRenderProps.allowSubmit || formLoading}
                loading={formLoading}
              />
              <Button
                type="button"
                fillMode={"outline"}
                themeColor={"primary"}
                style={{ marginLeft: 4 }}
                onClick={handleFormClose}
              >
                Cancel
              </Button>
            </GridLayoutItem>
          </GridLayout>
        </FormElement>
      )}
    />
  );
};

export default CreateItinerary;
