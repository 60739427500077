import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast } from "../../../components/toast/Toasts";

export const getDashboard = createAsyncThunk(
  "DashBoard/DashBoard",
  async (filter: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/DashBoard/DashBoard`,
        filter
      );
      if (response.data.Status === 200) {
        return response.data?.Data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      console.error("Error Fetching InquirySubtype:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllPendingDocumentTourBooking = createAsyncThunk(
  "TourBooking/PendingDocumentTourBookingList",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourBooking/PendingDocumentTourBookingList`,
        formData
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error(
        "Error Fetching Pending Document/Approval Tour Booking:",
        error
      );
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getDashboardTotalBooking = createAsyncThunk(
  "DashBoard/BookingDashBoard",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/DashBoard/BookingDashBoard`,
        formData
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Total Tour Booking:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getDashboardTotalPaymentReceipt = createAsyncThunk(
  "DashBoard/PaymentReceiptDashBoard",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/DashBoard/PaymentReceiptDashBoard`,
        formData
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Total Payment Receipt:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getDashboardPendingPaymentReceipt = createAsyncThunk(
  "DashBoard/PendingPaymentOf15DaysDepartureList",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/DashBoard/PendingPaymentOf15DaysDepartureList`,
        formData
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Pending Payment Receipt:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getDashboardTotalDepartureInNextFifteenDay = createAsyncThunk(
  "DashBoard/Next15DaysDepartureList",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/DashBoard/Next15DaysDepartureList`,
        formData
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching total Pax departure:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
