import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";
import { Typography } from "@progress/kendo-react-common";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import ShadowCard from "../../components/common/ShadowCard";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import FormTextField from "../../components/formFields/FormTextField";
import {
  createBranch,
  getBranchByID,
  updateBranch,
} from "./services/branch.services";
import { clearBranchDetails } from "./branchSlice";
import { getAllActiveCities } from "../city/services/city.services";
import { getAllActiveStates } from "../state/services/state.services";
import { getAllActiveCountries } from "../country/services/country.services";
import { ICity } from "../city/cityModel";
import { findIndex } from "lodash";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { getAllActiveCompanies } from "../company/services/company.services";
import { ICompany } from "../company/companyModel";
import { IState } from "../state/stateModel";
import { ICountry } from "../country/countryModel";
import { STATUSARRAY } from "../../_contstants/common";

interface CityChangeWatcherProps {
  formRenderProps: FormRenderProps;
  CityList: ICity[];
}

const CityChangeWatcher: React.FC<CityChangeWatcherProps> = ({
  CityList,
  formRenderProps,
}) => {
  const cityID = formRenderProps.valueGetter("CityID");

  useEffect(() => {
    const CityID = cityID ? cityID : undefined;
    const cityIndex = findIndex(CityList, { ID: CityID });
    if (cityIndex > -1) {
      const city = CityList[cityIndex];
      formRenderProps.onChange("StateID", { value: city.StateID });
      formRenderProps.onChange("CountryID", { value: city.CountryID });
    } else {
      formRenderProps.onChange("CityID", { value: "" });
      formRenderProps.onChange("StateID", { value: "" });
      formRenderProps.onChange("CountryID", { value: "" });
    }
  }, [cityID]);
  return null;
};

const CreateBranch: React.FC = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const BranchID = location.state?.BranchID;

  const BranchDetail = useAppSelector((state) => state.branch.BranchDetail);
  const loading = useAppSelector((state) => state.branch.loading);
  const CityList = useAppSelector((state) => state.city.CityList);
  const StateList = useAppSelector((state) => state.state.StateList);
  const CountryList = useAppSelector((state) => state.country.CountryList);
  const CompanyList = useAppSelector((state) => state.company.CompanyList);

  useEffect(() => {
    if (BranchID) {
      dispatch(getBranchByID(BranchID));
    }
    return () => {
      dispatch(clearBranchDetails());
    };
  }, [BranchID]);

  useEffect(() => {
    dispatch(getAllActiveCities());
    dispatch(getAllActiveStates());
    dispatch(getAllActiveCountries());
    dispatch(getAllActiveCompanies());
  }, []);

  const handleSubmit = async (values: any) => {
    try {
      if (BranchID) {
        const editPayload = {
          ID: BranchID,
          CompanyID: values.CompanyID ? +values.CompanyID : null,
          BranchName: values.BranchName ? values.BranchName : "",
          Address: values.Address ? values.Address : "",
          CityID: values.CityID ? +values.CityID : null,
          StateID: values.StateID ? +values.StateID : null,
          CountryID: values.CountryID ? +values.CountryID : null,
          Pincode: values.Pincode ? values.Pincode.toString() : "",
          BranchHead: values.BranchHead ? values.BranchHead : "",
          MobileNo: values.MobileNo ? values.MobileNo.toString() : "",
          EmailID: values.EmailID ? values.EmailID : "",
          IsActive: values.IsActive === 1,
        };
        const response = await dispatch(updateBranch(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate(-1);
        }
      } else {
        const insertPayload = {
          CompanyID: values.CompanyID ? +values.CompanyID : null,
          BranchName: values.BranchName ? values.BranchName : "",
          Address: values.Address ? values.Address : "",
          CityID: values.CityID ? +values.CityID : null,
          StateID: values.StateID ? +values.StateID : null,
          CountryID: values.CountryID ? +values.CountryID : null,
          Pincode: values.Pincode ? values.Pincode.toString() : "",
          BranchHead: values.BranchHead ? values.BranchHead : "",
          MobileNo: values.MobileNo ? values.MobileNo.toString() : "",
          EmailID: values.EmailID ? values.EmailID : "",
          IsActive: values.IsActive === 1,
        };
        const response = await dispatch(createBranch(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/branch");
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      <div>
        <Form
          onSubmit={handleSubmit}
          initialValues={BranchDetail}
          render={(formRenderProps: FormRenderProps) => (
            <FormElement>
              <ShadowCard style={{ padding: 12 }}>
                <GridLayout
                  style={{ marginRight: 20 }}
                  gap={{ rows: 5, cols: 10 }}
                  cols={[
                    { width: "33.33%" },
                    { width: "33.33%" },
                    { width: "33.33%" },
                  ]}
                >
                  <GridLayoutItem colSpan={3}>
                    <Typography.h4>
                      {BranchID ? "Update Branch" : "Create Branch"}
                    </Typography.h4>
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="BranchName"
                      label="Branch"
                      placeholder="i.e. DriveIn"
                      component={FormTextField}
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      id={"CompanyID"}
                      name={"CompanyID"}
                      label={"Company"}
                      placeholder="Company Name"
                      component={FormSelectionField}
                      astrike={true}
                      validator={requiredValidator}
                      options={CompanyList?.map((company: ICompany) => {
                        return {
                          value: company?.ID,
                          label: company?.CompanyName,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="BranchHead"
                      label="Branch Head"
                      placeholder="Branch Head Name"
                      component={FormTextField}
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem colSpan={2}>
                    <Field
                      name="Address"
                      label="Address"
                      placeholder="Address"
                      component={FormTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="Pincode"
                      label="Pincode"
                      type="number"
                      placeholder="i.e. 380038"
                      component={FormTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      id={"CityID"}
                      name={"CityID"}
                      label={"City"}
                      placeholder="i.e. Ahmedabad"
                      component={FormSelectionField}
                      validator={requiredValidator}
                      astrike={true}
                      options={CityList?.map((city: ICity) => {
                        return {
                          value: city?.ID,
                          label: city?.CityName,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      id={"StateID"}
                      name={"StateID"}
                      label={"State"}
                      placeholder="i.e. Gujarat"
                      component={FormSelectionField}
                      // disabled={true}
                      options={StateList?.map((state: IState) => {
                        return {
                          value: state?.ID,
                          label: state?.StateName,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      id={"CountryID"}
                      name={"CountryID"}
                      label={"Country"}
                      placeholder="i.e. India"
                      component={FormSelectionField}
                      // disabled={true}
                      options={CountryList?.map((country: ICountry) => {
                        return {
                          value: country?.ID,
                          label: country?.CountryName,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="MobileNo"
                      label="Mobile No"
                      type="number"
                      max="10"
                      placeholder="i.e. 9999999999"
                      component={FormTextField}
                      astrike={true}
                      validator={requiredValidator}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="EmailID"
                      label="Email ID"
                      placeholder="i.e. example@gmail.com"
                      component={FormTextField}
                      astrike={true}
                      validator={requiredValidator}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name={`IsActive`}
                      label="Status"
                      component={FormSelectionField}
                      validator={requiredValidator}
                      astrike={true}
                      options={STATUSARRAY?.map((status: any) => {
                        return {
                          value: status?.value,
                          label: status?.label,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem
                    colSpan={3}
                    style={{
                      marginTop: "20px",
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "end",
                    }}
                  >
                    <div>
                      <Button
                        type="button"
                        fillMode={"outline"}
                        themeColor={"primary"}
                        style={{ marginRight: 4 }}
                        onClick={() => navigate("/branch")}
                      >
                        Cancel
                      </Button>
                      <ButtonWithLoading
                        label={BranchID ? "Update" : "Create"}
                        type="submit"
                        disabled={!formRenderProps.allowSubmit || loading}
                        loading={loading}
                      />
                    </div>
                  </GridLayoutItem>
                </GridLayout>
              </ShadowCard>
              <CityChangeWatcher
                CityList={CityList}
                formRenderProps={formRenderProps}
              />
            </FormElement>
          )}
        />
      </div>
    </>
  );
};

export default CreateBranch;
