import * as React from "react";
import { FieldRenderProps, FieldWrapper } from "@progress/kendo-react-form";
import { Hint, Error, Label } from "@progress/kendo-react-labels";
import { TextArea } from "@progress/kendo-react-inputs";
import { CgDanger } from "react-icons/cg";

const FormTextArea = (props: FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    optional,
    rows,
    wrapperClassName,
    astrike = false,
    ...others
  } = props;

  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hindId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";

  return (
    <FieldWrapper className={wrapperClassName}>
      <Label
        // label={label}
        editorId={id}
        // editorValue={others.value}
        editorValid={valid}
        editorDisabled={disabled}
        optional={optional}
        // className="field-label"
      >
        {label} {astrike && <span style={{ color: "red" }}>*</span>}
      </Label>
      <TextArea
        valid={valid}
        id={id}
        size={"large"}
        disabled={disabled}
        rows={rows || 4}
        ariaDescribedBy={`${hindId} ${errorId}`}
        {...others}
      />
      {showHint && <Hint id={hindId}>{hint}</Hint>}
      {showValidationMessage && (
        <Error className="d-flex justify-content-end" id={errorId}>
          <span
            className="d-flex align-items-center"
            style={{
              background: "#DF4957",
              color: "white",
              padding: "1px 8px 1px 5px",
              borderRadius: 10,
              gap: 5,
            }}
          >
            <CgDanger style={{marginBottom:2}} /> {validationMessage}
          </span>
        </Error>
      )}
    </FieldWrapper>
  );
};

export default FormTextArea;
