import React, { useEffect } from "react";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Link } from "react-router-dom";
import DashBoardCard from "./DashBoardCard";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import ShadowCard from "../../components/common/ShadowCard";
import FormDatePicker from "../../components/formFields/FormDateField";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import moment from "moment";
import { PiBookBookmarkFill } from "react-icons/pi";
import { setDateFilter } from "./dashboardSlice";
import Trip from "../../assets/Images/trip.png";
import { getDashboard } from "./services/dashboard.services";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { DateRangeFilter } from "../../_contstants/common";
import { getFilterFromToDate } from "../../_helper/helper";
import { GiTakeMyMoney } from "react-icons/gi";
import { FaPersonCircleQuestion } from "react-icons/fa6";
import { TbCalendarTime } from "react-icons/tb";
import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartCategoryAxisTitle,
  ChartSeries,
  ChartSeriesItem,
  ChartTooltip,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartValueAxisTitle,
} from "@progress/kendo-react-charts";

interface DateFilterChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const DateFilterChangeWatcher: React.FC<DateFilterChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const SelectedDate = formRenderProps.valueGetter("SelectedDate");
  const FromDate = formRenderProps.valueGetter("FromDate");
  const ToDate = formRenderProps.valueGetter("ToDate");
  const next15Days = new Date(new Date().setDate(new Date().getDate() + 15));

  useEffect(() => {
    if (SelectedDate !== 6) {
      formRenderProps.onChange("FromDate", { value: null });
      formRenderProps.onChange("ToDate", { value: null });
      const filter = getFilterFromToDate(SelectedDate);
      console.log("filter", filter)
      if (filter.FromDate === "" && filter.ToDate === "") {
        const DefaultDate = {
          FromDate: moment(new Date()).format("YYYY-MM-DD"),
          ToDate: moment(next15Days).format("YYYY-MM-DD"),
        };
        dispatch(getDashboard(DefaultDate));
        dispatch(setDateFilter({ SelectedDate, ...DefaultDate }));
      } else {
        dispatch(getDashboard(filter));
        dispatch(setDateFilter({ SelectedDate, ...filter }));
      }
    }
  }, [SelectedDate]);

  useEffect(() => {
    const filter = {
      FromDate: FromDate
        ? moment(FromDate).format("YYYY-MM-DD")
        : moment(new Date()).format("YYYY-MM-DD"),
      ToDate: ToDate
        ? moment(ToDate).format("YYYY-MM-DD")
        : moment(next15Days).format("YYYY-MM-DD"),
    };
    if (FromDate && ToDate) {
      dispatch(getDashboard(filter));
      dispatch(setDateFilter({ SelectedDate, ...filter }));
    }
  }, [FromDate, ToDate]);
  return null;
};

const Dashboard: React.FC = () => {
  const UserName = useAppSelector((state) => state.login.loginDetails.UserName);
  const dashboardDetails = useAppSelector(
    (state) => state.dashboard.DashboardDetails
  );
  const dateFilter = useAppSelector((state) => state?.dashboard?.dateFilter);
  const formatedDateFilter = {
    ...dateFilter,
    FromDate: dateFilter.FromDate
      ? moment(dateFilter?.FromDate, "YYYY-MM-DD").toDate()
      : "",
    ToDate: dateFilter.ToDate
      ? moment(dateFilter?.ToDate, "YYYY-MM-DD").toDate()
      : "",
  };

  const today = moment(new Date()).format("YYYY-MM-DD");
  const next15Days = moment(
    new Date(new Date().setDate(new Date().getDate() + 15))
  ).format("YYYY-MM-DD");
  const ChartData =
    dashboardDetails &&
    dashboardDetails?.SectorList &&
    dashboardDetails?.SectorList?.length > 0
      ? dashboardDetails?.SectorList?.map((sector: any) => +sector?.TotalTour)
      : [];

  const ChartCategory =
    dashboardDetails &&
    dashboardDetails?.SectorList &&
    dashboardDetails?.SectorList?.length > 0
      ? dashboardDetails?.SectorList?.map((sector: any) => sector?.SectorName)
      : [];
  console.log("ChartData", ChartData, ChartCategory);

  return (
    <>
      <GridLayoutItem style={{ width: "100%" }}>
        {UserName && (
          <div
            style={{
              position: "relative",
              background: "rgb(255, 207, 223)",
              padding: "40px 20px",
              borderRadius: "10px",
              minHeight: "150px",
              overflow: "hidden",
              marginBottom: "15px",
              boxShadow: "5px 5px 10px #0000002b",
              border: "1px solid rgb(198 190 193)",
            }}
          >
            <div style={{ position: "relative", zIndex: 1 }}>
              <h4 style={{ fontWeight: 100, marginBottom: 20 }}>
                Welcome,
                <br />
                <span style={{ fontWeight: 900, fontSize: 35 }}>
                  {UserName}
                </span>
              </h4>
              <p className="m-0">
                All you need is just a{" "}
                <span style={{ color: "darkred", fontWeight: 600 }}>
                  Click away
                </span>
              </p>
            </div>
            <img
              src={Trip}
              style={{
                height: 235,
                position: "absolute",
                right: 10,
                top: 0,
                filter: "opacity(0.5)",
              }}
            />
          </div>
        )}
        <ShadowCard style={{ padding: 12 }}>
          <Form
            onSubmit={() => {}}
            initialValues={formatedDateFilter}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement style={{ width: "100%" }}>
                <GridLayout
                  style={{ marginRight: 40 }}
                  gap={{ rows: 20, cols: 20 }}
                  cols={[
                    { width: "33.33%" },
                    { width: "33.33%" },
                    { width: "33.33%" },
                  ]}
                >
                  <GridLayoutItem>
                    <Typography.h4 style={{ margin: 0 }}>
                      My Dashboard
                    </Typography.h4>
                  </GridLayoutItem>
                  <GridLayoutItem></GridLayoutItem>
                  <GridLayoutItem className="d-flex justify-content-end align-items-center ">
                    {formRenderProps.valueGetter("SelectedDate") ? (
                      ""
                    ) : (
                      <span style={{ whiteSpace: "nowrap" }}>
                        Next 15 Days Data!
                      </span>
                    )}
                    {formRenderProps.valueGetter("SelectedDate") === 6 && (
                      <>
                        <Field
                          wrapperStyle={{
                            margin: "0 0 0 10px",
                            width: 150,
                            flex: "none",
                          }}
                          name="FromDate"
                          component={FormDatePicker}
                          size={"small"}
                          format={"dd/MM/yyyy"}
                          placeholder="From Date"
                        />
                        <Field
                          wrapperStyle={{
                            margin: "0 0 0 10px",
                            width: 150,
                            flex: "none",
                          }}
                          name="ToDate"
                          component={FormDatePicker}
                          format={"dd/MM/yyyy"}
                          size={"small"}
                          minDate={moment(
                            formRenderProps.valueGetter("FromDate")
                          ).toDate()}
                          disabled={
                            formRenderProps.valueGetter("FromDate")
                              ? false
                              : true
                          }
                          placeholder="To Date"
                        />
                      </>
                    )}
                    <Field
                      wrapperStyle={{
                        margin: "0 0 0 10px",
                        width: 200,
                        flex: "none",
                      }}
                      name="SelectedDate"
                      component={FormSelectionField}
                      size={"small"}
                      placeholder="Filter"
                      options={DateRangeFilter?.map((date: any) => {
                        return {
                          value: date.value,
                          label: date.label,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Link
                      to={
                        !dashboardDetails?.PendingDocument
                          ? "#"
                          : "/dashboard/pendingdocumenttourbooking"
                      }
                      state={{
                        filter: {
                          FromDate: dateFilter?.FromDate || today,
                          ToDate: dateFilter?.ToDate || next15Days,
                        },
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <DashBoardCard
                        icon={TbCalendarTime}
                        amount={dashboardDetails?.PendingDocument}
                        cardColor="#5473E8"
                        title="Pending Tour Bookings"
                      />
                    </Link>
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Link
                      to={
                        !dashboardDetails?.TotalBooking
                          ? "#"
                          : "/dashboard/bookings"
                      }
                      state={{
                        filter: {
                          FromDate: dateFilter?.FromDate || today,
                          ToDate: dateFilter?.ToDate || next15Days,
                        },
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <DashBoardCard
                        icon={PiBookBookmarkFill}
                        amount={`${dashboardDetails?.TotalBooking}`}
                        cardColor="#36C2CF"
                        title="Bookings"
                      />
                    </Link>
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Link
                      to={
                        !dashboardDetails?.pendingPayment
                          ? "#"
                          : "/dashboard/pendingpayments"
                      }
                      state={{
                        filter: {
                          FromDate: dateFilter?.FromDate || today,
                          ToDate: dateFilter?.ToDate || next15Days,
                        },
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <DashBoardCard
                        icon={GiTakeMyMoney}
                        amount={`₹${dashboardDetails?.pendingPayment}`}
                        cardColor="#FB9F26"
                        title="Pending Payments"
                      />
                    </Link>
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Link
                      to={
                        !dashboardDetails?.TotalDeparture
                          ? "#"
                          : "/dashboard/next15daysdeparture"
                      }
                      state={{
                        filter: {
                          FromDate: dateFilter?.FromDate || today,
                          ToDate: dateFilter?.ToDate || next15Days,
                        },
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <DashBoardCard
                        icon={FaPersonCircleQuestion}
                        amount={dashboardDetails?.TotalDeparture}
                        cardColor="#f86e6e66"
                        title="Total Pax Dept. in 15 Days"
                      />
                    </Link>
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Link
                      to={
                        !dashboardDetails?.TotalPayment
                          ? "#"
                          : "/dashboard/payments"
                      }
                      state={{
                        filter: {
                          FromDate: dateFilter?.FromDate || today,
                          ToDate: dateFilter?.ToDate || next15Days,
                        },
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <DashBoardCard
                        icon={GiTakeMyMoney}
                        amount={`₹${dashboardDetails?.TotalPayment}`}
                        cardColor="#4aeb4a"
                        title="Payments"
                      />
                    </Link>
                  </GridLayoutItem>
                  <DateFilterChangeWatcher formRenderProps={formRenderProps} />
                </GridLayout>
              </FormElement>
            )}
          />
        </ShadowCard>
        <ShadowCard style={{ padding: 12, marginTop: 10 }}>
          <GridLayout gap={{ rows: 5, cols: 0 }} cols={[{ width: "100%" }]}>
            <GridLayoutItem>
              <Chart>
                <ChartSeries>
                  <ChartCategoryAxis>
                    <ChartCategoryAxisItem categories={ChartCategory}>
                      <ChartCategoryAxisTitle text="Sector" />
                    </ChartCategoryAxisItem>
                  </ChartCategoryAxis>
                  <ChartValueAxis>
                    <ChartValueAxisItem>
                      <ChartValueAxisTitle text="Total Tour" />
                    </ChartValueAxisItem>
                  </ChartValueAxis>
                  <ChartSeriesItem
                    type="column"
                    gap={2}
                    spacing={0.25}
                    data={ChartData}
                    tooltip={{ visible: true }}
                  />
                </ChartSeries>
                <ChartTooltip
                  render={(e: any) => (
                    <div>
                      <strong>Sector : {e.point?.category}</strong> <br />
                      <strong>Total Tour : {e.point?.value}</strong>
                    </div>
                  )}
                />
              </Chart>
            </GridLayoutItem>
          </GridLayout>
        </ShadowCard>
      </GridLayoutItem>
    </>
  );
};

export default Dashboard;
