import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IPaymentReceipt } from "../paymentReceiptModel";
import moment from "moment";

export const getAllPaymentReceipts = createAsyncThunk(
  "PaymentReceipt/FindAllPaymentReceipt",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/PaymentReceipt/FindAllPaymentReceipt`,
        {
          ReceiptNo: "",
          TourBookingNo: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Payment Receipts:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createPaymentReceipt = createAsyncThunk(
  "PaymentReceipt/InsertPaymentReceipt",
  async (PaxTypeData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/PaymentReceipt/InsertPaymentReceipt`,
        PaxTypeData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating payment receipt:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updatePaymentReceipt = createAsyncThunk(
  "PaymentReceipt/UpdatePaymentReceipt",
  async (PaxTypeData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/PaymentReceipt/UpdatePaymentReceipt`,
        PaxTypeData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating payment receipt:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deletePaymentReceipt = createAsyncThunk(
  "PaymentReceipt/DeletePaymentReceipt",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/PaymentReceipt/DeletePaymentReceipt`,
        { ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting payment receipt:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getPaymentReceiptByID = createAsyncThunk(
  "PaymentReceipt/FindByIDPaymentReceipt",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/PaymentReceipt/FindByIDPaymentReceipt`,
        { ID }
      );
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        ReceiptNo: result.ReceiptNo,
        PaymentFor: result.PaymentFor,
        OldReceiptNo: result.OldReceiptNo,
        Name: result.Name,
        CompanyID: result.CompanyID,
        CompanyName: result.CompanyName,
        PaymentDate: result.PaymentDate
          ? moment(result.PaymentDate, "YYYY-MM-DD").toDate()
          : "",
        ChequeDate: result.ChequeDate
          ? moment(result.ChequeDate, "YYYY-MM-DD").toDate()
          : "",
        TransactionDate: result.TransactionDate
          ? moment(result.TransactionDate, "YYYY-MM-DD").toDate()
          : "",
        ChequeNo: result.ChequeNo,
        TransactionNo: result.TransactionNo,
        Amount: result.Amount,
        Commission: result.Commission,
        PaymentType: result.PaymentType,
        BankName: result.BankName,
        BranchName: result.BranchName,
        IsRefund: result.IsRefund ? 1 : 2,
        ReceivedByID: result.ReceivedByID,
        Remarks: result.Remarks,

        TourBookingNo: result?.TourBookingNo,
        UpiType: result?.UpiType,
        CreditNoteNo: result?.CreditNoteNo,
        BankID: result?.BankID,
        ApprovedBy: result?.ApprovedBy,

        ApprovedDateTime: result?.ApprovedDateTime,
        ReceiptStatus: result?.ReceiptStatus,
        IsReceiptUploaded: result?.IsReceiptUploaded,
        ReceiptImage: result?.ReceiptImage,
        ApprovedRemarks: result?.ApprovedRemarks,
        ReceivedBy: result?.ReceivedBy,
      } as IPaymentReceipt;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching payment receipt:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getPaymentReceiptSummaryByBookingNo = createAsyncThunk(
  "PaymentReceipt/GetPaymentReceiptSummary",
  async (BookingNo: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/PaymentReceipt/GetPaymentReceiptSummary`,
        { BookingNo }
      );
      const result = response.data?.Data as any;
      return {
        PNRNo: result.PNRNo,
        Name: result.Name ? result.Name : "",
        TourAmount: result.TourAmount ? +result.TourAmount : null,
        TourCancellationAmount: result.TourCancellationAmount
          ? +result.TourCancellationAmount
          : null,
        TotalTourBookingAmount: result.TotalTourBookingAmount
          ? +result.TotalTourBookingAmount
          : null,
        TotalPaidAmount: result.TotalPaidAmount
          ? +result.TotalPaidAmount
          : null,
        AirTicketAmount: result.AirTicketAmount
          ? +result.AirTicketAmount
          : null,
        RefundAmount: result.RefundAmount ? +result.RefundAmount : null,
        RemainingAmount: result.RemainingAmount
          ? +result.RemainingAmount
          : null,
        Company: result.Company ? result.Company : "",
        CompanyID: result.CompanyID ? +result.CompanyID : null,
        PaymentFor: result.PaymentFor ? result.PaymentFor : "",
        PaymentHistory: result.PaymentHistory ? result.PaymentHistory : [],

        RailwayTicketAmount: result?.RailwayTicketAmount,
        TourCreditNoteAmount: result?.TourCreditNoteAmount,
        AirTicketCreditNoteAmount: result?.AirTicketCreditNoteAmount,
        RailwayTicketCreditNoteAmount: result?.RailwayTicketCreditNoteAmount,
        TotalCreditNoteAmount: result?.TotalCreditNoteAmount,
        CreditVoucherIssueAmount: result?.CreditVoucherIssueAmount,
      };
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching payment receipt:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActivePaymentReceipts = createAsyncThunk(
  "PaymentReceipt/FindAllActivePaymentReceipt",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/PaymentReceipt/FindAllActivePaymentReceipt`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Payment Receipt:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
