import React, { useEffect, useRef } from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormTextField from "../../components/formFields/FormTextField";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import { findIndex } from "lodash";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { clearCityDetails } from "./citySlice";
import { getAllActiveCountries } from "../country/services/country.services";
import { getAllActiveStates } from "../state/services/state.services";
import {
  createCity,
  getAllActiveCities,
  getAllCities,
  getCityByID,
  updateCity,
} from "./services/city.services";
import { IState } from "../state/stateModel";
import { closed } from "../../components/muidrawer/muiDrawerSlice";
import { Typography } from "@progress/kendo-react-common";
import { RxCross1 } from "react-icons/rx";
import { STATUSARRAY } from "../../_contstants/common";

interface StateChangeWatcherProps {
  formRenderProps: FormRenderProps;
  StateList: IState[];
}

const StateChangeWatcher: React.FC<StateChangeWatcherProps> = ({
  StateList,
  formRenderProps,
}) => {
  const stateID = formRenderProps.valueGetter("StateID");

  useEffect(() => {
    const StateID = stateID ? stateID : undefined;
    const stateIndex = findIndex(StateList, { ID: StateID });
    if (stateIndex > -1) {
      const state = StateList[stateIndex];
      formRenderProps.onChange("CountryID", { value: state.CountryID });
    } else {
      formRenderProps.onChange("StateID", { value: "" });
      formRenderProps.onChange("CountryID", { value: "" });
    }
  }, [stateID]);
  return null;
};

const CreateCity: React.FC = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);

  const CountryList = useAppSelector((state) => state.country.CountryList);
  const StateList = useAppSelector((state) => state.state.StateList);
  const CityDetail = useAppSelector((state) => state.city.CityDetail);
  const CityID = useAppSelector((state) => state.muidrawer.data);
  const formLoading = useAppSelector((state) => state.city.formLoading);
  const isIndirect = useAppSelector((state) => state.muidrawer.isIndirect);

  useEffect(() => {
    dispatch(getAllActiveCountries());
    dispatch(getAllActiveStates());
    return () => {
      dispatch(clearCityDetails());
      dispatch(closed());
    };
  }, []);

  useEffect(() => {
    if (CityID) {
      dispatch(getCityByID(CityID));
    }
  }, [CityID]);

  const handleSubmit = async (values: any) => {
    try {
      if (!CityID) {
        const payload = {
          CityName: values.CityName,
          StateID: values.StateID,
          CountryID: values.CountryID,
          IsActive: values?.IsActive === 1,
        };
        const response = await dispatch(createCity(payload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearCityDetails());
          dispatch(getAllCities());
          if (isIndirect) {
            dispatch(getAllActiveCities());
          } else {
            dispatch(getAllCities());
          }
        }
      } else {
        const payload = {
          ID: CityID,
          CityName: values.CityName,
          StateID: values.StateID,
          CountryID: values.CountryID,
          IsActive: values?.IsActive === 1,
        };
        const response = await dispatch(updateCity(payload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearCityDetails());
          dispatch(getAllCities());
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  const handleFormClose = () => {
    dispatch(closed());
  };

  if (formLoading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={CityDetail}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ width: "100%" }}>
          <GridLayout
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
            gap={{ rows: 10 }}
            cols={[{ width: "100%" }]}
          >
            <GridLayoutItem className="master-drawer-header">
              <Typography.h4 className="m-0">
                {CityID ? "Update City" : "Create City"}
              </Typography.h4>
              <Button
                type="button"
                fillMode={"flat"}
                themeColor={"dark"}
                style={{ marginRight: 4 }}
                onClick={() => dispatch(closed())}
              >
                <RxCross1 />
              </Button>
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="CityName"
                label="City Name"
                placeholder="i.e. Ahmedabad"
                astrike={true}
                component={FormTextField}
                validator={requiredValidator}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                id={"StateID"}
                name={"StateID"}
                label={"State Name"}
                placeholder="i.e. Gujarat"
                component={FormSelectionField}
                astrike={true}
                validator={requiredValidator}
                options={StateList.map((state: any) => {
                  return {
                    value: state.ID,
                    label: state.StateName,
                  };
                })}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                id={"CountryID"}
                name={"CountryID"}
                label={"Country Name"}
                placeholder="i.e. India"
                component={FormSelectionField}
                // validator={requiredValidator}
                disabled={true}
                options={CountryList.map((country: any) => {
                  return {
                    value: country.ID,
                    label: country.CountryName,
                  };
                })}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                id={"IsActive"}
                name={"IsActive"}
                label={"Status"}
                component={FormSelectionField}
                validator={requiredValidator}
                astrike={true}
                options={STATUSARRAY?.map((status: any) => {
                  return {
                    value: status?.value,
                    label: status?.label,
                  };
                })}
              />
            </GridLayoutItem>
            <GridLayoutItem
              colSpan={3}
              style={{
                textAlign: "start",
                marginTop: "20px",
                width: "100%",
              }}
            >
              <ButtonWithLoading
                label={CityID ? "Update" : "Create"}
                type="submit"
                disabled={!formRenderProps.allowSubmit || formLoading}
                loading={formLoading}
              />
              <Button
                type="button"
                fillMode={"outline"}
                themeColor={"primary"}
                style={{ marginLeft: 4 }}
                onClick={handleFormClose}
              >
                Cancel
              </Button>
            </GridLayoutItem>
            <StateChangeWatcher
              StateList={StateList}
              formRenderProps={formRenderProps}
            />
          </GridLayout>
        </FormElement>
      )}
    />
  );
};

export default CreateCity;
