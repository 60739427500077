import {
  Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import React, { useEffect, useRef, useState } from "react";
import ShadowCard from "../../../components/common/ShadowCard";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import RippleButton from "../../../components/common/RippleButton";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import FormSelectionField from "../../../components/formFields/FormSelectionField";
import { BOOLEAN } from "../../../_contstants/common";
import { ISector } from "../../sector/sectorModel";
import {
  getAllActiveCities,
  getCityBySectorID,
} from "../../city/services/city.services";
import { ICompany } from "../../company/companyModel";
import { getAllActiveCompanies } from "../../company/services/company.services";
import FormDatePicker from "../../../components/formFields/FormDateField";
import FormTextField from "../../../components/formFields/FormTextField";
import { IState } from "../../state/stateModel";
import { ICountry } from "../../country/countryModel";
import { getAllActiveStates } from "../../state/services/state.services";
import { getAllActiveCountries } from "../../country/services/country.services";
import { findIndex } from "lodash";
import PaxDetailsArray from "./PaxDetailsArray";
import { ITourList } from "../tourBookingModel";
import {
  createTourBooking,
  getByIDTourBookingStep1,
  getCheckAirlineSeats,
  getMonthWiseTourDatesByTourID,
  getTourBySectorIDAndTravelType,
  getTourCost,
  updateTourBooking,
} from "../services/tourBooking.services";
import moment from "moment";
import { ErrorToast } from "../../../components/toast/Toasts";
import {
  clearAvailabelSeats,
  clearTourBookingCostDetails,
  clearTourBookingStep1Details,
  increaseTourBookingActiveStep,
} from "../tourBookingSlice";
import {
  requiredValidator,
  requiredValidatorNumber0,
} from "../../../components/formFields/CommonValidator";
import FormCheckbox from "../../../components/formFields/FormCheckbox";
import { getAllActiveSector } from "../../sector/services/sector.services";
import { IFoodOption } from "../../foodoption/foodOptionModel";
import { getAllActiveFoodOptions } from "../../foodoption/services/foodOption.services";
import { ICity } from "../../city/cityModel";
import FormTextArea from "../../../components/formFields/FormTextArea";
import SightseenDetailsArray from "./SightseenDetailsArray";
import { getAllActiveCurrencies } from "../../currency/services/currency.services";
import { ICurrency } from "../../currency/currencyModel";
import { getAllActiveTours } from "../../tour/services/tour.services";
import FormTimePicker from "../../../components/formFields/FormTimeField";
export const TRAVELTYPE = [
  { label: "TOUR", value: "TOUR" },
  { label: "PACKAGE", value: "PACKAGE" },
];
export const DISCOUNTTYPE = [
  { label: "FLAT", value: 1 },
  { label: "PERCENTAGE", value: 2 },
];

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}

interface CompanyCityChangeWatcherProps {
  formRenderProps: FormRenderProps;
  StateList: IState[];
}
const SectorChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const isTourDateRef = useRef(true);
  const SectorID = formRenderProps.valueGetter("SectorID");
  const TravelType = formRenderProps.valueGetter("TravelType");
  useEffect(() => {
    if (SectorID) {
      dispatch(getCityBySectorID(SectorID));
    }
    if (SectorID && TravelType) {
      const payload = {
        SectorID: SectorID,
        TravelType: TravelType,
      };
      dispatch(getTourBySectorIDAndTravelType(payload));
    }
    if (!isTourDateRef.current) {
      formRenderProps.onChange("TourID", {
        value: null,
      });
    } else {
      isTourDateRef.current = false;
    }
  }, [SectorID, TravelType]);
  return null;
};

const CompanyCityChangeWatcher: React.FC<CompanyCityChangeWatcherProps> = ({
  StateList,
  formRenderProps,
}) => {
  const stateID = formRenderProps.valueGetter("CompanyStateID");

  useEffect(() => {
    const StateID = stateID ? stateID : undefined;
    const stateIndex = findIndex(StateList, { ID: StateID });
    if (stateIndex > -1) {
      const state = StateList[stateIndex];
      formRenderProps.onChange("CompanyCountryID", { value: state.CountryID });
    } else {
      formRenderProps.onChange("CompanyStateID", { value: "" });
      formRenderProps.onChange("CompanyCountryID", { value: "" });
    }
  }, [stateID]);
  return null;
};

const TourChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const isTourDateRef = useRef(true);
  const TourID = formRenderProps.valueGetter("TourID");

  useEffect(() => {
    if (TourID) {
      dispatch(getMonthWiseTourDatesByTourID(TourID));
    }
    if (!isTourDateRef.current) {
      formRenderProps.onChange("TourDate", {
        value: null,
      });
    } else {
      isTourDateRef.current = false;
    }
  }, [TourID]);

  // useEffect(() => {
  //   if (TravelType === "PACKAGE") {
  //     formRenderProps.onChange("TourCode", {
  //       value: TourBookingDataListDetails?.TourCode,
  //     });
  //   } else {
  //     formRenderProps.onChange("TourCode", {
  //       value: "",
  //     });
  //   }
  // }, [TourBookingDataListDetails]);

  return null;
};

const filterDates = (Month: number, TourBookingDataListDetails: any) => {
  if (Month) {
    const filteredMonth = TourBookingDataListDetails?.MonthData?.filter(
      (room: any) => +room?.Month === +Month
    );
    return filteredMonth[0]?.TourdateData || [];
  }
};

const TourDateChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const tourDate = formRenderProps.valueGetter("TourDate");
  const TourMonth = formRenderProps.valueGetter("TourMonth");
  const NoOfNights = formRenderProps.valueGetter("NoOfNights");
  const TravelType = formRenderProps.valueGetter("TravelType");
  const TourStartDate = formRenderProps.valueGetter("TourStartDate");

  const TourBookingDataListDetails = useAppSelector(
    (state) => state.tourBooking.TourBookingDataListDetails
  );

  const TourDateCodeDetails = useAppSelector(
    (state) => state.tourBooking.TourDateCodeDetails
  );

  useEffect(() => {
    const TourDate = filterDates(TourMonth, TourBookingDataListDetails)?.filter(
      (e: any) => e.TourDate === tourDate
    )[0]?.TourDate;
    if (TravelType === "TOUR") {
      const formData = {
        TourID: formRenderProps.valueGetter("TourID")?.toString(),
        TourDate: TourDate || "",
      };
      dispatch(getCheckAirlineSeats(formData));
      formRenderProps.onChange("TourStartDate", {
        value: TourDate ? moment(TourDate).toDate() : "",
      });
    }
    if (NoOfNights && TourDate && TravelType === "TOUR") {
      const newDate =
        moment(TourDate)
          ?.add(+NoOfNights || 0, "days")
          ?.toDate() || "";
      formRenderProps.onChange("TourEndDate", {
        value: newDate,
      });
    }
  }, [tourDate, TourBookingDataListDetails]);

  useEffect(() => {
    if (TourDateCodeDetails?.TourDateCodeList && TravelType === "TOUR") {
      formRenderProps.onChange("TourCode", {
        value: TourDateCodeDetails?.TourDateCodeList[0]?.TourCode || "",
      });
    } else if (TravelType === "PACKAGE") {
      formRenderProps.onChange("TourCode", {
        value: TourBookingDataListDetails?.TourCode || "",
      });
    } else {
      formRenderProps.onChange("TourCode", {
        value: "",
      });
    }
  }, [TourDateCodeDetails?.TourDateCodeList, TourBookingDataListDetails]);

  useEffect(() => {
    if (TourStartDate) {
      const newDate =
        moment(TourStartDate)
          ?.add(+NoOfNights || 0, "days")
          ?.toDate() || "";
      formRenderProps.onChange("TourEndDate", {
        value: newDate,
      });
    }
  }, [TourStartDate, NoOfNights]);

  return null;
};

const PaxChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const TourBookingCostDetails = useAppSelector(
    (state) => state.tourBooking.TourBookingCostDetails
  );
  const PaxTypesList = useAppSelector((state) => state.paxType.PaxTypeList);
  const TourDateCodeDetails = useAppSelector(
    (state) => state.tourBooking.TourDateCodeDetails
  );

  const TravelType = formRenderProps.valueGetter("TravelType");
  const TourMonth = formRenderProps.valueGetter("TourMonth");
  const TourID = formRenderProps.valueGetter("TourID");
  const TourCategoryID = formRenderProps.valueGetter("TourCategoryID");
  const RateNoOfNights = formRenderProps.valueGetter("NoOfNights");
  const tourDate = formRenderProps.valueGetter("TourDate");
  const PaxTypeList = formRenderProps.valueGetter("PaxTypeList");
  const TourBookingDataListDetails = useAppSelector(
    (state) => state.tourBooking.TourBookingDataListDetails
  );
  const TourDate = filterDates(TourMonth, TourBookingDataListDetails)?.filter(
    (e: any) => e.TourDate === tourDate
  )[0]?.TourDate;

  useEffect(() => {
    if (
      TourID &&
      TourCategoryID &&
      RateNoOfNights &&
      PaxTypeList?.length > 0 &&
      TravelType === "TOUR"
    ) {
      const payload = {
        TourID: TourID,
        TourCategoryID: TourCategoryID,
        RateNoOfNights: +RateNoOfNights,
        TourDate: TourDate,
        PaxTypeList: PaxTypeList.map((e: any) => ({
          PaxTypeID: e?.PaxTypeID || 0,
          NoOfRoom: +e?.NoOfRoom || 0,
        })),
      };
      dispatch(getTourCost(payload));
    }
  }, [
    PaxTypeList.map((item: any) => item.NoOfRoom).join("-"),
    PaxTypeList.map((item: any) => item.PaxTypeID).join("-"),
    TourID,
    TourCategoryID,
    RateNoOfNights,
    tourDate,
    TourBookingDataListDetails,
    TravelType,
  ]);

  useEffect(() => {
    formRenderProps.onChange(`TotalAmount`, {
      value: TourBookingCostDetails.TotalAmount,
    });
  }, [TourBookingCostDetails]);

  useEffect(() => {
    const totalNoOfRooms = PaxTypeList?.reduce(
      (accumulator: any, currentItem: any) => {
        const currentNoofRooms = +currentItem.NoOfRoom || 0;
        const currentPax: any = PaxTypesList?.filter(
          (e: any) => e.ID === currentItem?.PaxTypeID
        );
        const currentPaxCount =
          (currentPax &&
            currentPax?.length > 0 &&
            currentPax[0]?.Adult +
              currentPax[0]?.ExtraAdult +
              currentPax[0]?.CWB +
              currentPax[0]?.CNB) ||
          0;
        const currentSeats = currentPaxCount * currentNoofRooms;
        return accumulator + currentSeats;
      },
      0
    );
    if (totalNoOfRooms > TourDateCodeDetails?.AvailableSeat || 0) {
      // const diffrence =
      //   totalNoOfRooms - TourDateCodeDetails?.AvailableSeat || 0;
      ErrorToast(
        `You can enter only ${TourDateCodeDetails?.AvailableSeat || 0} Seats`
      );
      // formRenderProps.onChange(
      //   `PaxTypeList.${PaxTypeList?.length - 1}.NoOfRooms`,
      //   {
      //     value: diffrence,
      //   }
      // );
    }
  }, [
    PaxTypeList.map((item: any) => item.NoOfRoom).join("-"),
    PaxTypeList.map((item: any) => item.PaxTypeID).join("-"),
  ]);

  return null;
};

const SightSeenChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const SightseenDetails = formRenderProps.valueGetter("SightseenDetails");

  const totalExtraCost = SightseenDetails?.reduce(
    (accumulator: any, currentItem: any) => {
      const extraCost = (currentItem.Rate || 0) * (currentItem.Pax || 0);
      return accumulator + extraCost;
    },
    0
  );

  useEffect(() => {
    if (SightseenDetails?.length > 0) {
      formRenderProps.onChange(`TotalExtraCost`, {
        value: totalExtraCost,
      });
    }
  }, [
    SightseenDetails?.map((item: any) => item.SightseenID).join("-"),
    SightseenDetails?.map((item: any) => item.Pax).join("-"),
  ]);

  return null;
};

interface CityChangeWatcherProps {
  formRenderProps: FormRenderProps;
  CityList: ICity[];
}

const CityChangeWatcher: React.FC<CityChangeWatcherProps> = ({
  CityList,
  formRenderProps,
}) => {
  const cityID = formRenderProps.valueGetter("CityID");

  useEffect(() => {
    const CityID = cityID ? cityID : undefined;
    const cityIndex = findIndex(CityList, { ID: CityID });
    if (cityIndex > -1) {
      const city = CityList[cityIndex];
      formRenderProps.onChange("StateID", { value: city.StateID });
      formRenderProps.onChange("CountryID", { value: city.CountryID });
    } else {
      formRenderProps.onChange("CityID", { value: "" });
      formRenderProps.onChange("StateID", { value: "" });
      formRenderProps.onChange("CountryID", { value: "" });
    }
  }, [cityID]);
  return null;
};
const TotalAmountChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const location = useLocation();
  const TourBookingID = location.state?.TourBookingID;
  const TotalAmount = formRenderProps.valueGetter("TotalAmount");
  const TotalExtraCost = formRenderProps.valueGetter("TotalExtraCost") || 0;
  const DiscountType = formRenderProps.valueGetter("DiscountType") || 0;
  const DiscountValue = formRenderProps.valueGetter("DiscountValue") || 0;
  const DiscountAmount = formRenderProps.valueGetter("DiscountAmount") || 0;
  const AdminDiscountAmount =
    formRenderProps.valueGetter("AdminDiscountAmount") || 0;
  const TotalDiscountAmount =
    formRenderProps.valueGetter("TotalDiscountAmount") || 0;
  const TotalGSTAmount = formRenderProps.valueGetter("TotalGSTAmount") || 0;
  const TotalTCSAmount = formRenderProps.valueGetter("TotalTCSAmount") || 0;
  const Kasar = formRenderProps.valueGetter("Kasar") || 0;
  const IsGST = formRenderProps.valueGetter("IsGST");
  const IsTCS = formRenderProps.valueGetter("IsTCS");

  const TourBookingDataListDetails = useAppSelector(
    (state) => state.tourBooking.TourBookingDataListDetails
  );

  const TourBookingStep1Detail = useAppSelector(
    (state) => state.tourBooking.TourBookingStep1Detail
  );

  const GSTPer = TourBookingDataListDetails?.GSTPer;
  const TCSPer = TourBookingDataListDetails?.TCSPer;
  const IsApplyOnServiceTax = TourBookingDataListDetails?.IsApplyOnServiceTax;

  useEffect(() => {
    if (GSTPer > 0 && !TourBookingID) {
      formRenderProps.onChange("IsGST", {
        value: true,
      });
    }
  }, [GSTPer]);

  useEffect(() => {
    if (DiscountType === "2" || DiscountType === 2) {
      formRenderProps.onChange("DiscountAmount", {
        value: ((+TotalAmount + +TotalExtraCost) * DiscountValue) / 100,
      });
    } else {
      formRenderProps.onChange("DiscountAmount", { value: +DiscountValue });
    }
  }, [TotalAmount, TotalExtraCost, DiscountValue]);

  useEffect(() => {
    formRenderProps.onChange("TotalDiscountAmount", {
      value: +DiscountAmount + +AdminDiscountAmount,
    });
  }, [DiscountAmount, AdminDiscountAmount]);

  useEffect(() => {
    if (TourBookingStep1Detail) {
      formRenderProps.onChange("TotalAmount", {
        value: TourBookingStep1Detail?.TotalAmount,
      });
    }
  }, [TourBookingStep1Detail]);

  useEffect(() => {
    formRenderProps.onChange("TotalCost", {
      value:
        +TotalAmount +
        +TotalExtraCost +
        +TotalGSTAmount +
        +TotalTCSAmount -
        +TotalDiscountAmount -
        +Kasar,
    });
  }, [
    TotalAmount,
    TotalExtraCost,
    TotalDiscountAmount,
    TotalGSTAmount,
    TotalTCSAmount,
    Kasar,
    TourBookingStep1Detail,
  ]);

  useEffect(() => {
    if (GSTPer > 0) {
      if (IsGST) {
        formRenderProps.onChange("TotalGSTAmount", {
          value:
            ((+TotalAmount + +TotalExtraCost - +TotalDiscountAmount) *
              +GSTPer) /
            100,
        });
      } else {
        formRenderProps.onChange("TotalGSTAmount", {
          value: 0,
        });
      }
    } else {
      formRenderProps.onChange("TotalGSTAmount", {
        value: ((+TotalAmount + +TotalExtraCost) * +GSTPer) / 100,
      });
    }
  }, [
    TotalAmount,
    TotalExtraCost,
    TotalDiscountAmount,
    GSTPer,
    IsGST,
    IsApplyOnServiceTax,
  ]);

  useEffect(() => {
    if (TCSPer > 0 && !TourBookingID) {
      formRenderProps.onChange("IsTCS", {
        value: true,
      });
    }
  }, [TCSPer]);

  useEffect(() => {
    const totalAmount =
      +TotalAmount + +TotalExtraCost + +TotalGSTAmount - +TotalDiscountAmount;
    // if (TourBookingDataListDetails?.SectorType === "INTERNATIONAL") {
    if (IsTCS) {
      if (totalAmount <= 700000) {
        formRenderProps.onChange("TotalTCSAmount", {
          value: (+totalAmount * TCSPer) / 100,
        });
      } else {
        const firstTCSAmount = (700000 * TCSPer) / 100;
        const remainingTCSAmount = +totalAmount - 700000;
        const secondTCSAmount = (+remainingTCSAmount * 20) / 100;

        formRenderProps.onChange("TotalTCSAmount", {
          value: +firstTCSAmount + +secondTCSAmount,
        });
      }
    } else {
      formRenderProps.onChange("TotalTCSAmount", {
        value: 0,
      });
    }
    // } else {
    //   formRenderProps.onChange("TotalTCSAmount", {
    //     value: 0,
    //   });
    // }
  }, [
    TotalAmount,
    TotalExtraCost,
    TotalDiscountAmount,
    TotalGSTAmount,
    IsTCS,
    TCSPer,
    TourBookingDataListDetails?.SectorType,
  ]);

  return null;
};

const TourBookingDetails: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const TourBookingID = location.state?.TourBookingID;
  const loading = useAppSelector((state) => state.tourBooking.loading);
  const SectorList = useAppSelector((state) => state.sector.SectorList);
  const TourList = useAppSelector((state) => state.tourBooking.TourList);
  const CompanyList = useAppSelector((state) => state.company.CompanyList);
  const CityList = useAppSelector((state) => state.city.CityList);
  const StateList = useAppSelector((state) => state.state.StateList);
  const CountryList = useAppSelector((state) => state.country.CountryList);
  const CurrencyList = useAppSelector((state) => state.currency.CurrencyList);
  const FoodOptionList = useAppSelector(
    (state) => state.foodOption.FoodOptionList
  );
  const TourBookingStep1Detail = useAppSelector(
    (state) => state.tourBooking.TourBookingStep1Detail
  );
  const TourBookingCostDetails = useAppSelector(
    (state) => state.tourBooking.TourBookingCostDetails
  );

  const TourBookingDataListDetails = useAppSelector(
    (state) => state.tourBooking.TourBookingDataListDetails
  );
  const TourDateCodeDetails = useAppSelector(
    (state) => state.tourBooking.TourDateCodeDetails
  );

  const [formKey, setFormKey] = useState(1);

  useEffect(() => {
    dispatch(getAllActiveSector());
    dispatch(getAllActiveCompanies());
    dispatch(getAllActiveCities());
    dispatch(getAllActiveStates());
    dispatch(getAllActiveCountries());
    dispatch(getAllActiveFoodOptions());
    dispatch(getAllActiveCurrencies());

    return () => {
      dispatch(clearTourBookingCostDetails());
      dispatch(clearTourBookingStep1Details());
      dispatch(clearAvailabelSeats());
    };
  }, []);

  useEffect(() => {
    if (TourBookingID) {
      dispatch(getByIDTourBookingStep1(TourBookingID));
    }
  }, [TourBookingID]);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [TourBookingStep1Detail]);

  const handleTourMonthChange = (e: any, formRenderProps: FormRenderProps) => {
    const NoofNights = TourBookingDataListDetails.NoOfNights;
    formRenderProps.onChange("NoOfNights", {
      value: NoofNights,
    });
  };

  // const handleTourStartDateChange = (
  //   e: any,
  //   formRenderProps: FormRenderProps
  // ) => {
  //   const NoofNights = formRenderProps.valueGetter("NoOfNights");
  //   if (NoofNights && e?.value) {
  //     const newDate =
  //       moment(e?.value)
  //         ?.add(+NoofNights || 0, "days")
  //         ?.toDate() || "";
  //     formRenderProps.onChange("TourEndDate", {
  //       value: newDate,
  //     });
  //   }
  // };

  // const handleNoofNightsChange = (e: any, formRenderProps: FormRenderProps) => {
  //   const TourStartDate = formRenderProps.valueGetter("TourStartDate");
  //   const NoofNights = e?.value;
  //   if (TourStartDate && NoofNights) {
  //     const newDate =
  //       moment(TourStartDate)
  //         ?.add(+NoofNights || 0, "days")
  //         ?.toDate() || "";
  //     formRenderProps.onChange("TourEndDate", {
  //       value: newDate,
  //     });
  //   }
  // };

  const handleSubmit = async (values: any) => {
    try {
      if (!TourBookingID) {
        const insertPayload = {
          SectorID: values.SectorID ? +values.SectorID : null,
          TourID: values.TourID ? values.TourID : "",
          TourCategoryID: values.TourCategoryID ? +values.TourCategoryID : null,
          CompanyID: values.CompanyID ? +values.CompanyID : null,
          CurrencyID: values.CurrencyID ? +values.CurrencyID : null,
          CurrencyRate: values.CurrencyRate ? +values.CurrencyRate : null,
          TravelType: values.TravelType ? values.TravelType : "",
          Remarks: values.Remarks ? values.Remarks : "",
          TourMonth: values.TourMonth ? values.TourMonth.toString() : "",
          TourDate: values.TourDate ? values.TourDate : null,
          TourStartDate: values.TourStartDate
            ? moment(values.TourStartDate).format("YYYY-MM-DD")
            : "",
          NoOfNights: values.NoOfNights ? +values.NoOfNights : null,
          TotalNoOfRooms: values.TotalNoOfRooms ? +values.TotalNoOfRooms : null,
          TourEndDate: values.TourEndDate
            ? moment(values.TourEndDate).format("YYYY-MM-DD")
            : "",
          FirstName: values.FirstName ? values.FirstName : "",
          LastName: values.LastName ? values.LastName : "",
          MobileNo: values.MobileNo ? values.MobileNo : "",
          EmailID: values.EmailID ? values.EmailID : "",
          OTP: values.OTP ? values.OTP : "",
          Address: values.Address ? values.Address : "",
          CityID: values.CityID ? +values.CityID : null,
          StateID: values.StateID ? +values.StateID : null,
          CountryID: values.CountryID ? +values.CountryID : null,
          EmergencyNo: values.EmergencyNo ? values.EmergencyNo : "",
          IsCompanyInvoice: values.IsCompanyInvoice === 1,
          CompanyName: values.CompanyName ? values?.CompanyName : "",
          CompanyAddress: values.CompanyAddress ? values?.CompanyAddress : "",
          CompanyCity: values.CompanyCity ? values?.CompanyCity : null,
          CompanyStateID: values.CompanyStateID ? values?.CompanyStateID : null,
          CompanyCountryID: values.CompanyCountryID
            ? values?.CompanyCountryID
            : null,
          CompanyGSTNo: values.CompanyGSTNo ? values?.CompanyGSTNo : "",
          CompanyPANNo: values.CompanyPANNo ? values?.CompanyPANNo : "",
          IsGST: values.IsGST ? values.IsGST : false,
          IsTCS: values.IsTCS ? values.IsTCS : false,
          TotalAmount: values.TotalAmount ? +values.TotalAmount : null,
          TotalCost: values.TotalCost ? +values.TotalCost : null,
          DiscountType: values.DiscountType ? +values.DiscountType : null,
          DiscountValue: values.DiscountValue ? +values.DiscountValue : null,
          DiscountAmount: values.DiscountAmount ? +values.DiscountAmount : null,
          AdminDiscountAmount: values.AdminDiscountAmount
            ? +values.AdminDiscountAmount
            : null,
          TotalDiscountAmount: values.TotalDiscountAmount
            ? +values.TotalDiscountAmount
            : null,
          TotalTCSAmount: values.TotalTCSAmount ? +values.TotalTCSAmount : null,
          TotalGSTAmount: values.TotalGSTAmount ? +values.TotalGSTAmount : null,
          TotalExtraCost: values.TotalExtraCost ? +values.TotalExtraCost : null,
          Kasar: values.Kasar ? +values.Kasar : null,
          TourCode: values.TourCode ? values.TourCode : "",
          FoodOptionID: values.FoodOptionID ? +values.FoodOptionID : null,
          TourBookingDate: values.TourBookingDate
            ? moment(values.TourBookingDate).format("YYYY-MM-DD")
            : "",
          PickUpDate: values?.PickUpDate
            ? moment(values?.PickUpDate, "YYYY-MM-DD")?.format("YYYY-MM-DD")
            : "",
          PickUpPlace: values?.PickUpPlace ? values?.PickUpPlace : "",
          PickUpTime: values?.PickUpTime
            ? moment(values?.PickUpTime, "HH:mm:ss")?.format("HH:mm:ss")
            : "",
          PickUpFlightNo: values?.PickUpFlightNo ? values?.PickUpFlightNo : "",
          DropDate: values?.DropDate
            ? moment(values?.DropDate, "YYYY-MM-DD")?.format("YYYY-MM-DD")
            : "",
          DropPlace: values?.DropPlace ? values?.DropPlace : "",
          DropTime: values?.DropTime
            ? moment(values?.DropTime, "HH:mm:ss")?.format("HH:mm:ss")
            : "",
          DropFlightNo: values?.DropFlightNo ? values?.DropFlightNo : "",

          PaxTypeList: values.PaxTypeList
            ? values.PaxTypeList.map((item: any) => {
                return {
                  PaxTypeID: item.PaxTypeID ? +item.PaxTypeID : null,
                  NoOfRoom: item.NoOfRoom ? +item.NoOfRoom : null,
                };
              })
            : [],
          SightseenDetails: values.SightseenDetails
            ? values.SightseenDetails.map((item: any) => {
                return {
                  SightseenID: item.SightseenID ? +item.SightseenID : null,
                  Rate: item.Rate ? +item.Rate : null,
                  Pax: item.Pax ? +item.Pax : null,
                };
              })
            : [],
        };
        const response = await dispatch(createTourBooking(insertPayload));
        if (
          response?.meta?.requestStatus === "fulfilled" &&
          response.payload.Data?.ID
        ) {
          navigate("/tourbooking/edit", {
            state: {
              TourBookingID: response.payload.Data?.ID,
            },
          });
          dispatch(increaseTourBookingActiveStep());
        }
      } else {
        const editPayload = {
          ID: TourBookingID,
          SectorID: values.SectorID ? +values.SectorID : null,
          TourID: values.TourID ? values.TourID : "",
          TourCategoryID: values.TourCategoryID ? +values.TourCategoryID : null,
          CompanyID: values.CompanyID ? +values.CompanyID : null,
          CurrencyID: values.CurrencyID ? +values.CurrencyID : null,
          CurrencyRate: values.CurrencyRate ? +values.CurrencyRate : null,
          TravelType: values.TravelType ? values.TravelType : "",
          Remarks: values.Remarks ? values.Remarks : "",
          TourMonth: values.TourMonth ? values.TourMonth.toString() : "",
          TourDate: values.TourDate ? values.TourDate : null,
          TourStartDate: values.TourStartDate
            ? moment(values.TourStartDate).format("YYYY-MM-DD")
            : "",
          NoOfNights: values.NoOfNights ? +values.NoOfNights : null,
          TotalNoOfRooms: values.TotalNoOfRooms ? +values.TotalNoOfRooms : null,
          TourEndDate: values.TourEndDate
            ? moment(values.TourEndDate).format("YYYY-MM-DD")
            : "",
          FirstName: values.FirstName ? values.FirstName : "",
          LastName: values.LastName ? values.LastName : "",
          MobileNo: values.MobileNo ? values.MobileNo : "",
          EmailID: values.EmailID ? values.EmailID : "",
          OTP: values.OTP ? values.OTP : "",
          Address: values.Address ? values.Address : "",
          CityID: values.CityID ? +values.CityID : null,
          StateID: values.StateID ? +values.StateID : null,
          CountryID: values.CountryID ? +values.CountryID : null,
          EmergencyNo: values.EmergencyNo ? values.EmergencyNo : "",
          IsCompanyInvoice: values.IsCompanyInvoice === 1,
          CompanyName: values.CompanyName ? values?.CompanyName : "",
          CompanyAddress: values.CompanyAddress ? values?.CompanyAddress : "",
          CompanyCity: values.CompanyCity ? values?.CompanyCity : null,
          CompanyStateID: values.CompanyStateID ? values?.CompanyStateID : null,
          CompanyCountryID: values.CompanyCountryID
            ? values?.CompanyCountryID
            : null,
          CompanyGSTNo: values.CompanyGSTNo ? values?.CompanyGSTNo : "",
          CompanyPANNo: values.CompanyPANNo ? values?.CompanyPANNo : "",
          IsGST: values.IsGST ? values.IsGST : false,
          IsTCS: values.IsTCS ? values.IsTCS : false,
          TotalAmount: values.TotalAmount ? +values.TotalAmount : null,
          TotalCost: values.TotalCost ? +values.TotalCost : null,
          DiscountType: values.DiscountType ? +values.DiscountType : null,
          DiscountValue: values.DiscountValue ? +values.DiscountValue : null,
          DiscountAmount: values.DiscountAmount ? +values.DiscountAmount : null,
          AdminDiscountAmount: values.AdminDiscountAmount
            ? +values.AdminDiscountAmount
            : null,
          TotalDiscountAmount: values.TotalDiscountAmount
            ? +values.TotalDiscountAmount
            : null,
          TotalTCSAmount: values.TotalTCSAmount ? +values.TotalTCSAmount : null,
          TotalGSTAmount: values.TotalGSTAmount ? +values.TotalGSTAmount : null,
          TotalExtraCost: values.TotalExtraCost ? +values.TotalExtraCost : null,
          Kasar: values.Kasar ? +values.Kasar : null,
          TourCode: values.TourCode ? values.TourCode : "",
          FoodOptionID: values.FoodOptionID ? +values.FoodOptionID : null,
          TourBookingDate: values.TourBookingDate
            ? moment(values.TourBookingDate).format("YYYY-MM-DD")
            : "",
          PickUpDate: values?.PickUpDate
            ? moment(values?.PickUpDate, "YYYY-MM-DD")?.format("YYYY-MM-DD")
            : "",
          PickUpPlace: values?.PickUpPlace ? values?.PickUpPlace : "",
          PickUpTime: values?.PickUpTime
            ? moment(values?.PickUpTime, "HH:mm:ss")?.format("HH:mm:ss")
            : "",
          PickUpFlightNo: values?.PickUpFlightNo ? values?.PickUpFlightNo : "",
          DropDate: values?.DropDate
            ? moment(values?.DropDate, "YYYY-MM-DD")?.format("YYYY-MM-DD")
            : "",
          DropPlace: values?.DropPlace ? values?.DropPlace : "",
          DropTime: values?.DropTime
            ? moment(values?.DropTime, "HH:mm:ss")?.format("HH:mm:ss")
            : "",
          DropFlightNo: values?.DropFlightNo ? values?.DropFlightNo : "",
          PaxTypeList: values.PaxTypeList
            ? values.PaxTypeList.map((item: any) => {
                return {
                  PaxTypeID: item.PaxTypeID ? +item.PaxTypeID : null,
                  NoOfRoom: item.NoOfRoom ? +item.NoOfRoom : null,
                };
              })
            : [],
          SightseenDetails: values.SightseenDetails
            ? values.SightseenDetails.map((item: any) => {
                return {
                  SightseenID: item.SightseenID ? +item.SightseenID : null,
                  Rate: item.Rate ? +item.Rate : null,
                  Pax: item.Pax ? +item.Pax : null,
                };
              })
            : [],
        };
        const response = await dispatch(updateTourBooking(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(increaseTourBookingActiveStep());
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  return (
    <Form
      onSubmit={handleSubmit}
      key={formKey}
      initialValues={TourBookingStep1Detail}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement>
          <ShadowCard style={{ padding: 10 }}>
            <GridLayout
              style={{ marginRight: 20 }}
              gap={{ rows: 0, cols: 10 }}
              cols={[
                { width: "33.33%" },
                { width: "33.33%" },
                { width: "33.33%" },
              ]}
            >
              <GridLayoutItem colSpan={3}>
                <Typography.h4>{"Tour Booking Details"}</Typography.h4>
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name={"TourBookingDate"}
                  label={"Tour Booking Date"}
                  format="dd/MM/yyyy"
                  component={FormDatePicker}
                  validator={requiredValidator}
                  astrike={true}

                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="TravelType"
                  label="Travel Type"
                  component={FormSelectionField}
                  options={TRAVELTYPE?.map((traveltype: any) => {
                    return {
                      value: traveltype?.value,
                      label: traveltype?.label,
                    };
                  })}
                  astrike={true}

                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  id={"SectorID"}
                  name={"SectorID"}
                  label={"Sector"}
                  isAddNew={true}
                  addNewLink="sector/create"
                  dispatcherFunction={getAllActiveSector}
                  component={FormSelectionField}
                  options={SectorList?.map((sector: ISector) => {
                    return {
                      value: sector?.ID,
                      label: sector?.SectorName,
                    };
                  })}
                  astrike={true}

                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  id={"TourID"}
                  name={"TourID"}
                  label={"Tour"}
                  isAddNew={true}
                  addNewLink="tour/create"
                  dispatcherFunction={getAllActiveTours}
                  component={FormSelectionField}
                  options={TourList?.map((tour: ITourList) => {
                    return {
                      value: tour?.TourID,
                      label: tour?.TourName,
                    };
                  })}
                  astrike={true}

                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  id={"CompanyID"}
                  name={"CompanyID"}
                  label={"Company"}
                  component={FormSelectionField}
                  options={CompanyList?.map((company: ICompany) => {
                    return {
                      value: company?.ID,
                      label: company?.CompanyName,
                    };
                  })}
                  astrike={true}

                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <TourChangeWatcher formRenderProps={formRenderProps} />
            </GridLayout>
          </ShadowCard>
          <ShadowCard style={{ padding: 10, marginTop: 10 }}>
            <GridLayout
              style={{ marginRight: 20 }}
              gap={{ rows: 0, cols: 10 }}
              cols={[
                { width: "33.33%" },
                { width: "33.33%" },
                { width: "33.33%" },
              ]}
            >
              <GridLayoutItem
                style={{ display: "flex", justifyContent: "space-between" }}
                colSpan={3}
              >
                <Typography.h4>{"Tour Date Section"}</Typography.h4>
                {TourDateCodeDetails?.AvailableSeat > 0 && (
                  <div
                    style={{
                      backgroundColor: "red",
                      display: "flex",
                      alignItems: "center",
                      padding: 8,
                      borderRadius: 5,
                      height: 30,
                      color: "white",
                    }}
                  >
                    <Typography.h5 style={{ marginBottom: 0 }}>
                      {TourDateCodeDetails?.AvailableSeat}
                      {" Seats Available"}
                    </Typography.h5>
                  </div>
                )}
              </GridLayoutItem>
              {formRenderProps.valueGetter("TravelType") === "TOUR" && (
                <>
                  <GridLayoutItem>
                    <Field
                      name="TourMonth"
                      label="Tour Month"
                      component={FormSelectionField}
                      onChange={(e) =>
                        handleTourMonthChange(e, formRenderProps)
                      }
                      options={TourBookingDataListDetails?.MonthData?.map(
                        (data: any) => {
                          return {
                            value: data?.Month,
                            label: data?.MonthName,
                          };
                        }
                      )}
                      validator={requiredValidator}
                      astrike={true}

                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="TourDate"
                      label="Tour Date"
                      component={FormSelectionField}
                      options={filterDates(
                        formRenderProps.valueGetter("TourMonth"),
                        TourBookingDataListDetails
                      )?.map((e: any) => ({
                        label: moment(e.TourDate, "YYYY-MM-DD").format(
                          "DD/MM/YYYY"
                        ),
                        value: e.TourDate,
                      }))}
                      validator={requiredValidator}
                      astrike={true}

                    />
                  </GridLayoutItem>
                </>
              )}
              <TourDateChangeWatcher formRenderProps={formRenderProps} />
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name={"TourCode"}
                  label={"Tour Code"}
                  component={FormTextField}
                  disabled
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name={"TourStartDate"}
                  label={"Tour Start Date"}
                  format="dd/MM/yyyy"
                  component={FormDatePicker}
                  astrike={true}

                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name={"NoOfNights"}
                  label={"No Of Nights"}
                  validator={requiredValidator}
                  astrike={true}

                  component={FormSelectionField}
                  options={TourBookingDataListDetails?.NightData?.map(
                    (night: any) => ({
                      label: `${night.NoOfNights}`,
                      value: night.NoOfNights,
                    })
                  )}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name={"TourEndDate"}
                  label={"Tour End Date"}
                  format="dd/MM/yyyy"
                  component={FormDatePicker}
                  astrike={true}

                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name={"TourCategoryID"}
                  label={"Tour Type"}
                  component={FormSelectionField}
                  options={TourBookingDataListDetails?.TourCategoryData?.map(
                    (tourcategory: any) => {
                      return {
                        value: tourcategory?.TourCategoryID,
                        label: tourcategory?.TourCategory,
                      };
                    }
                  )}
                  astrike={true}

                  validator={requiredValidator}
                />
              </GridLayoutItem>
              {/* <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name={"TotalNoOfRooms"}
                  label={"No Of Rooms"}
                  component={FormNumericTextField}
                />
              </GridLayoutItem> */}
            </GridLayout>
          </ShadowCard>
          <ShadowCard style={{ padding: 10, marginTop: 10 }}>
            <GridLayout
              style={{ marginRight: 20 }}
              gap={{ rows: 0, cols: 10 }}
              cols={[
                { width: "33.33%" },
                { width: "33.33%" },
                { width: "33.33%" },
              ]}
            >
              <GridLayoutItem colSpan={3}>
                <Typography.h4>{"Tourist Information"}</Typography.h4>
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="FirstName"
                  label="First Name"
                  component={FormTextField}
                  validator={requiredValidator}
                  astrike={true}

                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="LastName"
                  label="Last Name"
                  component={FormTextField}
                  validator={requiredValidator}
                  astrike={true}

                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="MobileNo"
                  label="Mobile No / Whatsapp No"
                  type="number"
                  max="10"
                  component={FormTextField}
                  validator={requiredValidator}
                  astrike={true}

                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="EmailID"
                  label="Email ID"
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="EmergencyNo"
                  label="Emergency No"
                  type="number"
                  max="10"
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  id={"FoodOptionID"}
                  name={"FoodOptionID"}
                  label={"Food Preferences"}
                  component={FormSelectionField}
                  options={FoodOptionList?.map((foodoption: IFoodOption) => {
                    return {
                      value: foodoption?.ID,
                      label: foodoption?.Name,
                    };
                  })}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  id={"CityID"}
                  name={"CityID"}
                  label={"City"}
                  isAddNew={true}
                  drawerName={"cityForm"}
                  isIndirect={true}
                  component={FormSelectionField}
                  options={CityList?.map((city: ICity) => {
                    return {
                      value: city?.ID,
                      label: city?.CityName,
                    };
                  })}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  id={"StateID"}
                  name={"StateID"}
                  label={"State"}
                  isAddNew={true}
                  drawerName={"stateForm"}
                  isIndirect={true}
                  component={FormSelectionField}
                  options={StateList?.map((state: IState) => {
                    return {
                      value: state?.ID,
                      label: state?.StateName,
                    };
                  })}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  id={"CountryID"}
                  name={"CountryID"}
                  label={"Country"}
                  isAddNew={true}
                  drawerName={"countryForm"}
                  isIndirect={true}
                  component={FormSelectionField}
                  options={CountryList?.map((country: ICountry) => {
                    return {
                      value: country?.ID,
                      label: country?.CountryName,
                    };
                  })}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="Address"
                  label="Address"
                  component={FormTextArea}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="Remarks"
                  label="Remarks"
                  component={FormTextArea}
                />
              </GridLayoutItem>
            </GridLayout>
          </ShadowCard>
          <ShadowCard style={{ padding: 10, marginTop: 10 }}>
            <GridLayout
              style={{ marginRight: 20 }}
              gap={{ rows: 0, cols: 10 }}
              cols={[
                { width: "33.33%" },
                { width: "33.33%" },
                { width: "33.33%" },
              ]}
            >
              <GridLayoutItem colSpan={3}>
                <Typography.h4>{"Company Details"}</Typography.h4>
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="IsCompanyInvoice"
                  label="Company Invoice"
                  component={FormSelectionField}
                  options={BOOLEAN?.map((invoice: any) => {
                    return {
                      value: invoice?.value,
                      label: invoice?.label,
                    };
                  })}
                />
              </GridLayoutItem>
              <GridLayoutItem colSpan={2}></GridLayoutItem>
              {formRenderProps.valueGetter("IsCompanyInvoice") === 1 && (
                <>
                  <GridLayoutItem>
                    <Field
                      name="CompanyName"
                      label="Company Name"
                      component={FormTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="CompanyAddress"
                      label="Company Address"
                      component={FormTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="CompanyGSTNo"
                      label="GST No"
                      component={FormTextField}
                      // max={15}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="CompanyPANNo"
                      label="PAN No"
                      component={FormTextField}
                      // max={10}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      id={"CompanyCity"}
                      name={"CompanyCity"}
                      label={"Company City"}
                      component={FormTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem style={{ display: "flex", gap: 10 }}>
                    <Field
                      id={"CompanyStateID"}
                      name={"CompanyStateID"}
                      label={"Company State"}
                      component={FormSelectionField}
                      options={StateList?.map((state: IState) => {
                        return {
                          value: state?.ID,
                          label: state?.StateName,
                        };
                      })}
                    />
                    <Field
                      id={"CompanyCountryID"}
                      name={"CompanyCountryID"}
                      label={"Company Country"}
                      component={FormSelectionField}
                      options={CountryList?.map((country: ICountry) => {
                        return {
                          value: country?.ID,
                          label: country?.CountryName,
                        };
                      })}
                    />
                  </GridLayoutItem>
                </>
              )}
            </GridLayout>
            <CompanyCityChangeWatcher
              StateList={StateList}
              formRenderProps={formRenderProps}
            />
          </ShadowCard>
          <ShadowCard style={{ padding: 10, marginTop: 10 }}>
            <GridLayout
              style={{ marginRight: 20 }}
              gap={{ rows: 0, cols: 10 }}
              cols={[{ width: "50%" }, { width: "50%" }]}
            >
              <GridLayoutItem>
                <Typography.h4>{"Pax Details"}</Typography.h4>
              </GridLayoutItem>
              <GridLayoutItem>
                <GridLayout
                  gap={{ rows: 0, cols: 10 }}
                  cols={[
                    { width: "33.33%" },
                    { width: "33.33%" },
                    { width: "33.33%" },
                  ]}
                >
                  <GridLayoutItem colSpan={1}>
                    <Typography.h5 style={{ marginBottom: 0 }}>
                      {"PP Rate : "}
                      <span style={{ color: "green" }}>
                        {TourBookingCostDetails?.TwinSharing}
                      </span>
                    </Typography.h5>
                    <Typography.h5 style={{ marginBottom: 0 }}>
                      {"Extra Adult Rate : "}
                      <span style={{ color: "green" }}>
                        {TourBookingCostDetails?.ExtraAdultRate}
                      </span>
                    </Typography.h5>
                  </GridLayoutItem>
                  <GridLayoutItem colSpan={1}>
                    <Typography.h5 style={{ marginBottom: 0 }}>
                      {"CNB Rate : "}
                      <span style={{ color: "green" }}>
                        {TourBookingCostDetails?.CNBRate}
                      </span>
                    </Typography.h5>
                    <Typography.h5 style={{ marginBottom: 0 }}>
                      {"CWB Rate : "}
                      <span style={{ color: "green" }}>
                        {TourBookingCostDetails?.CWBRate}
                      </span>
                    </Typography.h5>
                  </GridLayoutItem>
                  <GridLayoutItem colSpan={1}>
                    <Typography.h5 style={{ marginBottom: 0 }}>
                      {"3 Sharing Rate : "}
                      <span style={{ color: "green" }}>
                        {TourBookingCostDetails?.ThreeSharing}
                      </span>
                    </Typography.h5>
                  </GridLayoutItem>
                </GridLayout>
              </GridLayoutItem>
              <GridLayoutItem style={{ marginTop: 10 }} colSpan={3}>
                <FieldArray
                  formRenderProps={formRenderProps}
                  component={PaxDetailsArray}
                  name="PaxTypeList"
                />
              </GridLayoutItem>
              <PaxChangeWatcher formRenderProps={formRenderProps} />
            </GridLayout>
          </ShadowCard>
          <ShadowCard style={{ padding: 10, marginTop: 10 }}>
            <GridLayout
              style={{ marginRight: 20 }}
              gap={{ rows: 0, cols: 10 }}
              cols={[{ width: "50%" }, { width: "50%" }]}
            >
              <GridLayoutItem>
                <Typography.h4>{"Sightseen Details"}</Typography.h4>
              </GridLayoutItem>
              <GridLayoutItem style={{ marginTop: 10 }} colSpan={3}>
                <FieldArray
                  formRenderProps={formRenderProps}
                  component={SightseenDetailsArray}
                  name="SightseenDetails"
                />
              </GridLayoutItem>
              <SightSeenChangeWatcher formRenderProps={formRenderProps} />
            </GridLayout>
          </ShadowCard>
          <ShadowCard style={{ padding: 10, marginTop: 10, marginBottom: 10 }}>
            <GridLayout
              style={{ marginRight: 30 }}
              gap={{ rows: 0, cols: 10 }}
              cols={[
                { width: "25%" },
                { width: "25%" },
                { width: "25%" },
                { width: "25%" },
              ]}
            >
              <GridLayoutItem>
                <Typography.h4>{"Amount Calculation"}</Typography.h4>
              </GridLayoutItem>
              <GridLayoutItem
                // colSpan={3}
                style={{
                  width: "100%",
                  fontWeight: 700,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Field
                  wrapperClassName="m-0"
                  name="IsGST"
                  label="Is GST Applicable"
                  maxLength="100"
                  component={FormCheckbox}
                  wrapperStyle={{ marginTop: 8 }}
                  // onChange={() => handleIsMenuCheck(formRenderProps)}
                />
                {/* </GridLayoutItem>
              <GridLayoutItem
                style={{
                  width: "100%",
                  fontWeight: 700,
                }}
              > */}
                <Field
                  name="IsTCS"
                  label="Is TCS Applicable"
                  maxLength="100"
                  component={FormCheckbox}
                  wrapperStyle={{ marginTop: 8 }}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  wrapperClassName="m-0"
                  name="CurrencyID"
                  label="Currency"
                  component={FormSelectionField}
                  validator={requiredValidator}
                  astrike={true}

                  options={CurrencyList?.map((currency: ICurrency) => {
                    return {
                      value: currency?.ID,
                      label: currency?.CurrencyName,
                    };
                  })}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  wrapperClassName="m-0"
                  name="CurrencyRate"
                  label="Currency Rate"
                  type="number"
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem
                colSpan={4}
                style={{
                  margin: "10px 0 20px",
                }}
              ></GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="TotalAmount"
                  label="Total Amount"
                  type="number"
                  disabled={
                    formRenderProps.valueGetter("TravelType") === "PACKAGE"
                      ? false
                      : true
                  }
                  component={FormTextField}
                  validator={requiredValidatorNumber0}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="TotalExtraCost"
                  label="Total Extra Cost"
                  type="number"
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  wrapperClassName="w-100"
                  name="DiscountType"
                  label="Discount Type"
                  type="number"
                  component={FormSelectionField}
                  options={DISCOUNTTYPE?.map((discounttype: any) => {
                    return {
                      value: discounttype?.value,
                      label: discounttype?.label,
                    };
                  })}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  wrapperClassName="w-100"
                  name="DiscountValue"
                  label="Discount Value"
                  type="number"
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem colSpan={4} style={{ display: "flex", gap: 10 }}>
                <Field
                  wrapperClassName="w-100 parent-calculation-label center-alighned-field"
                  name="DiscountAmount"
                  label="Discount"
                  type="number"
                  component={FormTextField}
                  disabled={true}
                />
                <Field
                  wrapperClassName="w-100"
                  name="AdminDiscountAmount"
                  label="Admin Discount"
                  type="number"
                  component={FormTextField}
                />
                <Field
                  wrapperClassName="w-100 parent-calculation-label center-alighned-field"
                  name="TotalDiscountAmount"
                  label="Total Discount"
                  type="number"
                  component={FormTextField}
                  disabled={true}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  wrapperClassName="parent-calculation-label center-alighned-field disabled-color-red"
                  name="TotalGSTAmount"
                  label="Total GST"
                  type="number"
                  component={FormTextField}
                  disabled={true}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  wrapperClassName="parent-calculation-label center-alighned-field disabled-color-red"
                  name="TotalTCSAmount"
                  label="Total TCS"
                  type="number"
                  component={FormTextField}
                  disabled={true}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="Kasar"
                  label="Kasar"
                  type="number"
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  wrapperClassName="parent-calculation-label center-alighned-field disabled-color-green"
                  name="TotalCost"
                  label="Total Cost"
                  type="number"
                  component={FormTextField}
                  disabled={true}
                />
              </GridLayoutItem>
              {/* <GridLayoutItem colSpan={3}></GridLayoutItem>
              <GridLayoutItem
                style={{
                  textAlign: "end",
                  marginTop: "20px",
                  width: "100%",
                }}
              >
                <RippleButton
                  fillMode="outline"
                  themeColor="primary"
                  style={{ marginRight: 4 }}
                  onClick={() => navigate("/tourbooking")}
                >
                  Cancel
                </RippleButton>
                <ButtonWithLoading
                  label={"Save"}
                  type="submit"
                  disabled={!formRenderProps.allowSubmit || loading}
                  loading={loading}
                />
              </GridLayoutItem> */}
              <TotalAmountChangeWatcher formRenderProps={formRenderProps} />
              {/* <TourDatechangeWatcher formRenderProps={formRenderProps} /> */}
            </GridLayout>
          </ShadowCard>

          <ShadowCard style={{ padding: 10, marginTop: 10 }}>
            <GridLayout
              style={{ marginRight: 30 }}
              gap={{ rows: 0, cols: 10 }}
              cols={[
                { width: "25%" },
                { width: "25%" },
                { width: "25%" },
                { width: "25%" },
              ]}
            >
              <GridLayoutItem colSpan={4}>
                <Typography.h4>{"Pickup & Drop Details"}</Typography.h4>
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name={"PickUpDate"}
                  label={"Pickup Date"}
                  format="dd/MM/yyyy"
                  component={FormDatePicker}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  id={"PickUpTime"}
                  name={"PickUpTime"}
                  label={"Pickup Time"}
                  component={FormTimePicker}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  id={"PickUpPlace"}
                  name={"PickUpPlace"}
                  label={"PickUp Place"}
                  component={FormTextField}
                  // component={FormSelectionField}
                  // options={CityList?.map((city: ICity) => {
                  //   return {
                  //     value: city?.CityName,
                  //     label: city?.CityName,
                  //   };
                  // })}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="PickUpFlightNo"
                  label="Pickup Flight No"
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name={"DropDate"}
                  label={"Drop Date"}
                  format="dd/MM/yyyy"
                  component={FormDatePicker}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  id={"DropTime"}
                  name={"DropTime"}
                  label={"Drop Time"}
                  component={FormTimePicker}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  id={"DropPlace"}
                  name={"DropPlace"}
                  label={"Drop Place"}
                  component={FormTextField}
                  // component={FormSelectionField}
                  // options={CityList?.map((city: ICity) => {
                  //   return {
                  //     value: city?.CityName,
                  //     label: city?.CityName,
                  //   };
                  // })}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="DropFlightNo"
                  label="Drop Flight No"
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem
                colSpan={4}
                style={{
                  textAlign: "end",
                  marginTop: "20px",
                  width: "100%",
                }}
              >
                <RippleButton
                  fillMode="outline"
                  themeColor="primary"
                  style={{ marginRight: 4 }}
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </RippleButton>
                <ButtonWithLoading
                  label={"Save"}
                  type="submit"
                  disabled={!formRenderProps.allowSubmit || loading}
                  loading={loading}
                />
              </GridLayoutItem>
            </GridLayout>
          </ShadowCard>
          <SectorChangeWatcher formRenderProps={formRenderProps} />
          <CityChangeWatcher
            CityList={CityList}
            formRenderProps={formRenderProps}
          />
        </FormElement>
      )}
    />
  );
};

export default TourBookingDetails;
