import React, { useEffect, useRef } from "react";
import {
  filterBy,
  CompositeFilterDescriptor,
} from "@progress/kendo-data-query";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { PagerTargetEvent } from "@progress/kendo-react-data-tools";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import { Typography } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { MdOutlineCancel } from "react-icons/md";
import { FaHotel, FaRoute } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { checkAcessRights } from "../../../_helper/helper";
import {
  DELETE_ACCESS,
  EDIT_ACCESS,
  INSERT_ACCESS,
} from "../../../_contstants/common";
import {
  closeDialog,
  openDialog,
} from "../../../components/dialog/dialogSlice";
import {
  clearTourBookingID,
  setTourBookingID,
} from "../../tourbooking/tourBookingSlice";
import IconButton from "../../../components/common/IconButton";
import { LoadingPanel } from "../../../components/layout/Loading";
import AlertBox from "../../../components/common/AlertBox";
import { deleteTourBooking } from "../../tourbooking/services/tourBooking.services";
import AppDialog, {
  DialogCloseButton,
} from "../../../components/dialog/Dialog";
import { getAllPendingDocumentTourBooking } from "../services/dashboard.services";

const PendingDocumentTourBooking: React.FC = () => {
  const dispatch = useAppDispatch();
  const dateFilter = useAppSelector((state) => state.dashboard.dateFilter);

  useEffect(() => {
    const payload = {
      FromDate: dateFilter?.FromDate ? dateFilter?.FromDate : "",
      ToDate: dateFilter?.ToDate ? dateFilter?.ToDate : "",
    };
    dispatch(getAllPendingDocumentTourBooking(payload));
  }, [dateFilter]);

  return (
    <>
      <DeleteTourBookingDialog />
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Pending Tour Booking List
          </Typography.h4>
        </GridLayoutItem>
        <GridLayoutItem>
          <TourBookingGridComponent />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 10 };

const TourBookingGridComponent: React.FC = () => {
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isEditAccess = checkAcessRights("/tourbooking", EDIT_ACCESS);
  const isDeleteAccess = checkAcessRights("/tourbooking", DELETE_ACCESS);
  const isHotelAddAccess = checkAcessRights("/hotelvoucher", INSERT_ACCESS);
  const isRouteAddAccess = checkAcessRights("/routevoucher", INSERT_ACCESS);

  const PendingDocumentTourBookingList = useAppSelector(
    (state) => state.dashboard.PendingDocumentTourBookingList
  );
  const loading = useAppSelector((state) => state.dashboard.loading);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All"
        ? PendingDocumentTourBookingList.length
        : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEdit = (item: any) => {
      navigate("/tourbooking/edit", {
        state: {
          TourBookingID: item?.ID,
          SectorID: item?.SectorID,
        },
      });
    };

    const handleCancelllation = (TourBookingID: number) => {
      navigate("/tourbooking/cancellation", {
        state: {
          TourBookingID: TourBookingID,
        },
      });
    };
    const handleOpenDeleteDialog = (ID: number) => {
      dispatch(openDialog("deleteTourBooking"));
      dispatch(setTourBookingID(ID));
    };

    return (
      <td
        className={`action-td ${props.className}`}
        style={{
          ...props.style,
        }}
      >
        {isEditAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Edit"
            onClick={() => handleEdit(props.dataItem)}
          >
            <FiEdit
              className="absolute-position"
              style={{ fontSize: "20px" }}
            />
          </IconButton>
        )}
        {isDeleteAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Delete"
            onClick={() => handleOpenDeleteDialog(props.dataItem.ID)}
          >
            <MdDelete
              className="absolute-position"
              style={{ fontSize: "24px" }}
            />
          </IconButton>
        )}
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Cancel"
          onClick={() => handleCancelllation(props.dataItem.ID)}
        >
          <MdOutlineCancel
            className="absolute-position"
            style={{ fontSize: "24px" }}
          />
        </IconButton>
      </td>
    );
  };

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };
  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className={`table-header ${props.index === 12 ? "locked-header" : ""}`}
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {PendingDocumentTourBookingList &&
      PendingDocumentTourBookingList.length ? (
        <KendoGrid
          style={{ maxHeight: "calc(100vh - 152px)" }}
          filterable={true}
          filter={filter}
          data={
            filter
              ? filterBy(PendingDocumentTourBookingList, filter).slice(
                  page.skip,
                  page.take + page.skip
                )
              : PendingDocumentTourBookingList.slice(
                  page.skip,
                  page.take + page.skip
                )
          }
          onFilterChange={handleFilterChange}
          skip={page.skip}
          take={page.take}
          total={PendingDocumentTourBookingList.length}
          pageable={{
            buttonCount: 5,
            pageSizes: [5, 10, 15, "All"],
            pageSizeValue: pageSizeValue,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column
            field="TourBookingNo"
            title="Booking No"
            width={150}
            cell={(props: any) => (
              <td>
                <p
                  className="hoverableName"
                  style={{ fontWeight: 600, cursor: "pointer", margin: 0 }}
                  onClick={() =>
                    window.open(
                      `/tourbookingdetails/${props.dataItem?.TourBookingNo}`
                    )
                  }
                >
                  {props.dataItem?.TourBookingNo}
                </p>
              </td>
            )}
          />
          <Column field="TourbookingDate" title="Booking Date" width={150} />
          <Column field="Name" title="Tourist Name" width={200} />
          <Column field="MobileNo" title="Contact No" width={150} />
          <Column field="SectorName" title="Sector" width={150} />
          <Column field="TravelType" title="Travel Type" width={150} />
          <Column field="TourName" title="Tour" width={350} />
          <Column field="TourCode" title="Tour Date Code" width={150} />
          <Column field="TourStartDate" title="Tour Start Date" width={150} />
          <Column field="NoOfNights" title="Nights" width={150} />
          <Column field="BookBy" title="Book By" width={200} />
          <Column field="BranchName" title="Branch" width={150} />
          {(isHotelAddAccess || isRouteAddAccess) && (
            <Column
              field="ID"
              title="Vouchers"
              width={"110px"}
              filterable={false}
              locked={true}
              cell={(props) => {
                return (
                  <td
                    style={{
                      display: "flex",
                      gap: 5,
                      justifyContent: "center",
                      ...props.style,
                    }}
                    className={props.className}
                  >
                    {isHotelAddAccess && (
                      <IconButton
                        type="button"
                        fillMode="flat"
                        size="small"
                        title="Hotel Voucher"
                        onClick={() =>
                          navigate("/hotelvoucher/edit", {
                            state: {
                              TourBookingID: props.dataItem.ID,
                            },
                          })
                        }
                      >
                        <FaHotel
                          className="absolute-position"
                          style={{ fontSize: "20px" }}
                        />
                      </IconButton>
                    )}
                    {isRouteAddAccess && (
                      <IconButton
                        type="button"
                        fillMode="flat"
                        size="small"
                        title="Route Voucher"
                        onClick={() =>
                          navigate("/routevoucher/edit", {
                            state: {
                              TourBookingID: props.dataItem.ID,
                            },
                          })
                        }
                      >
                        <FaRoute
                          className="absolute-position"
                          style={{ fontSize: "20px" }}
                        />
                      </IconButton>
                    )}
                  </td>
                );
              }}
            />
          )}
          {(isEditAccess || isDeleteAccess) && (
            <Column
              field="ID"
              title="Actions"
              cell={MyEditCommandCell}
              width={"140px"}
              filterable={false}
              locked={true}
            />
          )}
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

const DeleteTourBookingDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const TourBookingID = useAppSelector(
    (state) => state.tourBooking.TourBookingID
  );
  const dateFilter = useAppSelector((state) => state.dashboard.dateFilter);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const handleDeleteAction = async (ID: number | null) => {
    if (ID) {
      const payload = {
        FromDate: dateFilter?.FromDate ? dateFilter?.FromDate : "",
        ToDate: dateFilter?.ToDate ? dateFilter?.ToDate : "",
      };

      const response = await dispatch(deleteTourBooking(ID));
      if (response?.meta?.requestStatus === "fulfilled") {
        dispatch(getAllPendingDocumentTourBooking(payload));
        dispatch(closeDialog());
        dispatch(clearTourBookingID());
      } else {
        dispatch(getAllPendingDocumentTourBooking(payload));
        dispatch(closeDialog());
        dispatch(clearTourBookingID());
      }
    }
  };

  return (
    <>
      {dialogName === "deleteTourBooking" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Tour Booking"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Tour Booking?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(TourBookingID)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default PendingDocumentTourBooking;
