import React, { useEffect, useRef } from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormTextField from "../../components/formFields/FormTextField";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import {
  createPaxType,
  getAllPaxTypes,
  getPaxTypeByID,
  updatePaxType,
} from "./services/paxType.services";
import { clearPaxTypeDetails } from "./paxTypeSlice";
import { closed } from "../../components/muidrawer/muiDrawerSlice";
import { Typography } from "@progress/kendo-react-common";
import { RxCross1 } from "react-icons/rx";
import { STATUSARRAY } from "../../_contstants/common";
import FormSelectionField from "../../components/formFields/FormSelectionField";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}

const PaxCountChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const Adult = formRenderProps.valueGetter("Adult");
  const ExtraAdult = formRenderProps.valueGetter("ExtraAdult");
  const CWB = formRenderProps.valueGetter("CWB");
  const CNB = formRenderProps.valueGetter("CNB");
  const Infant = formRenderProps.valueGetter("Infant");
  useEffect(() => {
    formRenderProps.onChange("PaxCount", {
      value: +Adult + +ExtraAdult + +CWB + +CNB + +Infant,
    });
  }, [Adult, ExtraAdult, CWB, CNB, Infant]);
  return null;
};

const CreatePaxType: React.FC = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);

  const PaxTypeDetail = useAppSelector((state) => state.paxType.PaxTypeDetail);
  const PaxTypeID = useAppSelector((state) => state.muidrawer.data);
  const formLoading = useAppSelector((state) => state.paxType.formLoading);

  useEffect(() => {
    if (PaxTypeID) {
      dispatch(getPaxTypeByID(PaxTypeID));
    }
  }, [PaxTypeID]);

  useEffect(() => {
    return () => {
      dispatch(clearPaxTypeDetails());
      dispatch(closed());
    };
  }, []);

  const handleSubmit = async (values: any) => {
    try {
      if (!PaxTypeID) {
        const insertPayload = {
          PaxType: values.PaxType,
          PaxCount: values.PaxCount ? +values.PaxCount : null,
          Adult: values.Adult ? +values.Adult : 0,
          ExtraAdult: values.ExtraAdult ? +values.ExtraAdult : 0,
          CWB: values.CWB ? +values.CWB : 0,
          CNB: values.CNB ? +values.CNB : 0,
          Infant: values.Infant ? +values.Infant : 0,
          IsActive: values?.IsActive === 1,
        };
        const response = await dispatch(createPaxType(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearPaxTypeDetails());
          dispatch(getAllPaxTypes());
        }
      } else {
        const editPayload = {
          ID: PaxTypeID,
          PaxType: values.PaxType,
          PaxCount: values.PaxCount ? +values.PaxCount : null,
          Adult: values.Adult ? +values.Adult : 0,
          ExtraAdult: values.ExtraAdult ? +values.ExtraAdult : 0,
          CWB: values.CWB ? +values.CWB : 0,
          CNB: values.CNB ? +values.CNB : 0,
          Infant: values.Infant ? +values.Infant : 0,
          IsActive: values?.IsActive === 1,
        };
        const response = await dispatch(updatePaxType(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearPaxTypeDetails());
          dispatch(getAllPaxTypes());
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  const handleFormClose = () => {
    dispatch(closed());
  };
  if (formLoading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={PaxTypeDetail}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ width: "100%" }}>
          <GridLayout
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
            gap={{ rows: 10 }}
            cols={[{ width: "100%" }]}
          >
            <GridLayoutItem className="master-drawer-header">
              <Typography.h4 className="m-0">
                {PaxTypeID ? "Update Pax Type" : "Create Pax Type"}
              </Typography.h4>
              <Button
                type="button"
                fillMode={"flat"}
                themeColor={"dark"}
                style={{ marginRight: 4 }}
                onClick={() => dispatch(closed())}
              >
                <RxCross1 />
              </Button>
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="PaxType"
                label="Pax Type"
                placeholder="i.e. 2 Adults in 1 Room"
                component={FormTextField}
                validator={requiredValidator}
                astrike={true}
              />
            </GridLayoutItem>
            <GridLayoutItem
              style={{ width: "100%", display: "flex", gap: "10px" }}
            >
              <Field
                wrapperClassName="w-100"
                name="Adult"
                label="Adult"
                type="number"
                placeholder="0"
                astrike={true}
                component={FormTextField}
                validator={requiredValidator}
              />
              <Field
                wrapperClassName="w-100"
                name="ExtraAdult"
                label="Extra Adult"
                type="number"
                placeholder="0"
                component={FormTextField}
              />
            </GridLayoutItem>
            <GridLayoutItem
              style={{ width: "100%", display: "flex", gap: "10px" }}
            >
              <Field
                wrapperClassName="w-100"
                name="CWB"
                label="Child With Bed"
                type="number"
                placeholder="0"
                component={FormTextField}
              />
              <Field
                wrapperClassName="w-100"
                name="CNB"
                label="Child With No Bed"
                type="number"
                placeholder="0"
                component={FormTextField}
              />
              <Field
                wrapperClassName="w-100"
                name="Infant"
                label="Infant"
                type="number"
                placeholder="0"
                component={FormTextField}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="PaxCount"
                label="Pax Count"
                type="number"
                placeholder="0"
                component={FormTextField}
                disabled={"true"}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                id={"IsActive"}
                name={"IsActive"}
                label={"Status"}
                component={FormSelectionField}
                validator={requiredValidator}
                astrike={true}
                options={STATUSARRAY?.map((status: any) => {
                  return {
                    value: status?.value,
                    label: status?.label,
                  };
                })}
              />
            </GridLayoutItem>
            <GridLayoutItem
              colSpan={3}
              style={{
                textAlign: "start",
                marginTop: "20px",
                width: "100%",
              }}
            >
              <ButtonWithLoading
                label={!PaxTypeID ? "Create" : "Update"}
                type="submit"
                disabled={!formRenderProps.allowSubmit || formLoading}
                loading={formLoading}
              />
              <Button
                type="button"
                fillMode={"outline"}
                themeColor={"primary"}
                style={{ marginLeft: 4 }}
                onClick={handleFormClose}
              >
                Cancel
              </Button>
            </GridLayoutItem>
          </GridLayout>
          <PaxCountChangeWatcher formRenderProps={formRenderProps} />
        </FormElement>
      )}
    />
  );
};

export default CreatePaxType;
