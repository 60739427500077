import React, { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  filterBy,
  CompositeFilterDescriptor,
} from "@progress/kendo-data-query";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { PagerTargetEvent } from "@progress/kendo-react-data-tools";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import { Typography } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import { FiEdit } from "react-icons/fi";
import AlertBox from "../../components/common/AlertBox";
import { LoadingPanel } from "../../components/layout/Loading";
import IconButton from "../../components/common/IconButton";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import { MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import {
  deletePaymentReceipt,
  getAllPaymentReceipts,
} from "./services/paymentReceipt.services";
import {
  clearPaymentReceiptID,
  setPaymentReceiptID,
} from "./paymentReceiptSlice";
import { checkAcessRights } from "../../_helper/helper";
import {
  DELETE_ACCESS,
  EDIT_ACCESS,
  INSERT_ACCESS,
} from "../../_contstants/common";

const PaymentReceipt: React.FC = () => {
  const navigate = useNavigate();
  const isAddAccess = checkAcessRights(location.pathname, INSERT_ACCESS);

  const handleHotelBookingCreate = () => {
    navigate("/paymentreceipt/create");
  };

  return (
    <>
      <DeletePayemntReceiptDialog />
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Payment Receipt List
          </Typography.h4>
          {isAddAccess && (
            <Button
              onClick={handleHotelBookingCreate}
              fillMode={"solid"}
              themeColor={"primary"}
            >
              Add Payment Receipt
            </Button>
          )}
        </GridLayoutItem>
        <GridLayoutItem>
          <PaymentReceiptGridComponent />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 10 };

const PaymentReceiptGridComponent: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const gridRef = useRef<any>(null);
  const isEditAccess = checkAcessRights(location.pathname, EDIT_ACCESS);
  const isDeleteAccess = checkAcessRights(location.pathname, DELETE_ACCESS);

  const PaymentReceiptList = useAppSelector(
    (state) => state.paymentReceipt.PaymentReceiptList
  );
  const loading = useAppSelector((state) => state.paymentReceipt.loading);

  useEffect(() => {
    dispatch(getAllPaymentReceipts());
  }, []);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();
  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All" ? PaymentReceiptList.length : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEditPaymentReceipt = (ID: number) => {
      navigate("/paymentreceipt/edit", { state: { PaymentReceiptID: ID } });
    };
    const handleOpenDeleteDialog = (ID: number) => {
      dispatch(openDialog("deletePaymentReceipt"));
      dispatch(setPaymentReceiptID(ID));
    };
    return (
      <td className={`action-td ${props.className}`} style={props.style}>
        {/* <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Print"
          onClick={() =>
            window.open(
              `/paymentreceiptcustomerview/${props.dataItem?.ReceiptNo}`
            )
          }
        >
          <MdLocalPrintshop
            className="absolute-position"
            style={{ fontSize: "20px" }}
          />
        </IconButton> */}
        {isEditAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Edit"
            onClick={() => handleEditPaymentReceipt(props.dataItem.ID)}
          >
            <FiEdit
              className="absolute-position"
              style={{ fontSize: "20px" }}
            />
          </IconButton>
        )}
        {isDeleteAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Delete"
            onClick={() => handleOpenDeleteDialog(props.dataItem.ID)}
          >
            <MdDelete
              className="absolute-position"
              style={{ fontSize: "24px" }}
            />
          </IconButton>
        )}
      </td>
    );
  };
  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className={`table-header ${props.index === 8 ? "locked-header" : ""}`}
    >
      {props.children}
    </HeaderThElement>
  );
  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };
  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );
  if (loading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      {PaymentReceiptList && PaymentReceiptList.length ? (
        <KendoGrid
          style={{ maxHeight: "calc(100vh - 152px)" }}
          filterable={true}
          className="responsive-table"
          filter={filter}
          data={
            filter
              ? filterBy(PaymentReceiptList, filter).slice(
                  page.skip,
                  page.take + page.skip
                )
              : PaymentReceiptList.slice(page.skip, page.take + page.skip)
          }
          onFilterChange={handleFilterChange}
          skip={page.skip}
          take={page.take}
          total={PaymentReceiptList.length}
          pageable={{
            buttonCount: 5,
            pageSizes: [5, 10, 15, "All"],
            pageSizeValue: pageSizeValue,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column
            field="ReceiptNo"
            title="Receipt No"
            width={150}
            cell={(props: any) => (
              <td>
                <p
                  className="hoverableName"
                  style={{ fontWeight: 600, cursor: "pointer", margin: 0 }}
                  onClick={() =>
                    window.open(
                      `/paymentreceiptcustomerview/${props.dataItem?.ReceiptNo}`
                    )
                  }
                >
                  {props.dataItem?.ReceiptNo}
                </p>
              </td>
            )}
          />
          <Column field="PaymentDate" title="Payment Date"  width={150} />
          <Column field="PaymentFor" title="Payment For"  width={150} />
          <Column field="TourBookingNo" title="Tour Booking No"  width={200} />
          <Column field="Name" title="Name"  width={300}/>
          <Column field="CompanyName" title="Company Name" width={250} />
          <Column field="Amount" title="Amount" width={150} />
          <Column field="PaymentType" title="Payment Type" width={150} />
          {(isEditAccess || isDeleteAccess) && (
            <Column
              field="ID"
              title="Actions"
              cell={MyEditCommandCell}
              width={"110px"}
              // width={"140px"}
              filterable={false}
              locked={true}
            />
          )}
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

const DeletePayemntReceiptDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const PaymentReceiptID = useAppSelector(
    (state) => state.paymentReceipt.PaymentReceiptID
  );
  const handleDeleteAction = async (ID: number | null) => {
    if (ID) {
      const response = await dispatch(deletePaymentReceipt(ID));
      if (response.payload.Data.Status === 200) {
        dispatch(getAllPaymentReceipts());
        dispatch(closeDialog());
        dispatch(clearPaymentReceiptID());
      } else {
        dispatch(getAllPaymentReceipts());
        dispatch(closeDialog());
        dispatch(clearPaymentReceiptID());
      }
    }
  };
  return (
    <>
      {dialogName === "deletePaymentReceipt" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Payment Receipt"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Payment Receipt?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(PaymentReceiptID)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default PaymentReceipt;
