import { configureStore } from "@reduxjs/toolkit";
import { getDefaultMiddleware } from "@reduxjs/toolkit";
import dialogSlice from "../components/dialog/dialogSlice";
import loginReducer from "../features/login/loginSlice";
import countrySlice from "../features/country/countrySlice";
import userSlice from "../features/user/userSlice";
import menuSlice from "../features/menu/menuSlice";
import rightsSlice from "../features/rights/rightsSlice";
import userTypeSlice from "../features/userType/userTypeSlice";
import citySlice from "../features/city/citySlice";
import stateSlice from "../features/state/stateSlice";
import muiDrawerSlice from "../components/muidrawer/muiDrawerSlice";
import regionSlice from "../features/region/regionSlice";
import specialityTypeSlice from "../features/specialitytype/specialityTypeSlice";
import mealtypeSlice from "../features/mealtype/mealtypeSlice";
import roomTypeSlice from "../features/roomtype/roomTypeSlice";
import vendorTypeSlice from "../features/vendortype/vendorTypeSlice";
import companySlice from "../features/company/companySlice";
import vehicleSharingPaxSlice from "../features/vehiclesharingpax/vehicleSharingPaxSlice";
import airlineSlice from "../features/airline/airlineSlice";
import airlineclassSlice from "../features/airlineclass/airlineclassSlice";
import flightplaceSlice from "../features/flightplace/flightplaceSlice";
import airportSlice from "../features/airport/airportSlice";
import paxTypeSlice from "../features/paxtype/paxTypeSlice";
import routeSlice from "../features/route/routeSlice";
import branchSlice from "../features/branch/branchSlice";
import roomCategorySlice from "../features/roomcategory/roomCategorySlice";
import hotelMappingSlice from "../features/hotelmapping/hotelMappingSlice";
import currencySlice from "../features/currency/currencySlice";
import tourSlice from "../features/tour/tourSlice";
import gstSlice from "../features/gst/gstSlice";
import vehicleTypeSlice from "../features/vehicleType/vehicleTypeSlice";
import bankSlice from "../features/bank/bankSlice";
import sightseenSlice from "../features/sightseen/sightseenSlice";
import vendorsSlice from "../features/vendors/vendorsSlice";
import documentSlice from "../features/document/documentSlice";
import sectorSlice from "../features/sector/sectorSlice";
import itinerarySlice from "../features/itinerary/itinerarySlice";
import hotelSlice from "../features/hotel/hotelSlice";
import tourBookingSlice from "../features/tourbooking/tourBookingSlice";
import nationalitySlice from "../features/nationality/nationalitySlice";
import foodOptionSlice from "../features/foodoption/foodOptionSlice";
import paymentReceiptSlice from "../features/paymentreceipt/paymentReceiptSlice";
import reportSlice from "../features/reports/reportsSlice";
import dashboardSlice from "../features/dashboard/dashboardSlice";

const customizedMiddleware = () =>
  getDefaultMiddleware({
    serializableCheck: false,
  });

const store = configureStore({
  reducer: {
    login: loginReducer,
    dashboard: dashboardSlice,
    dialog: dialogSlice,
    muidrawer: muiDrawerSlice,
    country: countrySlice,
    city: citySlice,
    state: stateSlice,
    user: userSlice,
    menu: menuSlice,
    rights: rightsSlice,
    userType: userTypeSlice,
    region: regionSlice,
    specialityType: specialityTypeSlice,
    mealType: mealtypeSlice,
    roomType: roomTypeSlice,
    vendorType: vendorTypeSlice,
    vehicleType: vehicleTypeSlice,
    sightseen: sightseenSlice,
    company: companySlice,
    vehicleSharingPax: vehicleSharingPaxSlice,
    airline: airlineSlice,
    airlineclass: airlineclassSlice,
    flightplace: flightplaceSlice,
    airport: airportSlice,
    paxType: paxTypeSlice,
    route: routeSlice,
    branch: branchSlice,
    vendor: vendorsSlice,
    document: documentSlice,
    reports: reportSlice,
    bank: bankSlice,
    sector: sectorSlice,
    itinerary: itinerarySlice,
    roomCategory: roomCategorySlice,
    hotelMapping: hotelMappingSlice,
    currency: currencySlice,
    gst: gstSlice,
    tour: tourSlice,
    hotel: hotelSlice,
    tourBooking: tourBookingSlice,
    nationality: nationalitySlice,
    foodOption: foodOptionSlice,
    paymentReceipt: paymentReceiptSlice,
  },
  middleware: customizedMiddleware,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
