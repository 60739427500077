import React, { useEffect, useRef } from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormTextField from "../../components/formFields/FormTextField";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import {
  createCurrency,
  getAllActiveCurrencies,
  getAllCurrencies,
  getCurrencyByID,
  updateCurrency,
} from "./services/currency.services";
import { clearCurrencyDetails } from "./currencySlice";
import { closed } from "../../components/muidrawer/muiDrawerSlice";
import { Typography } from "@progress/kendo-react-common";
import { RxCross1 } from "react-icons/rx";
import { STATUSARRAY } from "../../_contstants/common";
import FormSelectionField from "../../components/formFields/FormSelectionField";

const CreateCurrency: React.FC = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);

  const CurrencyDetail = useAppSelector(
    (state) => state.currency.CurrencyDetail
  );
  const CurrencyID = useAppSelector((state) => state.muidrawer.data);
  const formLoading = useAppSelector((state) => state.currency.formLoading);
  const isIndirect = useAppSelector((state) => state.muidrawer.isIndirect);

  useEffect(() => {
    return () => {
      dispatch(clearCurrencyDetails());
      dispatch(closed());
    };
  }, []);

  useEffect(() => {
    if (CurrencyID) {
      dispatch(getCurrencyByID(CurrencyID));
    }
  }, [CurrencyID]);

  const handleSubmit = async (values: any) => {
    try {
      if (!CurrencyID) {
        const insertPayload = {
          CurrencyName: values.CurrencyName,
          CurrencyConversion: values.CurrencyConversion
            ? +values.CurrencyConversion
            : null,
          IsActive: values?.IsActive === 1,
        };
        const response = await dispatch(createCurrency(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearCurrencyDetails());
          if (isIndirect) {
            dispatch(getAllActiveCurrencies());
          } else {
            dispatch(getAllCurrencies());
          }
        }
      } else {
        const editPayload = {
          ID: CurrencyID,
          CurrencyName: values.CurrencyName,
          CurrencyConversion: values.CurrencyConversion
            ? +values.CurrencyConversion
            : null,
          IsActive: values?.IsActive === 1,
        };
        const response = await dispatch(updateCurrency(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearCurrencyDetails());
          dispatch(getAllCurrencies());
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  const handleFormClose = () => {
    dispatch(closed());
  };

  if (formLoading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={CurrencyDetail}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ width: "100%" }}>
          <GridLayout
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
            gap={{ rows: 10 }}
            cols={[{ width: "100%" }]}
          >
            <GridLayoutItem className="master-drawer-header">
              <Typography.h4 className="m-0">
                {CurrencyID ? "Update Currency" : "Create Currency"}
              </Typography.h4>
              <Button
                type="button"
                fillMode={"flat"}
                themeColor={"dark"}
                style={{ marginRight: 4 }}
                onClick={() => dispatch(closed())}
              >
                <RxCross1 />
              </Button>
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="CurrencyName"
                label="Currency"
                placeholder="i.e. INR"
                component={FormTextField}
                astrike={true}
                validator={requiredValidator}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="CurrencyConversion"
                label="Currency Conversion"
                type="number"
                placeholder="i.e. 1"
                component={FormTextField}
                validator={requiredValidator}
                astrike={true}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                id={"IsActive"}
                name={"IsActive"}
                label={"Status"}
                component={FormSelectionField}
                astrike={true}
                validator={requiredValidator}
                options={STATUSARRAY?.map((status: any) => {
                  return {
                    value: status?.value,
                    label: status?.label,
                  };
                })}
              />
            </GridLayoutItem>
            <GridLayoutItem
              colSpan={3}
              style={{
                textAlign: "start",
                marginTop: "20px",
                width: "100%",
              }}
            >
              <ButtonWithLoading
                label={CurrencyID ? "Update" : "Create"}
                type="submit"
                disabled={!formRenderProps.allowSubmit || formLoading}
                loading={formLoading}
              />
              <Button
                type="button"
                fillMode={"outline"}
                themeColor={"primary"}
                style={{ marginLeft: 4 }}
                onClick={handleFormClose}
              >
                Cancel
              </Button>
            </GridLayoutItem>
          </GridLayout>
        </FormElement>
      )}
    />
  );
};

export default CreateCurrency;
