import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { ITour, ITourCostDetails, ITourDate, ITourPlace } from "../tourModel";
import moment from "moment";

export const getAllTours = createAsyncThunk("Tour/FindAllTour", async () => {
  try {
    const response = await axiosApiInstance.post(
      `${API_URL}/Tour/FindAllTour`,
      {
        TourName: "",
        TourType: "",
        SectorName: "",
        TourCode: "",
        Nights: null,
        CompanyName: "",
      }
    );
    return response.data?.Data;
  } catch (error: any) {
    // ErrorToast(error?.response?.data?.Details || "Something went wrong");
    console.error("Error Fetching Tours:", error);
    return Promise.reject({
      message: error?.response?.data?.Details || "Something went wrong",
    });
  }
});

export const createTour = createAsyncThunk(
  "Tour/InsertTour",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Tour/InsertTour`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating tour:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateTour = createAsyncThunk(
  "Tour/UpdateTour",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Tour/UpdateTour`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating tour:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteTour = createAsyncThunk(
  "Tour/DeleteTour",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Tour/DeleteTour`,
        { ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting tour:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getTourByID = createAsyncThunk(
  "Tour/FindByIDTour",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Tour/FindByIDTour`,
        {
          ID: ID,
        }
      );
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        SectorType: result.SectorType,
        SectorID: result.SectorID,
        GST: result.GST,
        TCS: result.TCS,
        TourType: result.TourType,
        TourName: result.TourName,
        TourCode: result.TourCode,
        VisaVendorID: result?.VisaVendorID,
        InsuranceVendorID: result?.InsuranceVendorID,
        CompanyID: result.CompanyID,
        Nights: result.Nights,
        Days: result.Days,
        SpecialRemarks: result.SpecialRemarks,
        PickupPlace: result.PickupPlace,
        PickupTime: result.PickupTime
          ? moment(result.PickupTime, "HH:mm:ss").toDate()
          : "",
        PickupRemarks: result.PickupRemarks,
        DropPlace: result.DropPlace,
        DropTime: result.DropTime
          ? moment(result.DropTime, "HH:mm:ss").toDate()
          : "",
        DropRemarks: result.DropRemarks,
        PickupFlightPlace: result.PickupFlightPlace,
        DropFlightPlace: result.DropFlightPlace,
        CreatedBy: result.CreatedBy,
        IsActive: result.IsActive ? 1 : 2,
      } as ITour;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching tour:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ActiveInactiveTour = createAsyncThunk(
  "Tour/ActiveInActiveTour",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Tour/ActiveInActiveTour`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive tour:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveTours = createAsyncThunk(
  "Tour/FindAllActiveTour",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Tour/FindAllActiveTour`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching tours:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createTourDateArray = createAsyncThunk(
  "Tour/UpdateTourCode",
  async (TourDateData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Tour/UpdateTourCode`,
        TourDateData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error inserting tour date:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createTourPlaceArray = createAsyncThunk(
  "Tour/UpdateTourPlace",
  async (TourDateData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Tour/UpdateTourPlace`,
        TourDateData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error inserting tour place:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getActiveTourDatesByTourID = createAsyncThunk(
  "Tour/FindAllActiveTourCode",
  async (TourID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Tour/FindAllActiveTourCode`,
        {
          TourID: TourID,
        }
      );
      const result = response.data?.Data as any;
      const TourDateDetails = result?.map((tourDate: any) => ({
        ID: tourDate.ID,
        TourID: tourDate.TourID,
        TourCode: tourDate.TourCode,
        NoOfSeats: tourDate.NoOfSeats,
        TotalNoOfRooms: tourDate.TotalNoOfRooms,
        ManagerID: tourDate.ManagerID,
        AirlineID: tourDate.AirlineID,
        TourDate: tourDate.TourDate
          ? moment(tourDate.TourDate, "DD/MM/YYYY").toDate()
          : "",
      })) as ITourDate[];
      return TourDateDetails || [];
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching tour:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveTourPlacesByTourID = createAsyncThunk(
  "FindAllActiveTourPlace",
  async (TourID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Tour/FindAllActiveTourPlace`,
        {
          TourID: TourID,
        }
      );
      const result = response.data?.Data as any;
      const TourPlaceDetails = result?.map((tourPlace: any) => ({
        ID: tourPlace.ID,
        TourID: tourPlace.TourID,
        CityID: tourPlace.CityID,
        HotelID: tourPlace.HotelID,
        Nights: tourPlace.Nights,
        Position: tourPlace.Position,
        TourCodeID: tourPlace.TourCodeID ? tourPlace.TourCodeID.split(",") : [],
      })) as ITourPlace[];
      return TourPlaceDetails || [];
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching tour:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getTourRatesByTourID = createAsyncThunk(
  "Tour/FindByIdTourRate",
  async (TourID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Tour/FindByIdTourRate`,
        {
          TourID: TourID,
        }
      );
      const result = response.data?.Data as ITourCostDetails[];
      return result || [];
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching tour rates:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const insertTourRate = createAsyncThunk(
  "Tour/InsertTourRate",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Tour/InsertTourRate`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error inserting tour rate:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateTourRate = createAsyncThunk(
  "Tour/UpdateTourRate",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Tour/UpdateTourRate`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating tour rate:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteTourRate = createAsyncThunk(
  "Tour/DeleteTourRate",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Tour/DeleteTourRate`,
        {
          ID: ID,
        }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting tour rate:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const insertTourItinerary = createAsyncThunk(
  "Tour/InsertTourItinerary",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Tour/InsertTourItinerary`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error inserting tour itinerary:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateTourItinerary = createAsyncThunk(
  "Tour/UpdateTourItinerary",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Tour/UpdateTourItinerary`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating tour itinerary:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteTourItinerary = createAsyncThunk(
  "Tour/DeleteTourItinerary",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Tour/DeleteTourItinerary`,
        {
          ID: ID,
        }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting tour itinerary:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getTourItinerariesByTourID = createAsyncThunk(
  "Tour/FindByAllTourItinerary",
  async (TourID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Tour/FindByAllTourItinerary`,
        {
          TourID: TourID,
        }
      );
      const result = response.data?.Data as ITourCostDetails[];
      return result || [];
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching tour itineraries:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
