import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography } from "@progress/kendo-react-common";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import FormSelectionField from "../../../components/formFields/FormSelectionField";
import { requiredValidator } from "../../../components/formFields/CommonValidator";
import { ISector } from "../../sector/sectorModel";
import FormTextField from "../../../components/formFields/FormTextField";
import {
  STATUSARRAY,
  TCS_ARRAY,
  TRAVELTYPE,
} from "../../../_contstants/common";
import { getAllActiveCompanies } from "../../company/services/company.services";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { ICompany } from "../../company/companyModel";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import { createTour, getTourByID, updateTour } from "../services/tour.services";
import { increaseActiveStep } from "../tourSlice";
import ShadowCard from "../../../components/common/ShadowCard";
import RippleButton from "../../../components/common/RippleButton";
import { getAllActiveGST } from "../../gst/services/gst.services";
import {
  getAllActiveSector,
  getSectorByID,
} from "../../sector/services/sector.services";
import { IGST } from "../../gst/gstModel";
// import { IVendors } from "../../vendors/vendorsModel";
// import { getAllActiveVendors } from "../../vendors/services/vendors.services";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const SectorChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const TourID = location.state?.TourID;
  const SectorID = formRenderProps.valueGetter("SectorID");
  const isCurrentRef = useRef(true);

  useEffect(() => {
    const fetchSectorData = async () => {
      if (SectorID) {
        const res: any = await dispatch(getSectorByID(+SectorID));
        if (res.meta.requestStatus === "fulfilled") {
          formRenderProps.onChange("TourCode", {
            value: res.payload?.Prefix || "",
          });
        }
      }
    };
    if (SectorID && TourID) {
      if (!isCurrentRef.current) {
        fetchSectorData();
      } else {
        isCurrentRef.current = false;
      }
    } else if (SectorID) {
      fetchSectorData();
    } else {
      formRenderProps.onChange("TourCode", {
        value: "",
      });
    }
  }, [SectorID]);
  return null;
};

const TourInformation: React.FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const TourID = location.state?.TourID;
  const loading = useAppSelector((state) => state.tour.loading);
  const TourDetail = useAppSelector((state) => state.tour.TourDetail);
  const CompanyList = useAppSelector((state) => state.company.CompanyList);
  const SectorList = useAppSelector((state) => state.sector.SectorList);
  const GSTList = useAppSelector((state) => state.gst.GSTList);
  // const VendorsList = useAppSelector((state) => state.vendor.VendorsList);

  const [formKey, setFormKey] = useState(1);

  useEffect(() => {
    dispatch(getAllActiveCompanies());
    dispatch(getAllActiveGST());
    dispatch(getAllActiveSector());
    // dispatch(getAllActiveVendors());
  }, []);

  useEffect(() => {
    if (TourID) {
      dispatch(getTourByID(+TourID));
    }
  }, [TourID]);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [TourDetail]);

  const handleNoOfNightsChange = (e: any, formRenderProps: FormRenderProps) => {
    formRenderProps?.onChange(`Days`, {
      value: +e.value + 1 || null,
    });
  };

  const handleSubmit = async (values: any) => {
    try {
      if (!TourID) {
        const insertPayload = {
          TourName: values.TourName ? values.TourName : "",
          TourType: values.TourType ? values.TourType : "",
          SectorType: "INTERNATIONAL",
          SectorID: values.SectorID ? +values.SectorID : null,
          TourCode: values.TourCode ? values.TourCode : "",
          Nights: values.Nights ? +values.Nights : null,
          Days: values.Days ? +values.Days : null,
          GST: values.GST ? +values.GST : null,
          TCS: values.TCS ? +values.TCS : null,
          // SpecialityTypeID: Array.isArray(values.SpecialityTypeID)
          //   ? values.SpecialityTypeID.join(",")
          //   : values.SpecialityTypeID,
          CompanyID: values.CompanyID ? +values.CompanyID : null,
          // VisaVendorID: values.VisaVendorID ? +values.VisaVendorID : null,
          // InsuranceVendorID: values.InsuranceVendorID
          //   ? +values.InsuranceVendorID
          //   : null,
          IsActive: values.IsActive === 1,
          // SpecialRemarks: values.SpecialRemarks ? values.SpecialRemarks : "",
          // PickupPlace: values.PickupPlace ? values.PickupPlace : "",
          // PickupTime: values.PickupTime
          //   ? moment(values.PickupTime).format("HH:mm:ss")
          //   : "",
          // PickupRemarks: values.PickupRemarks ? values.PickupRemarks : "",
          // DropPlace: values.DropPlace ? values.DropPlace : "",
          // DropTime: values.DropTime
          //   ? moment(values.DropTime).format("HH:mm:ss")
          //   : "",
          // DropRemarks: values.DropRemarks ? values.DropRemarks : "",
          // PickupFlightPlace: values.PickupFlightPlace
          //   ? values.PickupFlightPlace
          //   : "",
          // DropFlightPlace: values.DropFlightPlace ? values.DropFlightPlace : "",
        };
        const response = await dispatch(createTour(insertPayload));
        if (
          response?.meta?.requestStatus === "fulfilled" &&
          response.payload?.ID
        ) {
          navigate("/tour/edit", {
            state: {
              TourID: response.payload?.ID,
              TourCode: values.TourCode ? values.TourCode : "",
              SectorID: values.SectorID ? values.SectorID : "",
            },
          });
          dispatch(increaseActiveStep());
        }
      } else {
        const editPayload = {
          ID: +TourID,
          TourName: values.TourName ? values.TourName : "",
          TourType: values.TourType ? values.TourType : "",
          SectorType: "INTERNATIONAL",
          SectorID: values.SectorID ? +values.SectorID : null,
          TourCode: values.TourCode ? values.TourCode : "",
          Nights: values.Nights ? +values.Nights : null,
          Days: values.Days ? +values.Days : null,
          // SubSectorID: values.SubSectorID ? +values.SubSectorID : null,
          GST: values.GST ? +values.GST : null,
          TCS: values.TCS ? +values.TCS : null,
          // SpecialityTypeID: Array.isArray(values.SpecialityTypeID)
          //   ? values.SpecialityTypeID.join(",")
          //   : values.SpecialityTypeID,
          CompanyID: values.CompanyID ? +values.CompanyID : null,
          // VisaVendorID: values.VisaVendorID ? +values.VisaVendorID : null,
          // InsuranceVendorID: values.InsuranceVendorID
          //   ? +values.InsuranceVendorID
          //   : null,
          // SpecialRemarks: values.SpecialRemarks ? values.SpecialRemarks : "",
          // PickupPlace: values.PickupPlace ? values.PickupPlace : "",
          // PickupTime: values.PickupTime
          //   ? moment(values.PickupTime).format("HH:mm:ss")
          //   : "",
          // PickupRemarks: values.PickupRemarks ? values.PickupRemarks : "",
          // DropPlace: values.DropPlace ? values.DropPlace : "",
          // DropTime: values.DropTime
          //   ? moment(values.DropTime).format("HH:mm:ss")
          //   : "",
          // DropRemarks: values.DropRemarks ? values.DropRemarks : "",
          // PickupFlightPlace: values.PickupFlightPlace
          //   ? values.PickupFlightPlace
          //   : "",
          // DropFlightPlace: values.DropFlightPlace ? values.DropFlightPlace : "",
          IsActive: values.IsActive === 1,
        };
        const response = await dispatch(updateTour(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/tour/edit", {
            state: {
              TourID: TourID,
              TourCode: values.TourCode ? values.TourCode : "",
              SectorID: values.SectorID ? values.SectorID : "",
            },
          });
          dispatch(increaseActiveStep());
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  return (
    <Form
      onSubmit={handleSubmit}
      key={formKey}
      initialValues={TourDetail}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement>
          {/* <HotelListChangeWatcher formRenderProps={formRenderProps} /> */}
          <ShadowCard style={{ padding: 10 }}>
            <GridLayout
              style={{ marginRight: 20 }}
              gap={{ rows: 0, cols: 10 }}
              cols={[
                { width: "33.33%" },
                { width: "33.33%" },
                { width: "33.33%" },
              ]}
            >
              <GridLayoutItem colSpan={3}>
                <Typography.h4>{"Tour Details"}</Typography.h4>
              </GridLayoutItem>
              {/* <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  id={"SectorType"}
                  name={"SectorType"}
                  label={"Sector type"}
                  component={FormSelectionField}
                  validator={requiredValidator}
                  options={SECTORTYPE?.map((sectortype: any) => {
                    return {
                      value: sectortype?.value,
                      label: sectortype?.label,
                    };
                  })}
                />
              </GridLayoutItem> */}
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  id={"TourType"}
                  name={"TourType"}
                  label={"Travel type"}
                  component={FormSelectionField}
                  validator={requiredValidator}
                  astrike={true}
                  options={TRAVELTYPE?.map((traveltype: any) => {
                    return {
                      value: traveltype?.value,
                      label: traveltype?.label,
                    };
                  })}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  id={"CompanyID"}
                  name={"CompanyID"}
                  label={"Company"}
                  component={FormSelectionField}
                  validator={requiredValidator}
                  astrike={true}
                  options={CompanyList?.map((company: ICompany) => {
                    return {
                      value: company?.ID,
                      label: company?.CompanyName,
                    };
                  })}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  id={"SectorID"}
                  name={"SectorID"}
                  label={"Sector"}
                  isAddNew={true}
                  addNewLink={"sector/create"}
                  dispatcherFunction={getAllActiveSector}
                  component={FormSelectionField}
                  validator={requiredValidator}
                  astrike={true}
                  options={SectorList?.map((sector: ISector) => {
                    return {
                      value: sector?.ID,
                      label: sector?.SectorName,
                    };
                  })}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name="TourName"
                  label="Tour Name"
                  component={FormTextField}
                  validator={requiredValidator}
                  astrike={true}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name="TourCode"
                  label="Tour Code"
                  component={FormTextField}
                  validator={requiredValidator}
                  astrike={true}
                />
              </GridLayoutItem>
              {/* <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  id={"SpecialityTypeID"}
                  name={"SpecialityTypeID"}
                  label={"Tour Speciality"}
                  component={FormMultiSelectionFiled}
                  validator={requiredArrayValidator}
                  options={SpecialityTypeList?.map(
                    (specialitytype: ISpecialityType) => {
                      return {
                        value: specialitytype?.ID,
                        label: specialitytype?.SpecialityType,
                      };
                    }
                  )}
                />
              </GridLayoutItem> */}
              <GridLayoutItem
                style={{ width: "100%", display: "flex", gap: 10 }}
              >
                <Field
                  wrapperClassName="w-100"
                  name="Nights"
                  label="Nights"
                  type="number"
                  max="2"
                  onChange={(e) => handleNoOfNightsChange(e, formRenderProps)}
                  component={FormTextField}
                  validator={requiredValidator}
                  astrike={true}
                />
                <Field
                  wrapperClassName="w-100"
                  name="Days"
                  label="Days"
                  type="number"
                  // max="2"
                  disabled={true}
                  component={FormTextField}
                  astrike={true}
                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  id={"GST"}
                  name={"GST"}
                  label={"GST"}
                  component={FormSelectionField}
                  validator={requiredValidator}
                  astrike={true}
                  isAddNew={true}
                  drawerName={"gstForm"}
                  isIndirect={true}
                  options={GSTList?.map((gst: IGST) => {
                    return {
                      value: gst?.ID,
                      label: gst?.Title,
                    };
                  })}
                />
              </GridLayoutItem>
              {/* need to change acordingly here */}
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  id={"TCS"}
                  name={"TCS"}
                  label={"TCS"}
                  component={FormSelectionField}
                  options={TCS_ARRAY}
                />
              </GridLayoutItem>
              {/* <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  id={"VisaVendorID"}
                  name={"VisaVendorID"}
                  label={"Visa Vendor"}
                  component={FormSelectionField}
                  options={VendorsList?.map((vendor: IVendors) => {
                    return {
                      value: vendor?.ID,
                      label: vendor?.VendorName,
                    };
                  })}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  id={"InsuranceVendorID"}
                  name={"InsuranceVendorID"}
                  label={"Insurance Vendor"}
                  component={FormSelectionField}
                  options={VendorsList?.map((vendor: IVendors) => {
                    return {
                      value: vendor?.ID,
                      label: vendor?.VendorName,
                    };
                  })}
                />
              </GridLayoutItem> */}
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  id={"IsActive"}
                  name={"IsActive"}
                  label={"Status"}
                  component={FormSelectionField}
                  validator={requiredValidator}
                  astrike={true}
                  options={STATUSARRAY?.map((status: any) => {
                    return {
                      value: status?.value,
                      label: status?.label,
                    };
                  })}
                />
              </GridLayoutItem>
              {/* <GridLayoutItem>
                <Field
                  name="SpecialRemarks"
                  label="Special Remarks"
                  component={FormTextArea}
                />
              </GridLayoutItem> */}
              <GridLayoutItem
                colSpan={3}
                style={{
                  textAlign: "end",
                  marginTop: "20px",
                  width: "100%",
                }}
              >
                <RippleButton
                  fillMode="outline"
                  themeColor="primary"
                  style={{ marginRight: 4 }}
                  onClick={() => navigate("/tour")}
                >
                  Cancel
                </RippleButton>
                <ButtonWithLoading
                  label={TourID ? "Update" : "Add"}
                  type="submit"
                  disabled={!formRenderProps.allowSubmit || loading}
                  loading={loading}
                />
              </GridLayoutItem>
            </GridLayout>
            <SectorChangeWatcher formRenderProps={formRenderProps} />
          </ShadowCard>
          {/* <ShadowCard style={{ padding: 10, marginTop: 10 }}>
            <GridLayout
              style={{ marginRight: 20 }}
              gap={{ rows: 0, cols: 10 }}
              cols={[
                { width: "33.33%" },
                { width: "33.33%" },
                { width: "33.33%" },
              ]}
            >
              <GridLayoutItem colSpan={3}>
                <Typography.h4>{"Pickup Details"}</Typography.h4>
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name="PickupPlace"
                  label="Pickup Place"
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name="PickupFlightPlace"
                  label="Pickup Flight"
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  id={"PickupTime"}
                  name={"PickupTime"}
                  label={"Pickup Time"}
                  component={FormTimePicker}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name="PickupRemarks"
                  label="Pickup Remarks"
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name="DropPlace"
                  label="Drop Place"
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name="DropFlightPlace"
                  label="Drop Flight"
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  id={"DropTime"}
                  name={"DropTime"}
                  label={"Drop Time"}
                  component={FormTimePicker}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name="DropRemarks"
                  label="Drop Remarks"
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem
                colSpan={3}
                style={{
                  textAlign: "end",
                  marginTop: "20px",
                  width: "100%",
                }}
              >
                <RippleButton
                  fillMode="outline"
                  themeColor="primary"
                  style={{ marginRight: 4 }}
                  onClick={() => navigate("/tour")}
                >
                  Cancel
                </RippleButton>
                <ButtonWithLoading
                  label={TourID ? "Update" : "Add"}
                  type="submit"
                  disabled={!formRenderProps.allowSubmit || loading}
                  loading={loading}
                />
              </GridLayoutItem>
            </GridLayout>
          </ShadowCard> */}
        </FormElement>
      )}
    />
  );
};

export default TourInformation;
