import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import React from "react";
import {
  closeDialog,
  openDialog,
} from "../../../components/dialog/dialogSlice";
import { setDeleteIndex } from "../tourSlice";
import { Card, GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import AppDialog, {
  DialogCloseButton,
} from "../../../components/dialog/Dialog";
import { Typography } from "@progress/kendo-react-common";
import IconButton from "../../../components/common/IconButton";
import { MdDelete } from "react-icons/md";
import FormDatePicker from "../../../components/formFields/FormDateField";
import { requiredValidator } from "../../../components/formFields/CommonValidator";
import FormTextField from "../../../components/formFields/FormTextField";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { axiosApiInstance } from "../../../app/axios";
import { API_URL } from "../../../app/config";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IAirline } from "../../airline/airlineModel";
import FormSelectionField from "../../../components/formFields/FormSelectionField";
import { getAllActiveAirlines } from "../../airline/services/airline.services";
import { getAllActiveUsers } from "../../user/services/user.services";

const TourDateArray = (fieldArrayRenderProps: FieldArrayRenderProps) => {
  const dispatch = useAppDispatch();
  const TourDateDeleteIndex = useAppSelector((state) => state.tour.deleteIndex);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const UserList = useAppSelector((state) => state.user.UserList);
  const AirlineList = useAppSelector((state) => state.airline.AirlineList);
  const location = useLocation();
  const TourCode = location.state?.TourCode;

  const handleOpenDeleteDialog = (index: number) => {
    dispatch(openDialog("tourDateDeleteDialog"));
    dispatch(setDeleteIndex(index));
  };

  const handleDeleteAction = React.useCallback(
    async (index: number) => {
      if (fieldArrayRenderProps?.value[index].ID) {
        try {
          const response = await axiosApiInstance.post(
            `${API_URL}/Tour/DeleteTourCode`,
            {
              ID: fieldArrayRenderProps?.value[index].ID,
            }
          );
          fieldArrayRenderProps.onRemove({ index: index });
          dispatch(closeDialog());
          dispatch(setDeleteIndex(-1));
          SuccessToast(response.data?.Details || "Success");
        } catch (error: any) {
          ErrorToast(error?.response?.data?.Details || "Something went wrong");
          console.error("Error deleting tourdate:", error);
          return Promise.reject({
            message: error?.response?.data?.Details || "Something went wrong",
          });
        }
      } else {
        fieldArrayRenderProps.onRemove({ index: index });
        dispatch(closeDialog());
        dispatch(setDeleteIndex(-1));
      }
    },
    [fieldArrayRenderProps]
  );

  const pushElementInarray = React.useCallback(() => {
    fieldArrayRenderProps.onPush({
      value: {
        TourDate: "",
        TourCode: "",
        NoOfSeats: null,
        TotalNoOfRooms: null,
        ManagerID: "",
        AirlineID: "",
      },
    });
  }, [fieldArrayRenderProps]);

  const handleTourDateChange = (e: any, index: number) => {
    const TourDateCode = moment(e.value).format("DDMMMYY") + TourCode;

    fieldArrayRenderProps?.formRenderProps?.onChange(
      `TourDates.${index}.TourCode`,
      {
        value: TourDateCode?.toUpperCase(),
      }
    );
  };

  return (
    <GridLayout cols={[{ width: "100%" }]}>
      {!fieldArrayRenderProps.value ? pushElementInarray() : null}
      <GridLayoutItem style={{ marginBottom: 10 }}>
        <Button
          fillMode={"solid"}
          themeColor={"primary"}
          onClick={pushElementInarray}
          type="button"
        >
          Add Tour Date
        </Button>
      </GridLayoutItem>
      {dialogName === "tourDateDeleteDialog" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Tour Date"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(TourDateDeleteIndex)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
      {fieldArrayRenderProps.value &&
        fieldArrayRenderProps.value.length > 0 &&
        fieldArrayRenderProps.value.map((TourDate: any, index: number) => (
          <GridLayoutItem key={index}>
            <Card className="fieldarray-card">
              <GridLayout
                style={{ marginRight: 40 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "15%" },
                  { width: "16%" },
                  { width: "20%" },
                  { width: "22%" },
                  { width: "11%" },
                  { width: "15%" },
                ]}
              >
                <GridLayoutItem>
                  <Field
                    name={`TourDates.${index}.TourDate`}
                    label="Tour Date"
                    onChange={(e) => handleTourDateChange(e, index)}
                    format="dd/MM/yyyy"
                    component={FormDatePicker}
                    astrike={true}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`TourDates.${index}.TourCode`}
                    label="Tour Code"
                    validator={requiredValidator}
                    astrike={true}
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`TourDates.${index}.AirlineID`}
                    label="Airline"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    astrike={true}
                    isAddNew={true}
                    addNewLink={"airline/create"}
                    dispatcherFunction={getAllActiveAirlines}
                    options={AirlineList?.map((airline: IAirline) => {
                      return {
                        value: airline?.ID,
                        label: airline?.AirlineName,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`TourDates.${index}.ManagerID`}
                    label="Manager"
                    component={FormSelectionField}
                    isAddNew={true}
                    addNewLink={"user/create"}
                    dispatcherFunction={getAllActiveUsers}
                    options={UserList?.map((user: any) => {
                      return {
                        value: user.ID,
                        label: user.FirstName + " " + user.LastName,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="w-100"
                    name={`TourDates.${index}.NoOfSeats`}
                    label="No Of Seats"
                    type="number"
                    component={FormTextField}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  style={{ display: "flex", width: "100%", gap: 10 }}
                >
                  <Field
                    wrapperClassName="w-100"
                    name={`TourDates.${index}.TotalNoOfRooms`}
                    label="No Of Rooms"
                    type="number"
                    component={FormTextField}
                    validator={requiredValidator}
                    astrike={true}
                  />
                  <IconButton
                    onClick={() => handleOpenDeleteDialog(index)}
                    disabled={fieldArrayRenderProps.value.length === 1}
                    themeColor={"error"}
                    size={"small"}
                    fillMode={"solid"}
                    type="button"
                    style={{ height: 24, width: 24, marginTop: 38 }}
                  >
                    <MdDelete
                      className="absolute-position"
                      style={{ fontSize: "16px" }}
                    />
                  </IconButton>
                </GridLayoutItem>
              </GridLayout>
            </Card>
          </GridLayoutItem>
        ))}
    </GridLayout>
  );
};

export default TourDateArray;
