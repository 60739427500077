import React, { useEffect, useRef } from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormTextField from "../../components/formFields/FormTextField";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import {
  createHotelMapping,
  getAllHotelMappings,
  getHotelMappingByID,
  updateHotelMapping,
} from "./services/hotelMapping.services";
import { clearHotelMappingDetails } from "./hotelMappingSlice";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import {
  getCityByTourID,
} from "../city/services/city.services";
import { ITourCity } from "../city/cityModel";
import { closed } from "../../components/muidrawer/muiDrawerSlice";
import { Typography } from "@progress/kendo-react-common";
import { RxCross1 } from "react-icons/rx";
import { STATUSARRAY } from "../../_contstants/common";
import { getAllActiveTours } from "../tour/services/tour.services";
import { getAllActiveHotels } from "../hotel/services/hotel.services";
import { ITour } from "../tour/tourModel";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const TourChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const isCityRef = useRef(true);
  const isHotelRef = useRef(true);
  const TourID = formRenderProps.valueGetter("TourID");
  const CityID = formRenderProps.valueGetter("CityID");

  useEffect(() => {
    if (TourID) {
      dispatch(getCityByTourID(TourID));
    }
    if (!isCityRef.current) {
      formRenderProps.onChange("CityID", {
        value: null,
      });
    } else {
      isCityRef.current = false;
    }
  }, [TourID]);

  useEffect(() => {
    if (!isHotelRef.current) {
      formRenderProps.onChange("HotelID", {
        value: null,
      });
    } else {
      isHotelRef.current = false;
    }
  }, [CityID]);

  return null;
};

const CreateHotelMapping: React.FC = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);

  const HotelMappingDetail = useAppSelector(
    (state) => state.hotelMapping.HotelMappingDetail
  );
  const HotelMappingID = useAppSelector((state) => state.muidrawer.data);
  const formLoading = useAppSelector((state) => state.hotelMapping.formLoading);
  const TourList = useAppSelector((state) => state.tour.TourList);
  const HotelList = useAppSelector((state) => state.hotel.HotelList);
  const TourCityList = useAppSelector((state) => state.city.TourCityList);

  useEffect(() => {
    if (HotelMappingID) {
      dispatch(getHotelMappingByID(HotelMappingID));
    }
  }, [HotelMappingID]);

  useEffect(() => {
    dispatch(getAllActiveTours());
    dispatch(getAllActiveHotels());

    return () => {
      dispatch(clearHotelMappingDetails());
      dispatch(closed());
    };
  }, []);

  const filterHotels = (CityID: number) => {
    if (CityID) {
      const result = HotelList?.filter((hotel: any) => {
        return hotel?.CityID === CityID;
      });
      return result.map((hotel) => {
        return {
          value: hotel?.ID,
          label: hotel?.HotelName,
        };
      });
    }
  };

  const handleSubmit = async (values: any) => {
    try {
      if (!HotelMappingID) {
        const insertPayload = {
          TourID: values.TourID ? +values.TourID : null,
          CityID: values.CityID ? +values.CityID : null,
          HotelID: values.HotelID ? +values.HotelID : null,
          NoOfNights: values.NoOfNights ? +values.NoOfNights : null,
          PositionNo: values.PositionNo ? +values.PositionNo : null,
          IsActive: values.IsActive === 1,
        };
        const response = await dispatch(createHotelMapping(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearHotelMappingDetails());
          dispatch(getAllHotelMappings());
        }
      } else {
        const editPayload = {
          ID: HotelMappingID,
          TourID: values.TourID ? +values.TourID : null,
          CityID: values.CityID ? +values.CityID : null,
          HotelID: values.HotelID ? +values.HotelID : null,
          NoOfNights: values.NoOfNights ? +values.NoOfNights : null,
          PositionNo: values.PositionNo ? +values.PositionNo : null,
          IsActive: values.IsActive === 1,
        };
        const response = await dispatch(updateHotelMapping(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearHotelMappingDetails());
          dispatch(getAllHotelMappings());
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  const handleFormClose = () => {
    dispatch(closed());
  };

  if (formLoading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={HotelMappingDetail}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ width: "100%" }}>
          <GridLayout
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
            gap={{ rows: 10 }}
            cols={[{ width: "100%" }]}
          >
            <GridLayoutItem className="master-drawer-header">
              <Typography.h4 className="m-0">
                {HotelMappingID
                  ? "Update Hotel Mapping"
                  : "Create Hotel Mapping"}
              </Typography.h4>
              <Button
                type="button"
                fillMode={"flat"}
                themeColor={"dark"}
                style={{ marginRight: 4 }}
                onClick={() => dispatch(closed())}
              >
                <RxCross1 />
              </Button>
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="TourID"
                label="Tour"
                placeholder="Tour Name"
                astrike={true}
                component={FormSelectionField}
                validator={requiredValidator}
                options={TourList?.map((tour: ITour) => {
                  return {
                    value: tour?.ID,
                    label: tour?.TourName,
                  };
                })}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="CityID"
                label="City"
                placeholder="i.e. Ahmedabad"
                component={FormSelectionField}
                validator={requiredValidator}
                astrike={true}
                options={TourCityList?.map((city: ITourCity) => {
                  return {
                    value: city?.PlaceID,
                    label: city?.Place,
                  };
                })}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="HotelID"
                label="Hotel"
                placeholder="Hotel Name"
                component={FormSelectionField}
                validator={requiredValidator}
                astrike={true}
                options={
                  HotelList &&
                  filterHotels(formRenderProps.valueGetter("CityID"))
                }
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="NoOfNights"
                label="No Of Nights"
                max="2"
                placeholder="0"
                component={FormTextField}
                astrike={true}
                validator={requiredValidator}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="PositionNo"
                label="Position No"
                max="2"
                placeholder="0"
                component={FormTextField}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                id={"IsActive"}
                name={"IsActive"}
                label={"Status"}
                astrike={true}
                component={FormSelectionField}
                validator={requiredValidator}
                options={STATUSARRAY?.map((status: any) => {
                  return {
                    value: status?.value,
                    label: status?.label,
                  };
                })}
              />
            </GridLayoutItem>
            <GridLayoutItem
              colSpan={3}
              style={{
                textAlign: "start",
                marginTop: "20px",
                width: "100%",
              }}
            >
              <ButtonWithLoading
                label={!HotelMappingID ? "Create" : "Update"}
                type="submit"
                disabled={!formRenderProps.allowSubmit || formLoading}
                loading={formLoading}
              />
              <Button
                type="button"
                fillMode={"outline"}
                themeColor={"primary"}
                style={{ marginLeft: 4 }}
                onClick={handleFormClose}
              >
                Cancel
              </Button>
            </GridLayoutItem>
          </GridLayout>
          <TourChangeWatcher formRenderProps={formRenderProps} />
        </FormElement>
      )}
    />
  );
};

export default CreateHotelMapping;
