import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import FormTextField from "../../components/formFields/FormTextField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import ShadowCard from "../../components/common/ShadowCard";
import {
  createPaymentReceipt,
  getPaymentReceiptByID,
  getPaymentReceiptSummaryByBookingNo,
  updatePaymentReceipt,
} from "./services/paymentReceipt.services";
import {
  clearPaymentReceiptCostDetail,
  clearPaymentReceiptDetails,
} from "./paymentReceiptSlice";
import { Button } from "@progress/kendo-react-buttons";
import {
  BOOLEAN,
  PAYMENTFOR,
  PAYMENTTYPE,
  UPITYPE,
} from "../../_contstants/common";
import { ICompany } from "../company/companyModel";
import FormDatePicker from "../../components/formFields/FormDateField";
import { Typography } from "@progress/kendo-react-common";
import { IUser } from "../user/userModel";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { getAllActiveUsers } from "../user/services/user.services";
import FormTextArea from "../../components/formFields/FormTextArea";
import { getAllActiveCompanies } from "../company/services/company.services";
import moment from "moment";
import {
  Grid,
  GridColumn as Column,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import { HeaderThElement } from "@progress/kendo-react-data-tools";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const TourBookingChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const PaymentReceiptCostDetail = useAppSelector(
    (state) => state.paymentReceipt.PaymentReceiptCostDetail
  );

  useEffect(() => {
    formRenderProps.onChange("PaymentFor", {
      value: PaymentReceiptCostDetail.PaymentFor,
    });
    formRenderProps.onChange("Name", {
      value: PaymentReceiptCostDetail.Name,
    });
    formRenderProps.onChange("CompanyID", {
      value: PaymentReceiptCostDetail.CompanyID,
    });
    formRenderProps.onChange("RemainingAmount", {
      value: PaymentReceiptCostDetail.RemainingAmount,
    });
    formRenderProps.onChange("TourBookingNo", {
      value: PaymentReceiptCostDetail.PNRNo,
    });
    formRenderProps.onChange("ReceivedByID", {
      value: parseInt(localStorage.getItem("UserID") || "0"),
    });
  }, [PaymentReceiptCostDetail]);
  return null;
};

const CreatePaymentReceipt: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const PaymentReceiptID = location.state?.PaymentReceiptID;
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const [formKey, setFormKey] = React.useState(1);

  const loading = useAppSelector((state) => state.paymentReceipt.loading);
  const CompanyList = useAppSelector((state) => state.company.CompanyList);
  const UserList = useAppSelector((state) => state.user.UserList);
  const PaymentReceiptDetail = useAppSelector(
    (state) => state.paymentReceipt.PaymentReceiptDetail
  );
  const PaymentReceiptCostDetail = useAppSelector(
    (state) => state.paymentReceipt.PaymentReceiptCostDetail
  );

  useEffect(() => {
    if (PaymentReceiptID) {
      dispatch(getPaymentReceiptByID(PaymentReceiptID));
    }
  }, [PaymentReceiptID]);
  useEffect(() => {
    const bookingNo = PaymentReceiptDetail?.TourBookingNo;
    if (PaymentReceiptDetail?.TourBookingNo) {
      dispatch(getPaymentReceiptSummaryByBookingNo(bookingNo));
    }
  }, [PaymentReceiptDetail]);

  useEffect(() => {
    dispatch(getAllActiveUsers());
    dispatch(getAllActiveCompanies());

    return () => {
      dispatch(clearPaymentReceiptDetails());
      dispatch(clearPaymentReceiptCostDetail());
    };
  }, []);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [PaymentReceiptDetail]);

  const handleGetPaymentReceipt = (formRenderProps: FormRenderProps) => {
    const BookingNo = formRenderProps.valueGetter("TourBookingNo");
    if (BookingNo) {
      dispatch(getPaymentReceiptSummaryByBookingNo(BookingNo));
    }
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className="table-header"
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  const handleSubmit = async (values: any) => {
    const formdata = {
      ReceiptNo: values.ReceiptNo ? values.ReceiptNo.toString() : "",
      PaymentFor: values.PaymentFor ? values.PaymentFor : "",
      TourBookingNo: values.TourBookingNo
        ? values.TourBookingNo.toString()
        : "",
      Name: values.Name ? values.Name : "",
      CompanyID: values.CompanyID ? values.CompanyID : null,
      PaymentDate: values.PaymentDate
        ? moment(values.PaymentDate).format("YYYY-MM-DD")
        : null,
      Amount: values.Amount ? +values.Amount : null,
      PaymentType: values.PaymentType ? values.PaymentType : "",
      BankName: values.BankName ? values.BankName : "",
      TransactionNo: values.TransactionNo
        ? values.TransactionNo.toString()
        : "",
      TransactionDate: values.TransactionDate
        ? moment(values.TransactionDate).format("YYYY-MM-DD")
        : null,
      ChequeNo: values.ChequeNo ? values.ChequeNo.toString() : "",
      ChequeDate: values.ChequeDate
        ? moment(values.ChequeDate).format("YYYY-MM-DD")
        : null,
      BranchName: values.BranchName ? values.BranchName : "",
      UpiType: values.UpiType ? values.UpiType.toString() : "",
      CreditNoteNo: values.CreditNoteNo ? values.CreditNoteNo.toString() : "",
      IsRefund: values.IsRefund === 1,
      ReceivedByID: values.ReceivedByID ? values.ReceivedByID : null,
      Remarks: values.Remarks ? values.Remarks : "",
      Commission: values?.Commission ? +values?.Commission : null,
      ApprovedRemarks: values.ApprovedRemarks ? values?.ApprovedRemarks : "",
      BankID: values?.BankID,
      ApprovedBy: values?.ApprovedBy,
      ApprovedDateTime: values.ApprovedDateTime
        ? values?.ApprovedDateTime
        : null,
      ReceiptStatus: values?.ReceiptStatus,
    };
    if (PaymentReceiptID) {
      try {
        const updatePayload = {
          ID: PaymentReceiptID,
          ...formdata,
        };
        const response = await dispatch(updatePaymentReceipt(updatePayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate(-1);
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    } else {
      try {
        const response = await dispatch(createPaymentReceipt(formdata));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate(-1);
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    }
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <Form
        key={formKey}
        onSubmit={handleSubmit}
        initialValues={PaymentReceiptDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            {/* <ShadowCard style={{ padding: 12 }}> */}
            <GridLayout
              style={{ marginRight: 10 }}
              gap={{ rows: 0, cols: 10 }}
              cols={[{ width: "40%" }, { width: "60%" }]}
            >
              <GridLayoutItem>
                <ShadowCard style={{ padding: 12 }}>
                  <GridLayout
                    gap={{ rows: 0, cols: 10 }}
                    cols={[{ width: "100%" }]}
                  >
                    <GridLayoutItem>
                      <Typography.h4>{"Payment Details"}</Typography.h4>
                    </GridLayoutItem>
                    <GridLayoutItem
                      style={{ width: "100%", display: "flex", gap: 10 }}
                    >
                      <Field
                        wrapperClassName="w-100"
                        name="TourBookingNo"
                        label="Booking No"
                        type="number"
                        component={FormTextField}
                        astrike={true}
                        validator={requiredValidator}
                      />
                      <div>
                        <Button
                          type="button"
                          fillMode={"solid"}
                          size={"medium"}
                          themeColor={"primary"}
                          style={{ marginTop: 35 }}
                          onClick={() =>
                            handleGetPaymentReceipt(formRenderProps)
                          }
                        >
                          View
                        </Button>
                      </div>
                    </GridLayoutItem>
                    <TourBookingChangeWatcher
                      formRenderProps={formRenderProps}
                    />
                    <GridLayoutItem>
                      <Field
                        name="PaymentFor"
                        label="Payment For"
                        component={FormSelectionField}
                        options={PAYMENTFOR?.map((paymentfor: any) => {
                          return {
                            value: paymentfor?.value,
                            label: paymentfor.label,
                          };
                        })}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        name="Name"
                        label="Name"
                        component={FormTextField}
                        astrike={true}
                        validator={requiredValidator}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        name="CompanyID"
                        label="Receipt Company"
                        component={FormSelectionField}
                        options={CompanyList?.map((company: ICompany) => {
                          return {
                            value: company?.ID,
                            label: company?.CompanyName,
                          };
                        })}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        name="PaymentDate"
                        label="Payment Date"
                        format="dd/MM/yyyy"
                        defaultDate={moment().toDate()}
                        component={FormDatePicker}
                      />
                    </GridLayoutItem>
                    {/* <GridLayoutItem>
                      <Field
                        name="OldReceiptNo"
                        label="Old Receipt No"
                        type="number"
                        component={FormTextField}
                      />
                    </GridLayoutItem> */}
                  </GridLayout>
                </ShadowCard>
              </GridLayoutItem>
              <GridLayoutItem>
                <ShadowCard style={{ padding: 12 }}>
                  <h3 style={{ padding: "10px" }}>
                    View Booking Form : {PaymentReceiptCostDetail?.PNRNo || ""}
                  </h3>
                  <table
                    cellSpacing={0}
                    border={1}
                    className="paymentreceipt-table"
                  >
                    <tr>
                      <td>Tour Cost (A)</td>
                      <td>{PaymentReceiptCostDetail?.TourAmount || 0}</td>
                    </tr>
                    <tr>
                      <td>Air Ticket Cost (B)</td>
                      <td>{PaymentReceiptCostDetail?.AirTicketAmount || 0}</td>
                    </tr>
                    <tr>
                      <td>Tour Cancellation Cost (C)</td>
                      <td>
                        {PaymentReceiptCostDetail?.TourCancellationAmount || 0}
                      </td>
                    </tr>
                    <tr>
                      <td>Tour Booking Amount (D) = (A + B + C)</td>
                      <td>
                        {PaymentReceiptCostDetail?.TotalTourBookingAmount || 0}
                      </td>
                    </tr>
                    <tr>
                      <td>Total Paid Amount (E)</td>
                      <td>{PaymentReceiptCostDetail?.TotalPaidAmount || 0}</td>
                    </tr>
                    <tr>
                      <td>Refund Amount (F)</td>
                      <td>{PaymentReceiptCostDetail?.RefundAmount || 0}</td>
                    </tr>
                    <tr>
                      <td>Remaining Amount (G) = (D + E + F)</td>
                      <td>{PaymentReceiptCostDetail?.RemainingAmount || 0}</td>
                    </tr>
                  </table>
                </ShadowCard>
              </GridLayoutItem>
              <GridLayoutItem colSpan={2}>
                <ShadowCard style={{ padding: 12, marginTop: 10 }}>
                  <GridLayout
                    style={{ marginRight: 10 }}
                    gap={{ rows: 0, cols: 10 }}
                    cols={[{ width: "50%" }, { width: "50%" }]}
                  >
                    <GridLayoutItem>
                      <GridLayoutItem>
                        <Typography.h4>{"Payment Details"}</Typography.h4>
                      </GridLayoutItem>
                      <GridLayoutItem style={{ width: "100%" }}>
                        <Field
                          name="Amount"
                          label="Amount"
                          type="number"
                          astrike={true}
                          component={FormTextField}
                          validator={requiredValidator}
                        />
                      </GridLayoutItem>
                      {/* <GridLayoutItem>
                        <Field
                          name="Commission"
                          label="Credit Card Commission"
                          type="number"
                          max="10"
                          component={FormTextField}
                        />
                      </GridLayoutItem> */}
                      <GridLayoutItem>
                        <Field
                          name="RemainingAmount"
                          label="Remaining Amount"
                          type="number"
                          disabled={true}
                          component={FormTextField}
                        />
                      </GridLayoutItem>
                      <GridLayoutItem>
                        <Field
                          name="PaymentType"
                          label="Payment Type"
                          component={FormSelectionField}
                          validator={requiredValidator}
                          astrike={true}
                          options={PAYMENTTYPE?.map((paymenttype: any) => {
                            return {
                              value: paymenttype?.value,
                              label: paymenttype?.label,
                            };
                          })}
                        />
                      </GridLayoutItem>
                    </GridLayoutItem>
                    <GridLayoutItem>
                      {formRenderProps.valueGetter("PaymentType") ===
                        "CREDIT CARD" && (
                        <div className="paymentdetails-box">
                          <GridLayoutItem>
                            <Typography.h4>{"Card Details"}</Typography.h4>
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="BankName"
                              label="Credit Card Company Name"
                              component={FormTextField}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="TransactionNo"
                              label="Transaction No"
                              component={FormTextField}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name={"TransactionDate"}
                              label="Transaction Date"
                              format="dd/MM/yyyy"
                              component={FormDatePicker}
                            />
                          </GridLayoutItem>
                        </div>
                      )}
                      {formRenderProps.valueGetter("PaymentType") ===
                        "CHEQUE" && (
                        <div className="paymentdetails-box">
                          <GridLayoutItem>
                            <Typography.h4>{"Cheque Details"}</Typography.h4>
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="ChequeNo"
                              label="Cheque Number"
                              component={FormTextField}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name={"ChequeDate"}
                              label="Cheque Date"
                              format="dd/MM/yyyy"
                              component={FormDatePicker}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="BankName"
                              label="Bank Name"
                              component={FormTextField}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="BranchName"
                              label="Branch Name"
                              component={FormTextField}
                            />
                          </GridLayoutItem>
                        </div>
                      )}
                      {formRenderProps.valueGetter("PaymentType") ===
                        "NEFT / IMPS" && (
                        <div className="paymentdetails-box">
                          <GridLayoutItem>
                            <Typography.h4>
                              {"NEFT / IMPS Details"}
                            </Typography.h4>
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="BankName"
                              label="Bank Name"
                              component={FormTextField}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="TransactionNo"
                              label="Transaction No"
                              component={FormTextField}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name={"TransactionDate"}
                              label="Transaction Date"
                              format="dd/MM/yyyy"
                              component={FormDatePicker}
                            />
                          </GridLayoutItem>
                        </div>
                      )}
                      {formRenderProps.valueGetter("PaymentType") === "UPI" && (
                        <div className="paymentdetails-box">
                          <GridLayoutItem>
                            <Typography.h4>{"UPI Details"}</Typography.h4>
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="UpiType"
                              label="Type"
                              component={FormSelectionField}
                              options={UPITYPE?.map((upitype: any) => {
                                return {
                                  value: upitype?.value,
                                  label: upitype?.label,
                                };
                              })}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="TransactionNo"
                              label="Transaction No"
                              component={FormTextField}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name={"TransactionDate"}
                              label="Transaction Date"
                              format="dd/MM/yyyy"
                              component={FormDatePicker}
                            />
                          </GridLayoutItem>
                        </div>
                      )}
                      {/* {formRenderProps.valueGetter("PaymentType") ===
                          "CREDIT NOTE" && (
                          <div className="paymentdetails-box">
                            <GridLayoutItem>
                              <Typography.h4>
                                {"Credit Note Details"}
                              </Typography.h4>
                            </GridLayoutItem>
                            <GridLayoutItem>
                              <Field
                                name="TourBookingNo"
                                label="Old Tour Booking No"
                                type="number"
                                component={FormTextField}
                              />
                            </GridLayoutItem>
                            <GridLayoutItem>
                              <Field
                                name="CreditNoteNo"
                                label="Credit Note No"
                                component={FormTextField}
                              />
                            </GridLayoutItem>
                          </div>
                        )} */}
                    </GridLayoutItem>
                  </GridLayout>
                </ShadowCard>
                <GridLayoutItem colSpan={2}>
                  <ShadowCard style={{ padding: 12, marginTop: 10 }}>
                    <GridLayout
                      style={{ marginRight: 10 }}
                      gap={{ rows: 0, cols: 10 }}
                      cols={[{ width: "50%" }, { width: "50%" }]}
                    >
                      <GridLayoutItem colSpan={2}>
                        <Typography.h4>{"Other Details"}</Typography.h4>
                      </GridLayoutItem>
                      <GridLayoutItem>
                        <Field
                          name="IsRefund"
                          label="Is Refund"
                          component={FormSelectionField}
                          options={BOOLEAN?.map((refund: any) => {
                            return {
                              value: refund?.value,
                              label: refund?.label,
                            };
                          })}
                        />
                      </GridLayoutItem>
                      <GridLayoutItem rowSpan={2}>
                        <Field
                          name="Remarks"
                          label="Remarks"
                          rows={5}
                          component={FormTextArea}
                          placeholder="Remarks"
                        />
                      </GridLayoutItem>
                      <GridLayoutItem>
                        <Field
                          name="ReceivedByID"
                          label="Recieved By"
                          component={FormSelectionField}
                          options={UserList?.map((user: IUser) => {
                            return {
                              value: user?.ID,
                              label: `${user?.FirstName} ${user?.LastName}`,
                            };
                          })}
                        />
                      </GridLayoutItem>
                    </GridLayout>
                    <GridLayoutItem
                      style={{
                        textAlign: "end",
                        marginTop: "20px",
                        width: "100%",
                      }}
                    >
                      <Button
                        type="button"
                        fillMode={"outline"}
                        themeColor={"primary"}
                        style={{ marginRight: 4 }}
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </Button>
                      <ButtonWithLoading
                        label={PaymentReceiptID ? "Update" : "Create"}
                        type="submit"
                        disabled={!formRenderProps.allowSubmit || loading}
                        loading={loading}
                      />
                    </GridLayoutItem>
                  </ShadowCard>
                </GridLayoutItem>
              </GridLayoutItem>
              {PaymentReceiptCostDetail &&
                PaymentReceiptCostDetail?.PaymentHistory &&
                PaymentReceiptCostDetail?.PaymentHistory.length > 0 && (
                  <GridLayoutItem colSpan={2}>
                    <ShadowCard style={{ padding: 12, marginTop: 10 }}>
                      <GridLayoutItem>
                        <Typography.h4>Pax Details</Typography.h4>
                      </GridLayoutItem>
                      <GridLayoutItem>
                        <Grid
                          style={{ maxHeight: "fit-content" }}
                          data={PaymentReceiptCostDetail?.PaymentHistory}
                          cells={{
                            headerCell: HeaderCustomCell,
                            data: MyDataCustomCell,
                          }}
                        >
                          <Column field="ReceiptNo" title="Receipt No" />
                          <Column field="PaymentDate" title="Payment Date" />
                          <Column field="Amount" title="Amount" />
                          <Column field="PaymentType" title="Payment Type" />
                          <Column field="BankName" title="Bank Name" />
                          <Column
                            field="TransactionNo"
                            title="Transaction No"
                          />
                          <Column
                            field="TransactionDate"
                            title="Transaction Date"
                          />
                        </Grid>
                      </GridLayoutItem>
                    </ShadowCard>
                  </GridLayoutItem>
                )}
            </GridLayout>
            {/* </ShadowCard> */}
          </FormElement>
        )}
      />
    </>
  );
};

export default CreatePaymentReceipt;
