import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import FormTextField from "../../components/formFields/FormTextField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import ShadowCard from "../../components/common/ShadowCard";
import {
  createUserType,
  getUserTypeByID,
  updateUserType,
} from "./services/userType.services";
import { clearUserTypeDetails } from "./userTypeSlice";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { STATUSARRAY } from "../../_contstants/common";
import { getAllActiveMenuSubMenuList } from "../menu/services/menu.services";
import FormCheckbox from "../../components/formFields/FormCheckbox";
import { getAllActiveRights } from "../rights/services/rights.services";
import { IRights } from "../rights/rightsModel";
import FormMultiSelectionFiled from "../../components/formFields/FormMultiSelectionFiled";

// interface FormChangeWatcherProps {
//   formRenderProps: FormRenderProps;
// }

// const RightsChangeWatcher: React.FC<FormChangeWatcherProps> = ({
//   formRenderProps,
// }) => {
//   const MenuSubMenuList = useAppSelector((state) => state.menu.MenuSubMenuList);

//   MenuSubMenuList &&
//     MenuSubMenuList.length > 0 &&
//     MenuSubMenuList?.map((menu: any) => {
//       menu?.MenuSubList?.map((submenu: any) => {
//         const isMenuChecked = formRenderProps.valueGetter(`${submenu?.ID}`);
//         useEffect(() => {
//           if (isMenuChecked === false) {
//             formRenderProps.onChange(`${submenu?.ID}Rights`, {
//               value: null,
//             });
//           }
//         }, [isMenuChecked]);
//       });
//     });

//   return null;
// };

const CreateUserType: React.FC = () => {
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const UserTypeID = location.state?.UserTypeID;

  const loading = useAppSelector((state) => state.userType.loading);
  const UserTypeDetail = useAppSelector(
    (state) => state.userType.UserTypeDetail
  );
  const MenuSubMenuList = useAppSelector((state) => state.menu.MenuSubMenuList);
  const RightsList = useAppSelector((state) => state.rights.RightsList);

  useEffect(() => {
    if (UserTypeID) {
      dispatch(getUserTypeByID(UserTypeID));
    }
  }, [UserTypeID]);

  useEffect(() => {
    dispatch(getAllActiveMenuSubMenuList());
    dispatch(getAllActiveRights());

    return () => {
      dispatch(clearUserTypeDetails());
    };
  }, []);

  const handleMenuChange = (
    formRenderProps: FormRenderProps,
    menuName: string,
    isMenuChecked: boolean
  ) => {
    MenuSubMenuList &&
      MenuSubMenuList.length > 0 &&
      MenuSubMenuList?.forEach((menu: any) => {
        if (isMenuChecked === false) {
          formRenderProps.onChange(`${menu?.ID}Rights`, {
            value: null,
          });
        }

        if (menu?.ID === menuName) {
          menu?.MenuSubList?.forEach((submenu: any) => {
            formRenderProps.onChange(`${submenu?.ID}`, {
              value: isMenuChecked,
            });
            if (isMenuChecked === false) {
              formRenderProps.onChange(`${submenu?.ID}Rights`, {
                value: null,
              });
            }
          });
        }
      });
  };

  const handleSubMenuChange = (
    formRenderProps: FormRenderProps,
    subMenuName: string,
    isMenuChecked: boolean
  ) => {
    MenuSubMenuList &&
      MenuSubMenuList.length > 0 &&
      MenuSubMenuList?.map((menu: any) => {
        menu?.MenuSubList?.map((submenu: any) => {
          if (submenu?.ID === subMenuName) {
            if (isMenuChecked === false) {
              formRenderProps.onChange(`${submenu?.ID}Rights`, {
                value: null,
              });
            }
            if (submenu?.ID && submenu?.UnderMenuID) {
              formRenderProps.onChange(`${submenu?.UnderMenuID}`, {
                value: true,
              });
            }
          }
        });
      });
  };

  const handleSubmit = async (values: any) => {
    // const rightsKeys = Object.keys(values).filter((key) =>
    //   key.endsWith("Rights")
    // );

    // const menuIDs = Object.keys(values)
    //   .filter((key) => !key.endsWith("Rights") && values[key] === true)
    //   .map((key) => parseInt(key, 10));

    // const existingRights = new Set(
    //   rightsKeys.map((item) => parseInt(item.replace("Rights", ""), 10))
    // );
    // const resultArray = menuIDs.filter((item) => !existingRights.has(item));

    // const result = [
    //   ...rightsKeys.map((key) => ({
    //     MenuID: parseInt(key.replace("Rights", ""), 10),
    //     RightsID: values[key].length > 0 ? values[key].join(", ") : "",
    //   })),
    //   ...resultArray.map((id: any) => ({
    //     MenuID: parseInt(id, 10),
    //     RightsID: "",
    //   })),
    // ];

    // interface Values {
    //   [key: string]: string[] | boolean;
    // }

    interface ResultItem {
      MenuID: number;
      RightsID: string;
    }

    const result: ResultItem[] = Object.entries(values).reduce(
      (acc: ResultItem[], [key, value]) => {
        if (key.endsWith("Rights")) {
          const menuID: number = parseInt(key.replace("Rights", ""), 10);
          if (value) {
            const rightsID: string =
              Array.isArray(value) && value.length > 0 ? value.join(", ") : "";
            acc.push({ MenuID: menuID, RightsID: rightsID });
          }
        } else if (value === true) {
          const menuID: number = parseInt(key, 10);
          if (!values[`${menuID}Rights`]) {
            acc.push({ MenuID: menuID, RightsID: "" });
          }
        }
        return acc;
      },
      []
    );

    if (UserTypeID) {
      try {
        const updatePayload = {
          ID: UserTypeID,
          UserType: values.UserType ? values.UserType : "",
          IsActive: values.IsActive === 1,
          UserTypeRightsAssign: result || [],
        };
        const response = await dispatch(updateUserType(updatePayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate(-1);
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    } else {
      try {
        const insertPayload = {
          UserType: values.UserType ? values.UserType : "",
          IsActive: values.IsActive === 1,
          UserTypeRightsAssign: result || [],
        };
        const response = await dispatch(createUserType(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate(-1);
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    }
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <Form
        onSubmit={handleSubmit}
        initialValues={UserTypeDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "33.33%" },
                  { width: "33.33%" },
                  { width: "33.33%" },
                ]}
              >
                <GridLayoutItem colSpan={3}>
                  <Typography.h4>
                    {UserTypeID ? "Update User Type" : "Create User Type"}
                  </Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="UserType"
                    maxLength="100"
                    label="User Type"
                    placeholder="i.e. Admin"
                    component={FormTextField}
                    astrike={true}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="IsActive"
                    label="Status"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    astrike={true}
                    options={STATUSARRAY?.map((status: any) => {
                      return {
                        value: status?.value,
                        label: status?.label,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem></GridLayoutItem>
              </GridLayout>
            </ShadowCard>
            <ShadowCard style={{ padding: 12, marginTop: 10 }}>
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "33.33%" },
                  { width: "33.33%" },
                  { width: "33.33%" },
                ]}
              >
                <GridLayoutItem colSpan={3}>
                  <Typography.h4>Rights</Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem colSpan={3}>
                  {MenuSubMenuList &&
                    MenuSubMenuList.length > 0 &&
                    MenuSubMenuList.map((menu: any) => {
                      return (
                        <>
                          <div
                            style={{
                              display: "flex",
                              width: "50%",
                              justifyContent: "space-between",
                              alignItems: "center",
                              minHeight: 45,
                            }}
                          >
                            <Field
                              key={menu?.ID}
                              name={`${menu?.ID}`}
                              label={menu?.MenuName}
                              component={FormCheckbox}
                              onChange={() =>
                                handleMenuChange(
                                  formRenderProps,
                                  menu?.ID,
                                  formRenderProps.valueGetter(`${menu?.ID}`)
                                )
                              }
                            />
                            {formRenderProps.valueGetter(`${menu?.ID}`) ===
                              true && (
                              <Field
                                wrapperStyle={{ width: 300 }}
                                name={`${menu?.ID}Rights`}
                                size="small"
                                isSelectAll={true}
                                component={FormMultiSelectionFiled}
                                options={RightsList?.map((right: IRights) => {
                                  return {
                                    value: right?.ID,
                                    label: right?.Name,
                                  };
                                })}
                              />
                            )}
                          </div>
                          {menu &&
                            menu?.MenuSubList &&
                            menu?.MenuSubList.length > 0 &&
                            menu?.MenuSubList?.map((submenu: any) => {
                              return (
                                <div
                                  style={{
                                    display: "flex",
                                    width: "60%",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    minHeight: 45,
                                  }}
                                >
                                  <Field
                                    wrapperStyle={{ marginLeft: "15%" }}
                                    key={submenu?.ID}
                                    name={`${submenu?.ID}`}
                                    label={submenu?.MenuName}
                                    component={FormCheckbox}
                                    onChange={() =>
                                      handleSubMenuChange(
                                        formRenderProps,
                                        submenu?.ID,
                                        formRenderProps.valueGetter(
                                          `${submenu?.ID}`
                                        )
                                      )
                                    }
                                  />
                                  {formRenderProps.valueGetter(
                                    `${submenu?.ID}`
                                  ) === true && (
                                    <Field
                                      wrapperStyle={{ width: 300 }}
                                      // key={submenu?.ID}
                                      name={`${submenu?.ID}Rights`}
                                      size="small"
                                      isSelectAll={true}
                                      // label={`${submenu?.MenuName}Rights`}
                                      // validator={
                                      //   formRenderProps.valueGetter(
                                      //     `${submenu?.ID}`
                                      //   ) && requiredArrayValidator
                                      // }
                                      component={FormMultiSelectionFiled}
                                      options={RightsList?.map(
                                        (right: IRights) => {
                                          return {
                                            value: right?.ID,
                                            label: right?.Name,
                                          };
                                        }
                                      )}
                                    />
                                  )}
                                </div>
                              );
                            })}
                        </>
                      );
                    })}
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={3}
                  style={{
                    textAlign: "end",
                    marginTop: "20px",
                    width: "100%",
                  }}
                >
                  <Button
                    type="button"
                    fillMode={"outline"}
                    themeColor={"primary"}
                    style={{ marginRight: 4 }}
                    onClick={() => navigate("/usertype")}
                  >
                    Cancel
                  </Button>
                  <ButtonWithLoading
                    label={UserTypeID ? "Update" : "Create"}
                    type="submit"
                    disabled={!formRenderProps.allowSubmit || loading}
                    loading={loading}
                  />
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
          </FormElement>
        )}
      />
    </>
  );
};

export default CreateUserType;
