import React, { useEffect, useRef } from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormTextField from "../../components/formFields/FormTextField";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import {
  createMenu,
  getAllActiveMenuSubMenuList,
  getAllMenus,
  getMenuByID,
  updateMenu,
} from "./services/menu.services";
import { clearMenuDetails } from "./menuSlice";
import FormCheckbox from "../../components/formFields/FormCheckbox";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { IMenuSubMenuList } from "./menuModel";
import { STATUSARRAY } from "../../_contstants/common";
import { closed } from "../../components/muidrawer/muiDrawerSlice";
import { Typography } from "@progress/kendo-react-common";
import { RxCross1 } from "react-icons/rx";

// interface FormChangeWatcherProps {
//   formRenderProps: FormRenderProps;
// }

// const PaxCountChangeWatcher: React.FC<FormChangeWatcherProps> = ({
//   formRenderProps,
// }) => {
//   const Adult = formRenderProps.valueGetter("Adult");
//   const ExtraAdult = formRenderProps.valueGetter("ExtraAdult");
//   const CWB = formRenderProps.valueGetter("CWB");
//   const CNB = formRenderProps.valueGetter("CNB");
//   const Infant = formRenderProps.valueGetter("Infant");
//   useEffect(() => {
//     formRenderProps.onChange("PaxCount", {
//       value: +Adult + +ExtraAdult + +CWB + +CNB + +Infant,
//     });
//   }, [Adult, ExtraAdult, CWB, CNB, Infant]);
//   return null;
// };

const CreateMenu: React.FC = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);

  const MenuDetail = useAppSelector((state) => state.menu.MenuDetail);
  const MenuSubMenuList = useAppSelector((state) => state.menu.MenuSubMenuList);
  const MenuID = useAppSelector((state) => state.muidrawer.data);
  const formLoading = useAppSelector((state) => state.menu.formLoading);

  useEffect(() => {
    if (MenuID) {
      dispatch(getMenuByID(MenuID));
    }
  }, [MenuID]);

  useEffect(() => {
    dispatch(getAllActiveMenuSubMenuList());

    return () => {
      dispatch(clearMenuDetails());
      dispatch(closed());
    };
  }, []);

  const handleIsMenuCheck = (formRenderProps: FormRenderProps) => {
    const IsMenu = formRenderProps.valueGetter("IsMenu");
    if (IsMenu === true) {
      formRenderProps.onChange("UnderMenuID", {
        value: null,
      });
    }
  };

  const handleSubmit = async (values: any) => {
    try {
      if (!MenuID) {
        const insertPayload = {
          MenuName: values?.MenuName ? values?.MenuName : "",
          MenuKey: values?.MenuKey ? values?.MenuKey : "",
          IsMenu: values?.IsMenu ? values?.IsMenu : false,
          UnderMenuID: values?.UnderMenuID ? values?.UnderMenuID : null,
          IsActive: values.IsActive === 1,
        };
        const response = await dispatch(createMenu(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearMenuDetails());
          dispatch(getAllMenus());
        }
      } else {
        const editPayload = {
          ID: MenuID,
          MenuName: values?.MenuName ? values?.MenuName : "",
          MenuKey: values?.MenuKey ? values?.MenuKey : "",
          IsMenu: values?.IsMenu ? values?.IsMenu : false,
          UnderMenuID: values?.UnderMenuID ? values?.UnderMenuID : null,
          IsActive: values.IsActive === 1,
        };
        const response = await dispatch(updateMenu(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearMenuDetails());
          dispatch(getAllMenus());
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  const handleFormClose = () => {
    dispatch(closed());
  };
  if (formLoading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={MenuDetail}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ width: "100%" }}>
          <GridLayout
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
            gap={{ rows: 10 }}
            cols={[{ width: "100%" }]}
          >
            <GridLayoutItem className="master-drawer-header">
              <Typography.h4 className="m-0">
                {MenuID ? "Update Menu" : "Create Menu"}
              </Typography.h4>
              <Button
                type="button"
                fillMode={"flat"}
                themeColor={"dark"}
                style={{ marginRight: 4 }}
                onClick={() => dispatch(closed())}
              >
                <RxCross1 />
              </Button>
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="MenuName"
                label="Menu Name"
                placeholder="i.e. Dashboard"
                component={FormTextField}
                validator={requiredValidator}
                astrike={true}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="MenuKey"
                label="Key"
                placeholder="i.e. dashboard"
                astrike={true}
                component={FormTextField}
                validator={requiredValidator}
              />
            </GridLayoutItem>
            <GridLayoutItem
              style={{
                width: "100%",
                fontWeight: 700,
                padding: "20px 0 10px",
              }}
            >
              <Field
                name="IsMenu"
                label="Is Main Menu"
                maxLength="100"
                component={FormCheckbox}
                onChange={() => handleIsMenuCheck(formRenderProps)}
              />
            </GridLayoutItem>
            {formRenderProps.valueGetter("IsMenu") === false && (
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  id={"UnderMenuID"}
                  name={"UnderMenuID"}
                  label={"Main Menu"}
                  astrike={true}
                  placeholder="Main Menu Name"
                  component={FormSelectionField}
                  validator={requiredValidator}
                  disabled={
                    formRenderProps.valueGetter("IsMenu") === true
                      ? true
                      : false
                  }
                  options={MenuSubMenuList?.map((menu: IMenuSubMenuList) => {
                    return {
                      value: menu?.ID,
                      label: menu?.MenuName,
                    };
                  })}
                />
              </GridLayoutItem>
            )}
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="IsActive"
                label="Status"
                component={FormSelectionField}
                astrike={true}
                validator={requiredValidator}
                options={STATUSARRAY?.map((status: any) => {
                  return {
                    value: status?.value,
                    label: status?.label,
                  };
                })}
              />
            </GridLayoutItem>
            <GridLayoutItem
              colSpan={3}
              style={{
                textAlign: "start",
                marginTop: "20px",
                width: "100%",
              }}
            >
              <ButtonWithLoading
                label={!MenuID ? "Create" : "Update"}
                type="submit"
                disabled={!formRenderProps.allowSubmit || formLoading}
                loading={formLoading}
              />
              <Button
                type="button"
                fillMode={"outline"}
                themeColor={"primary"}
                style={{ marginLeft: 4 }}
                onClick={handleFormClose}
              >
                Cancel
              </Button>
            </GridLayoutItem>
          </GridLayout>
          {/* <PaxCountChangeWatcher formRenderProps={formRenderProps} /> */}
        </FormElement>
      )}
    />
  );
};

export default CreateMenu;
