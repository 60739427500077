import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast } from "../../../components/toast/Toasts";
import { API_URL } from "../../../app/config";



export const getUserTourBooking = createAsyncThunk(
  "TourBookingReport/UserWiseTourBookingReport",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourBookingReport/UserWiseTourBookingReport`,
        formData
      );
      return response.data?.Data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Customer Report:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);


export const getSelfTourBooking = createAsyncThunk(
  "TourBookingReport/SelfTourBookingReport",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourBookingReport/SelfTourBookingReport`,
        formData
      );
      return response.data?.Data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Customer Report:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
